import React, { useState, useEffect, useContext } from 'react';
import './Main.css';
import NavBar from './Subcomponents/NavBar/NavBar';
import {Link} from 'react-router-dom';
import MainData from './Subcomponents/MainData/maindata';

import Eempl from '../logos/Eempl.png';
import Hhq from '../logos/Hhq.png';
import Ccust from '../logos/Ccust.png';
import Mmng from '../logos/Mmng.png';
import {AuthContext} from '../contexts/useAuthContext';
import { DataContext } from '../contexts/useDataContext';

	const defaultProps={
         links:  [
                    { name:'empl',img: Eempl, ttl:'עובדי שטח'},
                    { name:'refs',img: Mmng, ttl:'מנהלי אזור' },
                    { name:'cust',img: Ccust, ttl:'לקוחות' },
                    { name:'hq', img: Hhq, ttl:'עובדי הנהלה'}
                ]
		};

	

const Main = (props) =>{
	
	const [links, setLinks] = useState(defaultProps.links);
	const {regmng} = useContext(AuthContext);	
	const {setLoading} = useContext(DataContext);
		
	useEffect(() => {
     	if(regmng){
			let newArr = defaultProps.links.filter(x => x.name==='refs' /*|| x.name==='cust'*/);
			setLinks(newArr);
		}
    }, [regmng]);

	return (
      <div style={{background: 'white', width:'100%', height:'100vh',position:'absolute'}}>
			<NavBar shownav={props.shownav}/> 
			{!regmng && <MainData />}
            <div className='flex flex-wrap card-container gap-3 mt-8'>
                <div className='flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center flex-column md:flex-row'>
                    {links.slice(0).reverse().map(d => (
                        <div className={`main text-center col-md-${12/links.length}`} key={d.name} onClick={()=>setLoading(true)}
							style={{maxWidth:'320px'}}>
                             <Link to={`/${d.name}`} ><img src={d.img} alt={d.name}/></Link> 
                             <h3><Link className='no-underline underline' to={`/${d.name}`}>{d.ttl}</Link> </h3>     
                        </div>
                    )) }
                </div>
            </div>
      </div>
      );
};
export default Main;
