import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import DPicker from '../../Subcomponents/DatePicker';
import DrpDown from '../../Subcomponents/DropDown';
import Text from '../../Subcomponents/TextInput';
import { DataContext } from '../../../contexts/useDataContext';
import {updateCust, updateRefsWhenMoveAffs} from '../../../functions/functions.js';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chip } from 'primereact/chip';
import DropDownHQ from '../../Subcomponents/DropDownHQ';
import { ProgressSpinner } from 'primereact/progressspinner';

const HqDetails = (props) => {
	const { value, handleChange, ttlList, hqData } = useContext(DataContext);
	const [chosenReg, setChosenReg] = useState(null);
	const [regsList, setRegsList] = useState([]);
	const [loadSpin, setLoadSpin] = useState(false);

	useEffect(() => {
		let regsArr = hqData
			.filter((k) => k.Ttl === 'מנהל אזור').filter(w=> w.ActStts)
			.map((a) => {
				return (
					a.HqNum !== value.HqNum && { name: `${a.HqName} ${a.HqLName}`, num: a.HqNum }
				);
			})
			.filter((s) => s !== false);
		setRegsList(regsArr);
	}, [hqData, value.HqNum]);

	
	const moveSnifim = async () => {
		if (chosenReg == null) {
			alert('נא לבחור מנהל אזור');
			return;
		}

		let NewNum = value.HqNum;
		let NewName = `${value.HqName} ${value.HqLName}`;
		let OldNum = regsList.filter(q=> q.name===chosenReg)[0].num;
		
		setLoadSpin(true);

		let promise1 = await updateCust(NewNum, NewName, OldNum);
		let promise2 = await updateRefsWhenMoveAffs(NewNum, OldNum);

		Promise.all([promise1, promise2]).then(() => {
			//...
		});

		setLoadSpin(false);
		
		props.toast.current.show({
			closable: false,
			severity: 'success',
			summary: 'העברת הסניפים הסתיימה',
		});  
	};

	const snifimMove = (
		<div className="border-round-lg border-1 border-300 mt-2">
		
				<DropDownHQ
					inpt="Ttl"
					vl={chosenReg}
					lbl="ממנהל אזור"
					placeholder="בחר מנהל אזור"
					option={regsList}
					r={props.cl}
					onChange={(e) => setChosenReg(e.value)}
				/>
				<Text
					inpt="HqName HqLName"
					vl={`${value.HqName} ${value.HqLName}`}
					dis={true}
					lbl="אל מנהל אזור"
				/>
			<div style={{ marginTop: 6 }}>
				<div style={{ paddingTop: 5, paddingBottom: 5 }}>
					<Button className="p-button-text buttonTextFont" onClick={moveSnifim} color="primary" 	label={loadSpin ? 'מעביר, נא המתן...' : 'העבר'} />
					{loadSpin ? <ProgressSpinner className="spinner" /> : null}
				</div>
			</div>
		</div>
	);

	let sn = props.snifim ? snifimMove : null;

	return (
		<div style={{ direction: 'rtl' }} className="grid w-full mx-auto mt-1">
			<div className="col-12 md:col-6">
				<div className="border-round-lg border-1 border-300">
					<Text
						inpt="HqNum"
						vl={value.HqNum}
						r={props.cl}
						lbl="מספר עובד"
						dis={value.ActStts ? true : false}
					/>
					<DrpDown
						inpt="Ttl"
						vl={value.Ttl}
						lbl="תפקיד"
						option={ttlList.map((k) => k.item)}
						r={props.cl}
					/>
					<Text inpt="HqId" vl={value.HqId} r={props.cl}  lbl="תעודת זהות" />
					<Text inpt="HqName" vl={value.HqName} r={props.cl} lbl="שם פרטי" />
					<Text inpt="HqLName" vl={value.HqLName} r={props.cl} lbl="שם משפחה" />
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<DPicker inpt="SDate" vl={value.SDate} r={props.cl} lbl="תחילת עבודה" />
					<DPicker inpt="EDate" vl={value.EDate} lbl={'סיום עבודה'} />
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<Text inpt="Strt" vl={value.Strt} lbl="רחוב" />
					<Text inpt="Cty" vl={value.Cty} lbl="עיר" />
					<Text inpt="HqTel" vl={value.HqTel} r={props.cl} lbl="מס' טלפון" />
				</div>
			</div>
			<div className="col-12 md:col-6">
				<div>
					<label>הערות</label>
					<InputTextarea
						rows={5}
						name="Notes"
						value={value.Notes}
						onChange={handleChange}
						autoResize
						className="focus:border-primary w-full inputArea"
					/>
				</div>
				<div style={{ marginTop: 6 }}>
					<div className="mt-1 text-center">
						<Chip
							label={value.ActStts ? 'עובד פעיל' : 'עובד לא פעיל'}
							className={`w-8rem justify-content-center h-2rem ${
								value.ActStts ? 'bg-indigo-400 text-0' : 'bg-black-alpha-30 text-0'
							}`}
						/>
					</div>
				</div>
				{sn}
			</div>
		</div>
	);
};

export default HqDetails;