import React, { useContext } from 'react';
import Item from './Item';
import { ListsContext } from '../../contexts/listsContext';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';

import './Kablanim.css';

const Lists = (props) => {
	const { list } = useContext(ListsContext);
	return (
		<Card className='pCard'>
			{list !== null && (
				<ul className='divUl'>
					{list.map((item, i) => (
						<React.Fragment key={item.id}>
							<Item item={item} />
							{i < list.length - 1 && <Divider />}
						</React.Fragment>
					))}
				</ul>
			)}
		</Card>
	);
};

export default Lists;