import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import EmplDetailsGarbage from '../Garbage/EmplDetailsGarbage';
import {getDocument} from '../../functions/functions.js';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import {DataContext} from '../../contexts/useDataContext';

const HisModal = (props) =>{
	const [dt, setDt] = useState('');		
	const {displayDialog, setDisplayDialog} = useContext(DataContext);
	const [tabind, setTabind] = useState(0);

	useEffect(() => {
		const load =async() =>{
	
			let moshe = await getDocument('Empl', props.slct);
			setDt(moshe);
		};
        load();
    }, [props.slct]);
	
		
	
	const footer = <div>
	
		<Button variant="outlined" className='p-button-text buttonTextFont text-red-500' onClick={()=>setDisplayDialog(false)} color="primary" label ='יציאה'/>
	</div>;
   
	return (
		
			<Dialog
				header="כרטיס עובד"
				visible={displayDialog}
				onHide={() => setDisplayDialog(false)}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
				<TabView activeIndex={tabind} onTabChange={(e) => setTabind(e.index)} panelContainerClassName="p-0 pt-2">
					<TabPanel
						header="פרטי עובד"
						rightIcon="pi pi-user"
						key="1"
						className="tabPane "
					>
						<EmplDetailsGarbage  value={dt} />
					</TabPanel>
				</TabView>
			</Dialog>
    );
    };

export default HisModal;


