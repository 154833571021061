import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../Subcomponents/TableHeader/Header';
import HqModal from './Modal/HqModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import SideBar from '../Subcomponents/SideBar';
import NavBar from '../Subcomponents/NavBar/NavBar';
import { DataContext } from '../../contexts/useDataContext';
import { getData, readDataAll } from '../../functions/functions.js';
import Main from '../../logos/Main.png';
import Empl from '../../logos/Empl.png';
import Cust from '../../logos/Cust.png';
import Mng from '../../logos/Mng.bmp';
import AddHq from '../../logos/Add_hq.png';
import { filterSets } from '../Empl/EmplFuncHelpers';

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const dateFormat = require('dateformat');

const sideBar = [
	{ id: 1, url: '/main', alt: 'בית', img: Main, ttl: 'בית' },
	{ id: 2, url: '/empl', alt: 'עובדי שטח', img: Empl, ttl: 'עובדי שטח' },
	{ id: 3, url: '/refs', alt: 'מנהלי אזור', img: Mng, ttl: 'מנהלי אזור' },
	{ id: 4, url: '/cust', alt: 'לקוחות', img: Cust, ttl: 'לקוחות' },
	{ id: 5, url: '#', alt: 'טופס חדש', img: AddHq, ttl: 'טופס חדש' },
];

let propDefaults = [
	{ field: 'HqNum', header: '#', showcol: true, initial: true },
	{ field: 'HqName', header: 'שם פרטי', showcol: true, initial: true },
	{ field: 'HqLName', header: 'שם משפחה', showcol: true, initial: true },
	{ field: 'HqId', header: 'תעודת זהות', showcol: true, initial: true },
	{ field: 'SDate', header: 'תחילת עבודה', showcol: true, initial: true, showFilter: true },
	{ field: 'EDate', header: 'סיום עבודה', showcol: true, initial: true, showFilter: true },
	{ field: 'Ttl', header: 'תפקיד', showcol: true, initial: true, showFilter: true },
	{ field: 'HqTel', header: 'טלפון', showcol: false, initial: false },
	{ field: 'Strt', header: 'רחוב', showcol: false, initial: false },
	{ field: 'Cty', header: 'עיר', showcol: false, initial: false },
	{ field: 'Notes', header: 'הערות', showcol: false, initial: false },
	{ field: 'ActStts', header: 'פעילות', showcol: false, initial: false },
];

const Hdq = (props) => {
	const {
		hqData,
		setHqData,
		selectValue,
		displayDialog,
		setDateBack,
		ttlList,
		setTtlList,
	} = useContext(DataContext);
	const [runFirstTime, setRunFirstTime] = useState(false);
	let myRef = useRef();
	const [isnotActLoaded, setIsnotActLoaded] = useState(false);
	const [showOnlyActive, setShowOnlyActive] = useState(false);
	const [sinunVal, setSinunVal] = useState({ Ttl: null, SDate: null, EDate: null });
	const [ttlListFilter, setTtlListFilter] = useState([]);
	const [filters1, setFilters1] = useState(null);

	const [cols, setCols] = useState(propDefaults);
	const [showSearch, setShowSearch] = useState(false);
	const [spinner, setSpinner] = useState(false);

	const [globalFilter, setGlobalFilter] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		const loadDataTtl = async (hqdata) => {
			const ttlListTmp = await readDataAll('Titles');
			setTtlList(ttlListTmp);

			//prepare the list for filter regs
			setTtlListFilter(
				hqdata
					.map((v) => v.Ttl)
					.filter((val, id, array) => array.indexOf(val) === id)
					.map((vv) => {
						return { label: vv, value: vv };
					})
			);
		};

		const loadData = async () => {
			setSpinner(true);
			setRunFirstTime(true);
			let hqdata = await getData('Hq', true);
			setHqData(hqdata);

			loadDataTtl(hqdata);
			setFilters1(filterSets);
			setSpinner(false);
		};

		!runFirstTime && !hqData.length && loadData();
		ttlList.length === 0 && loadDataTtl(hqData);
	}, [hqData, setHqData, runFirstTime, setTtlList, ttlList]);

	const dsplSlctVal = (e) => {
		selectValue(e.data);
	};

	const addEnt = (x) => (e) => {
		if (x === 5) {
			selectValue(createEmptyObj());
		}
	};

	const createEmptyObj = () => {
		let tmpObj = {};
		propDefaults
			.map((k) => k.field)
			.map((q) => {
				return (tmpObj[q] = '');
			});
		return tmpObj;
	};

	const onSearchChange = (event) => {
		myRef.current.filter(null, '', 'equals');
		setGlobalFilter(event.target.value);
		setSearchValue(event.target.value);
	}; //search

	const showSearchClick = () => {
		setShowSearch(!showSearch);
		setGlobalFilter(null);
		setSearchValue('');
		setShowOnlyActive(false);
	};

	const handleToggleCols = (value) => {
		let newArr = [...cols];
		let newObj = { ...value, showcol: !value.showcol };
		newArr[newArr.map((i) => i.field).indexOf(value.field)] = newObj;
		setCols(newArr);
	};

	const resetFilter = async () => {
		setGlobalFilter(null);
		setSearchValue('');
		setSinunVal({ SDate: null, EDate: null });

		setShowOnlyActive(false);

		myRef.current.reset();
	};

	const showAct = async () => {
		let tmpALL = hqData; //active and not active

		if (!isnotActLoaded) {
			setSpinner(true);
			let dataOfNotActive = await getData('Hq', false);
			setIsnotActLoaded(true); //טעינה של לא פעילים
			tmpALL = [...hqData, ...dataOfNotActive].sort((a, b) =>
				a.Number > b.Number ? 1 : b.Number > a.Number ? -1 : 0
			);

			let seen = new Set(); //removre duplicates when deleting refs
			const filteredArr = tmpALL.filter((el) => {
				const duplicate = seen.has(el.HqNum);
				seen.add(el.HqNum);
				return !duplicate;
			});

			setHqData(filteredArr);
			setSpinner(false);
		}

		setShowOnlyActive(!showOnlyActive);
	};



	
	const dateFilterTemplate = (col) => {

		return <Calendar  dateFormat="dd-mm-yy" readOnlyInput={true} value={sinunVal[col.field]}
										onChange={(e)=>onfltDates(e.value, col.field)}
										placeholder="dd-mm-yyyy"
				   />
    }
	
	const ttlFilterTemplate = () => {

		return <Dropdown
				style={{ width: '100%' }}
				value={sinunVal.Ttl}
				options={ttlListFilter}
				placeholder="בחר תפקיד"
				onChange={onFilterChange('Ttl', 'equals')}
			//	showClear
			/>
    }
	
	const onFilterChange = (col, par) => (e) => {
		myRef.current.filter(e.value, col, par);
		setSinunVal({ ...sinunVal, [col]: e.value });
	}; //filter Title

	const clrFlt = (state) => (e) => {
		setSinunVal({ ...sinunVal, [state]: null });
		fltTable('', state);
	};

	const onfltDates = (date, state) => {
		setSinunVal({ ...sinunVal, [state]: date });
		let newArr = fltTable(date, state);

		if (newArr.length) {
			myRef.current.filter(
				newArr.map((x) => x[state]),
				state,
				'in'
			);
		} else {
			myRef.current.filter('jfgjhfgjhgvjhfj', state, 'equals');
		}
	};

	const fltTable = (date, state) => {
		let tempD = hqData;

		//initial filter per request

		if (date !== '' && state === 'SDate') {
			tempD = tempD.filter(
				(v) => setDateBack(v.SDate) >= dateFormat(date, 'yyyy-mm-dd') && v.SDate !== ''
			);
		} else if (date !== '' && state === 'EDate') {
			tempD = tempD.filter(
				(v) => setDateBack(v.EDate) <= dateFormat(date, 'yyyy-mm-dd') && v.EDate !== ''
			);
		} else if (date === '') {
			return tempD;
		}

		//Check if other filters are assigned

		tempD =
			sinunVal.SDate !== null && state !== 'SDate'
				? tempD.filter(
						(v) =>
							setDateBack(v.SDate) >= dateFormat(date, 'yyyy-mm-dd') && v.SDate !== ''
				  )
				: tempD;
		tempD =
			sinunVal.EDate !== null && state !== 'EDate'
				? tempD.filter(
						(v) =>
							setDateBack(v.EDate) <= dateFormat(date, 'yyyy-mm-dd') && v.EDate !== ''
				  )
				: tempD;

		return tempD;
	};

	const header = (
		<Header
			showSearch={showSearch}
			onChange={onSearchChange}
			searchValue={searchValue}
			showActive={showAct}
			showYNAct={showOnlyActive}
			showSrchInpt={showSearchClick}
			resetFltr={resetFilter}
			handleToggleCols={handleToggleCols}
			cols={cols}
			
			tableName="עובדי הנהלה"
			//paginatorDropdownAppendTo
			expTableCols={cols.filter((col) => col.showcol === true)}
			data={!showOnlyActive ? hqData.filter((x) => x.ActStts === true) : hqData}
		/>
	);

	let dynamicColumns = cols
		.filter((col) => col.showcol === true)
		.slice(0)
		.reverse()
		.map((col, i) => {
			return (
				<Column
					key={col.field}
					field={col.field}
					header={col.header}
					filter={col.showFilter}
					sortable={false}
					filterElement={
						col.field === 'SDate' ||
						col.field === 'EDate' 
							? dateFilterTemplate
							: col.field==='Ttl' ? ttlFilterTemplate
							:	null
					}
					showFilterOperator={false}		//filter
					showFilterMatchModes={false}  	//filter
					showAddButton={false}		//filter
					showApplyButton={col.field === 'SDate' ||
						col.field === 'EDate' ||  col.field==='Ttl'
					 ? false:true}  //filter
					
					onFilterClear={clrFlt(col.field)}
				
					headerStyle={{ overflow: 'visible'}}
				/>
			);
		});

	let sidebar = sideBar.map((value) => (
		<SideBar
			click={addEnt(value.id)}
			key={value.id}
			img={value.img}
			ttl={value.ttl}
			alt={value.alt}
			url={value.url}
		/>
	));

	return (
		<div className="bg">
			<NavBar shownav={props.shownav} /> 

			<div
				className="container card flex-wrap p-4 surface-card border-round-lg md:w-7" /*style={{width:'950px'}}*/
			>
				{spinner ? <ProgressSpinner className="spinner" /> : null}
				<DataTable
					value={!showOnlyActive ? hqData.filter((x) => x.ActStts === true) : hqData}
					ref={myRef}
					paginator={true}
					rows={10}
					rowsPerPageOptions={[10, 25, 50, 100]}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
					header={header}
					globalFilter={globalFilter}
					selectionMode="single"
					filters={filters1}
					//     autoLayout={true}
					//onSelectionChange={e => setSlctHq(e.value)}
					responsiveLayout="scroll"
					onRowClick={dsplSlctVal}
					emptyMessage="אין נתונים"
				>
					{dynamicColumns}
				</DataTable>
			</div>
			{displayDialog ? <HqModal  isnotActLoaded={isnotActLoaded}
									 			setIsnotActLoaded={setIsnotActLoaded}  
                                            	obj={createEmptyObj()}
                                            /> : null}

			<div className="icon-bar"> {sidebar}</div>
		</div>
	);
};

export default Hdq;