import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const auth = getAuth();

const useAuth = () => {
	const [stateAuth, setStateAuth] = useState(() => {
		const user = auth.currentUser;

		return { initializing: !user, user };
	});

	const onChange = (user) => {
		setStateAuth({ initializing: false, user });
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, onChange);
		// unsubscribe to the listener when unmounting
		return () => unsubscribe();
	}, []);

	return stateAuth;
};

export default useAuth;