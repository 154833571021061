import React, { useState, useEffect } from 'react';

import { Button } from 'primereact/button';
import securitypic from '../../logos/securitypic.svg';
import { Login, LogoutFromSystem } from '../../functions/functions';
import validateLogin from './validateLogin';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useForm } from 'react-hook-form';

const getBrowser = () => {
	// Detect browser name
	return (
		/chrome|crios/i.test(navigator.userAgent) &&
		!/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(navigator.userAgent)
	);
};

export default function LoginToApp(props) {
	let navigate = useNavigate();
	const [loginError, setLoginError] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [errors, setErrors] = useState({});
	const { register, handleSubmit } = useForm();
	
	const styles =
		'text-base surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full';
	
	
	
	const authenticateUser = async (data) => {
		setSpinner(true);

		try {
			await Login(data.email.concat('@is.is'), data.password);
			setSpinner(false);
			sessionStorage.setItem('isLogged', true);
			navigate('/main');
		} catch (err) {
			setSpinner(false);
			console.error('Authentication error', err);
	
			setLoginError(err.code.substring(err.code.indexOf('/') + 1).toUpperCase());
		}
	}; 
	
	const onSubmit = (data) => {
		const errs=validateLogin(data)
		setErrors(errs)
		
		const noErrors = Object.keys(errs).length === 0;
		noErrors && authenticateUser(data);
	}

	useEffect(() => {
		const logOut = async () => {
			sessionStorage.clear();
			await LogoutFromSystem();
		};
		logOut();

		getBrowser();
		if (!getBrowser()) alert('שים לב: האתר לא יוצג טוב בדפדפן זה. מומלץ לעבור לדפדפן כרום');
	}, []);


	return (
		<div
			className="flex flex-column align-items-center"
			style={{ backgroundImage: 'linear-gradient(to right, #FFFFFF, #F0F8FF)', width: '100%', height: '100vh', position: 'absolute' }}
		>
			<div className="flex flex-column w-20rem pt-7">
				{spinner ? (
					<ProgressSpinner style={{ position: 'fixed', margin: '120px' }} />
				) : null}
				<div className="field flex justify-content-center w-full">
					{/*<i className="pi pi-lock" style={{ fontSize: '2em' }}></i> */}
					<img
						alt="securitypic"
						src={securitypic}
						width="160"
						style={{ verticalAlign: 'middle' }}
					/>
				</div>

				<div className="field flex justify-content-center w-full">
					<label htmlFor="email" className="text-xl font-semibold">
						כניסה למערכת
					</label>
				</div>
				<form	onSubmit={handleSubmit(onSubmit)}>
					<div className="field flex justify-content-end flex-wrap w-full">
						<label>	שם משתמש</label>
						<input className={styles} {...register('email', { required: true })}/>
					</div>

					{errors.email  && <p role="alert" style={{ color: 'red' }}>{errors.email}</p>}
					  
					<div className="field flex justify-content-end flex-wrap w-full">
						<label>סיסמה</label>
						<input className={styles} {...register('password', { required: true })} 
							type="password"/>
					</div>
					
					{errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
					{loginError && <p style={{ color: 'red' }}>{loginError}</p>}

					
					<div className="w-full pt-2">
						<Button
							label="התחבר"
							aria-label="Submit"
							type="submit"
							className="w-full"
							onClick={handleSubmit}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}