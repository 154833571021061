import { Routes, Route } from 'react-router-dom';
import Login from './components/Auth/login';
import PrivateRoute from './components/Subcomponents/PrivateRoute ';
import AuthProvider from './contexts/useAuthContext';
import Main from './components/Main';
import DataProvider from './contexts/useDataContext';
import ListsProvider from './contexts/listsContext';


import Empl from './components/Empl/Empl';
import Cust from './components/Cust/Cust';
import Hq from './components/Hq/Hq';
import Refs from './components/Refs/Refs';
import Lists from './components/Lists/Lists';
import Auth from './components/Subcomponents/Auth/Auth';
import Garbage from './components/Garbage/Garbage';
import VisaValidity from './components/Reports/VisaValidity';
import InsrcValidity from './components/Reports/InsrcValidity';
import RegsReport from './components/Reports/Regsreport';
import Missing101 from './components/Reports/Missing101';
import InrReport from './components/Reports/IncrReport';
import CustAgrmntReport from './components/Reports/CustAgrmntReport';
import History from './components/History/History';



import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'primeflex/primeflex.css';

const App = () => {

	return (
		<div>
			<DataProvider>
				<ListsProvider>
					<AuthProvider>
						<Routes>
						{/*	<Route path="/" element={<Empl />} />   */}

							<Route path="/" element={<Login />} />
							<Route path="/login" element={<Login />} />
							<Route  element={<PrivateRoute />}>
								<Route path="/main" element={<Main shownav="main"/>} exact />
								<Route exact path="/empl" element={<Empl shownav="logout"/>} />
								<Route exact path="/cust" element={<Cust shownav="logout"/>} />
								<Route exact path="/hq" element={<Hq shownav="logout" />}  />
								<Route exact path="/refs" element={<Refs shownav="logout"/>}  />
								<Route exact path="/kablanim" element={<Lists shownav="kablanim"/>}  />
								<Route exact path="/titles" element={<Lists shownav="titles"/>}  />
								<Route exact path="/auth" element={<Auth shownav="rashi"/>}  />
								<Route exact path="/garbage" element={<Garbage shownav="rashi"/>}  />
								<Route exact path="/vvalidity" element={<VisaValidity shownav="rashi"/>}  />
								<Route exact path="/insvalidity" element={<InsrcValidity shownav="rashi"/>}  />
								<Route exact path="/regsreport" element={<RegsReport shownav="rashi"/>}  />
								<Route exact path="/missing101" element={<Missing101 shownav="rashi"/>}  />
								<Route exact path="/increport" element={<InrReport shownav="rashi"/>}  />
								<Route exact path="/custagrmntreport" element={<CustAgrmntReport shownav="rashi"/>}  />
								<Route exact path="/history" element={<History shownav="rashi"/>}  />
							</Route>
						</Routes>
					</AuthProvider>
				</ListsProvider>
			</DataProvider>
		</div>
	);
};

export default App;