import React, { useState, useEffect, useContext, useRef } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Header from '../Subcomponents/TableHeader/Header';
import HisModal from './HisModal';
import {getRegsMngs, loadHistory} from '../../functions/functions.js';
import DPicker from '../Subcomponents/DatePicker';
import {ProgressSpinner} from 'primereact/progressspinner';
import NavBar from '../Subcomponents/NavBar/NavBar';
import {AuthContext} from '../../contexts/useAuthContext';
import {DataContext} from '../../contexts/useDataContext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

let propDefaults = [ 
                {field: 'Number',header: '#', showcol: true, initial:true},
                {field: 'Name', header: 'שם פרטי', showcol: true, initial:true},
                {field: 'LName', header: 'שם משפחה', showcol: true, initial:true},
                {field: 'NName', header: 'כינוי', showcol: true, initial:true},
                {field: 'CustNum', header: "מס' סניף", showcol: true, initial:true},
                {field: 'CustName', header: 'לקוח', showcol: true, initial:true},
				{field: 'AffName', header: 'סניף', showcol: true, initial:true},
				{field: 'SDate', header: 'תחילת עב. בחברה', showcol: true, initial:true},
				{field: 'EDate', header: 'סיום עב. בחברה', showcol: true, initial:true},
				{field: 'SDateAff', header: 'תחילת עב. בסניף', showcol: true, initial:true},
				{field: 'EDateAff', header: 'סיום עב. בסניף', showcol: true, initial:true},
				{field: 'RgMngName', header: 'מנהל אזור', showcol: true, initial:true}
            ];


const  His =(props) =>{
    	
		const {regmng, user} = useContext(AuthContext);	
		const {value, displayDialog, setDisplayDialog} = useContext(DataContext);
		const [tbldt, setTbldt] = useState([]);
		const [showTable, setShowTable] = useState(false);
		let myRef = useRef();
	
        const [cols, setCols] = useState(propDefaults);
		const [reglist, setReglist] = useState([]);
     
		const [mngr, setMngr] = useState('');
		
		
		const [slct, setSlct] = useState('');
		const [showFilter, setShowFilter] = useState(false);
		const [showSearch, setShowSearch] = useState(false);
		const [spinner, setSpinner] = useState(false);
	
		const [globalFilter,setGlobalFilter] = useState(null);
		const [searchValue, setSearchValue] = useState('');
        
    useEffect(() => {
        console.log('start History');
       
			const loadData = async() =>{
			 setSpinner(true);
			let regListTmp = await getRegsMngs(); 

			if(regmng){ //filtering the regs list if the user is not manager
				regListTmp=regListTmp.filter(x=> x.num===user.user.displayName)
				setReglist(regListTmp);
			}else{
				regListTmp.push({name: 'כל מנהלי אזור', num: '0000'});
				setReglist(regListTmp);
			} 
		
			setSpinner(false);
    };
		
		loadData();
		
    }, [regmng, user]);
    
    
	const  dsplSlctVal= e =>{
		let nm = e.data.Number;
		setSlct(nm);
		setDisplayDialog(true);
	};
    
	
	const runTable = async()=>{
		if(value===null || (value.SDate==='' && value.EDate==='')){
			 alert('נא להזין לפחות תאריך אחד'); return;
		}
		if(value.SDate==='' && value.EDate==null){
			 alert('נא להזין לפחות תאריך אחד'); return;
		}
		if(value.SDate==null && value.EDate===''){
			 alert('נא להזין לפחות תאריך אחד'); return;
		}
		
	 	if(reglist.filter((x) => x.name === mngr)===undefined){ alert('נא לבחור מנהל אזור'); return; }
		
		let tmpdata = await loadHistory(value.SDate,value.EDate, reglist.filter((x) => x.name === mngr)[0].num) ;
		
		setTbldt(tmpdata);
		setShowTable(true);
		
	}
	
    const onSearchChange = (event) => { 
        myRef.current.filter(null,'', 'equals');
        setGlobalFilter(event.target.value);
        setSearchValue(event.target.value);
    }; //search 
    
    const showSearchClick=()=>{
        setShowSearch(!showSearch);
        setGlobalFilter(null);
        setSearchValue('');
        setShowFilter(false);
    };
    
    const handleToggleCols= value => {
        let newArr = [...cols];
        let newObj = {...value, 'showcol': !value.showcol};
        newArr[newArr.map(i => i.field).indexOf(value.field)] = newObj;
        setCols(newArr);
    };
    
    const resetFilter = async() =>{
        console.log('reset');
        setGlobalFilter(null);
        setSearchValue('');
        setShowFilter(false);
       	setTbldt(tbldt);
        myRef.current.reset()
    };
	
	const header = <Header 		showSearch={showSearch}
						   		onChange={onSearchChange}
								runFltr={()=> setShowFilter(!showFilter)}
								showFilter={showFilter}
								searchValue={searchValue}
								showActNotActIcon='false'
								showSrchInpt={showSearchClick}
								resetFltr={resetFilter}
								handleToggleCols={handleToggleCols}
								cols={cols}
								data={tbldt}
					   			expTableCols={cols.filter((col) => col.showcol === true)}
								tableName='תיעוד שיוכים'
	/>;

        let dynamicColumns = cols.filter(col => col.showcol === true).slice(0).reverse().map((col,i) =>{
             return <Column key={col.field} field={col.field} header={col.header} filter={showFilter} sortable={false} 
                  headerStyle={{overflow:'visible'}}  /> ;
        });
	
		let table = <DataTable value={tbldt}
                              	ref={myRef}
								paginator={true}
								rows={10}
								rowsPerPageOptions={[10, 25, 50, 100]}
								paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
								selectionMode="single"
								globalFilter={globalFilter}
								responsiveLayout="scroll"
								reorderableColumns={true}
								header={header}
                                onSelectionChange={e => setSlct(e.value.Number)}
                                onRowClick={dsplSlctVal} 
                                emptyMessage="אין נתונים"
                                >
                      {dynamicColumns}
                    </DataTable> 
	
        return(
            <div>
                <NavBar shownav={props.shownav}/>
                 <div className="container">
                    {spinner ? <ProgressSpinner className="spinner"/>:null}
                  		<div className="w-14rem text-right p-2" style={{ float: 'right' }}>
							<Dropdown
									value={mngr}
									onChange={(e) =>setMngr(e.value)}
									options={reglist.map((k) => k.name)}
									placeholder="בחר מנהל אזור"
									className={`text-xs w-full border-1 border-solid outline-none focus:border-primary focus:shadow-none inpHght 
													hover:border-solid plcholdr dropDown`}
									dropdownIcon="pi pi-angle-down"
									style={{ direction: 'rtl' }}
								/>
						</div>
					 <div >
					 	<div style={{ direction: 'rtl' }} className="grid w-full mx-auto mt-1">
							<div className="col-12 md:col-3">
								<div className="border-round-lg border-1 border-300">
									<DPicker inpt='SDate' vl={value!=null ? value.SDate : null}  lbl='תחילת עבודה בסניף:'/>
								</div>
							</div>
							<div className="col-12 md:col-3">
								<div className="border-round-lg border-1 border-300">
									<DPicker inpt='EDate' vl={value!=null ? value.EDate : null} lbl='סיום עבודה בסניף:' />
								</div>
							</div>
							<div className="col-12 md:col-2">
								<Button className='p-button-text buttonTextFont' 
									style={{padding:'10px'}} onClick={runTable} color="primary">חפש</Button>
							</div>
						</div>	
						 </div>
                	{showTable && <hr style={{width:'95%'}}  /> }
						{showTable && (
					<div className="container card flex-wrap p-4 mt-4 surface-card border-round-lg md:w-12">
						{table}
					</div>
				)}
				</div>
					{displayDialog ? <HisModal 
							slct={slct}   
							displayDialog={displayDialog}
							onClose={()=>setDisplayDialog(false)}
					/> : null }
				
            </div>
            );
  
};


export default His;


/*

   
                
       
	   
	  
	   

*/