import React from 'react';
import Text from '../Subcomponents/TextInput';
import { Chip } from 'primereact/chip';
import { InputTextarea } from 'primereact/inputtextarea';

const  EmplDetailsGarbage = ({value}) => {
    return (
      <div style={{direction: 'rtl'}} className='grid w-full mx-auto'>
            <div className='fr col-12 md:col-4'>
                <div className='border-round-lg border-1 border-300'>
                    <Text inpt='Number' vl={value.Number} dis={true} lbl="מספר עובד"/>
                    <Text inpt='NewNum' vl={value.NewNumd} dis={true} lbl="מספר עובד חדש"   />
                    <Text inpt='Name'  vl={value.Name} dis={true}lbl="שם פרטי"  />
                    <Text inpt='LName' vl={value.LName} dis={true} lbl="שם משפחה"  />
                    <Text inpt='NName' vl={value.NName} dis={true} lbl="כינוי"  />
                </div>
                <div className='border-round-lg border-1 border-300 mt-2' >
                    <Text inpt='SDate'  vl={value.SDate} lbl ='תחילת עבודה' dis={true} />
                    <Text inpt='EDate'  vl={value.EDate} lbl='סיום עבודה' dis={true}/>
                </div>
                <div  className='border-round-lg border-1 border-300 mt-2'>
                    <Text inpt='Bday' vl={value.Bday} lbl='ת.לידה' dis={true} />
                    <Text  inpt='Tel' vl={value.Tel} lbl="טלפון"  dis={true}  /> 
                </div>
            </div>
            <div className='col-12 md:col-5'>
                <div className='border-round-lg border-1 border-300'>
                    <Text inpt='Bcountry'  lbl={'ארץ לידה'} vl={value.Bcountry}dis={true}/>  
                    <Text inpt='Kablan' lbl={'קבלן'}  vl={value.Kablan} dis={true} />
                    <Text inpt='Status' lbl={'סטטוס'} vl={value.Status} dis={true} />
                    <Text  inpt='Id' vl={value.Id} lbl="ת.ז." dis={true}  />
                    <Text  inpt='Visa'vl={value.Visa} lbl="ויזה"  dis={true}  />
                    <Text inpt='VValdty' vl={value.VValdty} lbl='תוקף ויזה' dis={true}/>
                    <Text  inpt='Insrnce' vl={value.Insrnce} lbl="מס' ביטוח" dis={true}/>
                    <Text inpt='InsrnceVldty' vl={value.InsrnceVldty}lbl='תוקף ביטוח' dis={true}/>      
                </div>
                <div className='border-round-lg border-1 border-300 mt-2'>
                    <Text  inpt='Strt'  vl={value.Strt}  lbl="רחוב" dis={true} />
                    <Text  inpt='Cty' vl={value.Cty}  lbl="עיר" dis={true}  />
                </div>
            </div>             
            <div className='fr col-12 md:col-3'>
                <div  className='border-round-lg border-1 border-300'>
                    <Text inpt={'Gndr'} lbl='מין' vl={value.Gndr} dis={true} /> 
                    <Text inpt={'T101'}  vl={value.T101} lbl={'טופס 101'} dis={true} /> 
                    <Text inpt={'Mstnn'}  vl={value.Mstnn}  lbl={'מסתנן'} dis={true} />
                    <Text  inpt='KHol' vl={value.KHol}  lbl="מספר קופ''ח" dis={true}/>
                </div>
                <div className='mt-2'>
                    <label className='fr'>הערות</label>
                    <InputTextarea rows={5}  name ='Notes' value={value.Notes} className='w-full'/>   
                </div>
                   <div className='mt-2 text-center '>
						<Chip label={value.ActStts ? 'עובד פעיל' : 'עובד לא פעיל'}
						className={`w-8rem justify-content-center ${value.ActStts ? 'bg-indigo-400 text-0' : 'bg-black-alpha-30 text-0'}`} /> 
					</div>
            </div>          
        </div>  
        );
};

export default EmplDetailsGarbage;