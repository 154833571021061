import React, { useState, useContext } from 'react';
import EditItem from './EditItem';
import { ListsContext } from '../../contexts/listsContext';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';

import './Kablanim.css';

const Item = (props) => {
	const [isediting, setIsediting] = useState(false);
	const { removeItem, entity } = useContext(ListsContext);

	return (
		<li className="liDiv">
			{isediting ? (
				<EditItem
					id={props.item.id}
					title={props.item.item}
					isediting={isediting}
					setIsediting={setIsediting}
				/>
			) : (
				<>
					<div className="divText">{props.item.item}</div>
					<div className="item" style={{ right: 'unset' }}>
						<Button
							icon="pi pi-trash"
							className="p-button-rounded p-button-text p-button-plain"
							onClick={() => removeItem(entity, props.item.id)}
							style={{ fontSize: '0.8em', color: 'dimgray' }}
						/>
						<Button
							icon="pi pi-pencil"
							className="p-button-rounded p-button-text p-button-plain"
							onClick={() => setIsediting(!isediting)}
							style={{ fontSize: '0.8em', color: 'dimgray' }}
						/>
					</div>
				</>
			)}
		</li>
	);
};

export default Item;