import React, { useContext } from 'react';
import { Outlet , Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/useAuthContext';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {

	const { user } = useContext(AuthContext);
	
	let islogged = sessionStorage.getItem('isLogged');

	return (
		!user.initializing ?
			 user.user && islogged? <Outlet {...rest} /> : <Navigate to="/login" />
			:
		null
			
		
	);
};

export default PrivateRoute;

				
				