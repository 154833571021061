import { initializeApp } from "firebase/app";

import { getFirestore, getDocs, query, where, collection, setDoc, doc, deleteDoc, updateDoc , getDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import firebaseConfig from '../firebaseSettings.js';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const auth = getAuth(app);
/*******************************************/

export const Login =async(email, password)=>{
	return await signInWithEmailAndPassword(auth, email, password)
}

/*******************************************/
export const LogoutFromSystem =async()=>{
	sessionStorage.clear();
	await signOut(auth);
}

/*******************************************/
export const getData  = async(colctn, actNact)=>{
        console.log('Load data for: ' + colctn + ' => '  + actNact.toString() );
	
		const q = query(collection(db, colctn), where("ActStts", "==", actNact));
	   return await getDocs(q)
        .then(snapshot => {
           return snapshot.docs.map(doc => doc.data());
        })
        .catch(err=>{ console.log(err); throw(err); });
    };

/*******************************************/
/************************************************/
export const readDataAll = async (colctn)=>{
	
	const q = await getDocs(collection(db, colctn));
    return q.docs.map(doc => doc.data());
}

/*************************************/
export const getDocument = async(colctn, dc)=>{
	const q = doc(db, colctn, dc);
 

	return await getDoc(q)
        .then(snapshot => {
           return snapshot.data();
        }) 
        .catch(err=>{ console.log(err); throw(err); });
};
/*******************************************/
export const getDataFromHis=async(num)=>{
	
	    console.log('Load data from History for: ' + num);
        let count=0;
	
		const q = query(collection(db, 'His'), where('Number', '==', num));
	   	
		return await getDocs(q)
        .then(snapshot => {
            let Data = snapshot.docs.map(doc => {
                let d=doc.data();
                let dataSet = {key: count++, Number:d.Number, CustNum:d.CustNum, CustName:d.CustName, AffName:d.AffName,
                                SDateAff:d.SDateAff, EDateAff: d.EDateAff};
            return dataSet;
             });
			return Data;
        })
        .catch(err=>{ console.log(err); throw(err); });
    
}
/*******************************************/
export const updateDocument=async(colctn, id, data)=>{

	const tmp = doc(db, colctn, id);

	return await updateDoc( tmp , data)
	.then(()=> {
		return true;
	})
	.catch(error=> {
		console.error("Error writing document: ", error);
	});
	
}
/*******************************************/
export const delDoc=async(colctn, id)=>{
	
	return await deleteDoc(doc(db, colctn, id))
	.then(()=> {
    	return true;
	}).catch(error=> {
    	console.error("Error removing document: ", error);
	});	
	

}
/*******************************************/
export const addDoc=async(colctn, id, data)=>{
	
	return await setDoc(doc(db, colctn, id), data).then(()=> {
			return true;
		})
		.catch(error=> {
			console.error("Error writing document: ", error);
		});
}
/*******************************************/
export const movetoHistory=async(obj)=>{
	  console.log('Moves data number for Empl: '+ obj.Number +' to History');
	
		return await setDoc(doc(db, "His", obj.Number + "-" + obj.CustNum + "-" + obj.EDateAff), obj)
		.then(()=> {
			return true;
		})
		.catch(error=> {
			console.error("Error writing document: ", error);
		});
	
}
/*******************************************/
export const movetoGarbage=async(obj, num)=>{
	  console.log('Moves data number '+ num +' to Garbage');
	
		return await setDoc(doc(db, "Garbage", num), obj)
		.then(()=> {
			return true;
		})
		.catch(error=> {
			console.error("Error writing document: ", error);
		});
     
}
/*******************************************/
export const getDataPerMng=async(colctn, actNact, num)=>{
	 console.log('Load data for: ' + collection + ' => '  + actNact.toString() );
	

const q = query(collection(db, colctn), where("ActStts", "==", actNact), where("RgMngNum", "==", num));
	
	   return await getDocs(q)
        .then(snapshot => {
           return snapshot.docs.map(doc => doc.data());
        })
        .catch(err=>{ console.log(err); throw(err); });
	
    };
/*******************************************/
export const getRegsMngs=async()=>{
	 console.log('Load reg Managers');
	
	const q = query(collection(db, "Hq"), where("Ttl", "==", 'מנהל אזור'), where("ActStts", "==", true));
	
	   return await getDocs(q)
        .then(snapshot => {
           let Data = snapshot.docs.map(doc => {
                let d=doc.data();
                let dt = {name: `${d.HqName} ${d.HqLName}`, num: d.HqNum};
            return dt;
             });
        return Data;
        })
        .catch(err=>{ console.log(err); throw(err); });
	
}
/*******************************************/
export const getDataCustRefs  = async(num)=>{
        console.log(`Get refs data for cust: ${num}`);
	
		let Dt=[];
	
		const q = query(collection(db, 'Empl'));
	
	   return await getDocs(q)
        .then(snapshot => {
          snapshot.forEach(doc => {
					let tmpArr=doc.data().ref2Cust;
					for (let key in tmpArr) {	
						if(tmpArr[key].CustNum === num){
							let d=doc.data();
							let dataSet = {key:d.Number, 'Number': d.Number, 'Name': d.Name, 'LName':d.LName, 'NName': d.NName,
											'SDateAff': tmpArr[key].SDateAff, 'EDateAff':  tmpArr[key].EDateAff};
							Dt.push(dataSet);
						} 
					}  
  				});
				return Dt;
        })
        .catch(err=>{ console.log(err); throw(err); });
	
	}; 
/*******************************************/
export const updateRegNumInRefs = async(RgMngNum, CustNum, refsData) =>{
        console.log(`Update Reg manager Num ${RgMngNum} in refs`);
	
        let arr = refsData;
       
		 for (let i=0; i<arr.length; i++){
			
			let q = doc(db, 'Empl', arr[i].Number);
			 
		 	await getDoc(q)
        		.then(async(doc) => 
					    {    
				 
                    let tmpArr=doc.data().ref2Cust;
    				let ref2Cust=[];
    					for (let key in tmpArr) {
    						if(tmpArr[key].Rgnmgr !== RgMngNum &&
    						    tmpArr[key].CustNum === CustNum){  
    							var newObj = {...tmpArr[key], 'Rgnmgr': RgMngNum}
    							ref2Cust.push(newObj);
    						}else{
    						ref2Cust.push(tmpArr[key]);
    						}
    					}
				 
				 	await updateDoc( q , {ref2Cust})
				    .then(()=> { console.log("Ref2Cust successfully updated in Empl " + doc.data().Number );});
				
                });
		 }
	
};   
/*******************************************/
export const updateCust  = async(NewNum, NewName, OldNum)=>{
        console.log(`Move Affs from HqNum ${OldNum} to HqNum ${NewNum}`);
				
			let tmpList = query(collection(db, 'Cust'), where('RgMngNum', '==', OldNum));
			let custNumList = await getDocs(tmpList)
				.then(snapshot => {
				   return snapshot.docs.map(doc => doc.data().CustNum);
				})
       

			for(let x in custNumList){
				let q = doc(db, 'Cust', custNumList[x]);
				
				await updateDoc( q , {RgMngName: NewName, RgMngNum: NewNum})
				.then(()=> { console.log("New Reg Manager successfully updated!" );});
			}
};
/*******************************************/
export const updateRefsWhenMoveAffs = async(NewNum, OldNum) =>{
        console.log(`Update Reg manager Num ${NewNum} in refs`);
	
		let updt;
		let newList=[];
			
			let tmpList = await getDocs(collection(db, "Empl"));
			let emplList = 	tmpList.docs.map(doc => ({'Number':doc.data().Number, 'ref2Cust':  doc.data().ref2Cust}) );
	
	
			for(let x in emplList) { //loop over the main list
    			let tempRef2Cust = emplList[x].ref2Cust;
					let ref2Cust=[];
					updt=false;
					for(let q in tempRef2Cust){ //loop over the refs in tempRef2Cust
						if(tempRef2Cust[q].Rgnmgr === OldNum){  
    							var newObj = {...tempRef2Cust[q], 'Rgnmgr': NewNum}
								ref2Cust.push(newObj);
								updt=true;
    					}else{
    							ref2Cust.push(tempRef2Cust[q]);
						}
					};
					if(updt){
						newList.push({'Number':emplList[x].Number, 'ref2Cust': ref2Cust})
					}
			}
	
			for(let a in newList){
				let tmp = newList[a].ref2Cust
				let q = doc(db, 'Empl', newList[a].Number);
				
				await updateDoc( q , {'ref2Cust' : tmp})
				.then(()=> {  console.log("Ref2Cust successfully updated in Empl " + newList[a].Number );});
			
			}
		
}; 
/*******************************************/
export const deleteRegFromRefandCust = async(num) =>{
        console.log(`Delete Hq manager ${num} from refs and Cust`);
	
        let updt;
        //Delete from Refs
		
		let tmpList = await getDocs(collection(db, "Empl"));
	
                tmpList.forEach(async(doc) => 
                    {
        				let tmpArr=doc.data().ref2Cust;
        				let ref2Cust=[];
        				updt=false;
        					for (let key in tmpArr) {
        						if(tmpArr[key].Rgnmgr === num ){
        							var newObj = {
        								CustNum: tmpArr[key].CustNum,
        								SDateAff: tmpArr[key].SDateAff,
        								EDateAff: tmpArr[key].EDateAff,
        								//Notes: tmpArr[key].Notes,
        								Rgnmgr: ""
        							};
        							ref2Cust.push(newObj);
        							updt=true;
        						}else{
        						ref2Cust.push(tmpArr[key]);
        						}
        					}	
					
    					if(updt){
							let q = doc(db, 'Empl', doc.data().Number);
							await updateDoc( q , {ref2Cust})
				    		.then(()=> { console.log("Ref2Cust successfully updated in Empl " + doc.data().Number );});
    					}
					});
				
	
        //Delete from Cust
		const w = query(collection(db, 'Cust'), where('RgMngNum', '==', num));
		const querySnapshot = await getDocs(w);
       
				querySnapshot.forEach(async(doc)=>{
					let a = doc(db, 'Cust', doc.data().CustNum);
					await updateDoc( a , { "RgMngNum" : "", "RgMngName" : ""  })
				    .then(()=> { console.log("Cust successfully updated in " + doc.data().CustNum );});
					
				 
                });
}; 
/*******************************************/
export const updtRegName = async(num, name) =>{
	console.log(`Update Reg mngr Name ${name} in Cust`);

	const w = query(collection(db, 'Cust'), where('RgMngNum', '==', num));
	const querySnapshot = await getDocs(w);
	
			querySnapshot.forEach(async(doc)=> {
				let a = doc(db, 'Cust', doc.data().CustNum);	
				await updateDoc( a , { "RgMngName" : name })
				    .then(()=> { console.log("Name successfully updated in Cust! " + doc.data().CustNum );});
				
			});
    };
/****************************************************************/
export const loadHistory  = async(start,end, num)=>{
	console.log(`Load data from History for:  ${num}`);
       
		if(num==='0000'){
			
			const q = query(collection(db, 'His'));
		   	return await getDocs(q)
        	 	.then(snapshot =>{ 
					return getArr(snapshot, start,end)
				})
        	.catch(err=>{ console.log(err); throw(err); });
		}else{
			const q = query(collection(db, 'His'), where("Rgnmgr", "==", num));
	   		return await getDocs(q)
        		.then(snapshot => {
					return getArr(snapshot, start,end)
				})
        	.catch(err=>{ console.log(err); throw(err); });
	 	}
   	};

const setDateBack = (x)=>{
        let xyz;
    	if(x===''){
    	   xyz=x;
    	}else{
        	let	d = x.substring(0, 2);
        	let	m = x.substring(3, 5); 
        	let	y = x.substring(x.length-4, x.length);
        	return xyz=y.concat("-").concat(m).concat("-").concat(d);
   	}
     return xyz;
}

const getArr=(snapshot, start,end)=>{
	  	let Dt=[];
		let st = start!=null ?  setDateBack(start): undefined;
		let nd = end!=null ? setDateBack(end): undefined;
	 	
		snapshot.docs.forEach(doc=> {
						let stE = setDateBack(doc.data().SDateAff);
						let ndE = setDateBack(doc.data().EDateAff);
						if(start!==undefined && end!==undefined){
							if(!(stE>nd) && !(ndE<st)){ Dt.push(doc.data());}
						}
						if(start && end===undefined){
							if((st<=ndE) ){ Dt.push(doc.data());}
						}
						if(start===undefined && end){
							if((nd>=stE) ){Dt.push(doc.data());}
						}
			});

	return Dt;
	
}
/**********************************************************/