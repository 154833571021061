import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../Subcomponents/TableHeader/Header';
import { ProgressSpinner } from 'primereact/progressspinner';
import NavBar from '../Subcomponents/NavBar/NavBar';
import { getData, getRegsMngs } from '../../functions/functions.js';
import { DataContext } from '../../contexts/useDataContext';
import { AuthContext } from '../../contexts/useAuthContext';
import DropDownRegs from '../Subcomponents/DropDownRegs';

let propDefaults = [
	{ field: 'Number', header: '#', showcol: true, initial: true },
	{ field: 'Name', header: 'שם פרטי', showcol: true, initial: true },
	{ field: 'LName', header: 'שם משפחה', showcol: true, initial: true },
	{ field: 'NName', header: 'כינוי', showcol: true, initial: true },
	{ field: 'Tel', header: 'טלפון', showcol: true, initial: true },
	{ field: 'Visa', header: 'מספר ויזה', showcol: true, initial: true },
	{ field: 'AffName', header: 'סניף', showcol: true, initial: true },
	{ field: 'InsrnceVldty', header: 'תוקף ביטוח', showcol: true, initial: true },
	{ field: 'Notes', header: 'הערות', showcol: true, initial: true },
	{ field: 'RegMngr', header: 'מנהל אזור', showcol: true, initial: true },
];

const Refs = (props) => {
	const { regmng, user } = useContext(AuthContext);
	const {
		emplData,
		setEmplData,
		custData,
		setCustData,
		setDateBack,
		setMngChosen,
		refsData,
		setRefsData,
	} = useContext(DataContext);
	
	const [runFirstTime, setRunFirstTime] = useState(false);
	const [reglist, setReglist] = useState([]);

	let myRef = useRef();

	const [showTable, setShowTable] = useState(false);

	const [cols, setCols] = useState(propDefaults);

	const [showSearch, setShowSearch] = useState(false);
	const [spinner, setSpinner] = useState(false);

	const [globalFilter, setGlobalFilter] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		const loadData = async () => {
			setSpinner(true);
			setRunFirstTime(true);

			if (!emplData.length) {
				let emplD = await getData('Empl', true);
				setEmplData(emplD);
			}
			if (!custData.length) {
				let custD = await getData('Cust', true);
				setCustData(custD);
			}

			let regListTmp = await getRegsMngs();

			if (regmng) {
				//filtering the regs list if the user is not manager
				regListTmp = regListTmp.filter((x) => x.num === user.user.displayName);
				setReglist(regListTmp);
			} else {
				regListTmp.push({ name: 'כל מנהלי אזור', num: '0000' });
				setReglist(regListTmp);
			}

			setSpinner(false);
		};

		!runFirstTime && loadData();
	}, [regmng, user, runFirstTime, custData, emplData, setEmplData, setCustData]);

	const setAddData = (doc, rf) => {
		let indx = custData.findIndex((x) => x.CustNum === rf.CustNum);
		return {
			...doc,
			RegMngr: indx !== -1 ? custData[indx].RgMngName : null,
			AffName: indx !== -1 ? custData[indx].AffName : null,
		};
	};

	const slctMngr = async (e) => {
		setSpinner(true);
		setShowTable(true);

		setMngChosen(e.value);

		const regNum = reglist.filter((x) => x.name === e.value)[0].num;
		
		let today = new Date();
		let newdate = new Date(today);
		newdate.setDate(newdate.getDate() + 7);

		let d = newdate.getDate();
		let m = newdate.getMonth() + 1;
		let y = newdate.getFullYear().toString();
		let Ldate = y.concat('-').concat(m).concat('-').concat(d);

		let Dt = [];
		for (let x in emplData) {
			let tmpArr = emplData[x].ref2Cust;
			if (
				new Date(Ldate) >= new Date(setDateBack(emplData[x].InsrnceVldty)) ||
				emplData[x].InsrnceVldty === ''
			) {
				for (var q in tmpArr) {
					if (tmpArr[q].Rgnmgr === regNum || regNum === '0000') {
						//מנהל ספציפי
						Dt.push(setAddData(emplData[x], tmpArr[q]));
					}
				}
			}
		}

		setRefsData(Dt);

		setSpinner(false);
	};

	const onSearchChange = (event) => {
		myRef.current.filter(null, '', 'equals');
		setGlobalFilter(event.target.value);
		setSearchValue(event.target.value);
	}; //search

	const showSearchClick = () => {
		setShowSearch(!showSearch);
		setGlobalFilter(null);
		setSearchValue('');
	};

	const handleToggleCols = (value) => {
		let newArr = [...cols];
		let newObj = { ...value, showcol: !value.showcol };
		newArr[newArr.map((i) => i.field).indexOf(value.field)] = newObj;
		setCols(newArr);
	};

	console.log('qw');

	const header = (
		<Header
			showSearch={showSearch}
			onChange={onSearchChange}
			searchValue={searchValue}
			showSrchInpt={showSearchClick}
			handleToggleCols={handleToggleCols}
			cols={cols}
			data={refsData}
			expTableCols={cols.filter((col) => col.showcol === true)}
		
			tableName="דוח תוקף ביטוח ל-7 ימים הבאים"
		/>
	);

	let dynamicColumns = cols
		.filter((col) => col.showcol === true)
		.slice(0)
		.reverse()
		.map((col, i) => {
			return (
				<Column
					key={col.field}
					field={col.field}
					header={col.header}
					sortable={false}
					headerStyle={{ overflow: 'visible'}}
				/>
			);
		});

	
	let table = (
		<DataTable
			value={refsData}
			ref={myRef}
			paginator={true}
			rows={10}
			rowsPerPageOptions={[10, 25, 50, 100]}
			paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
			currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
			selectionMode="single"
			globalFilter={globalFilter}
			responsiveLayout="scroll"
			reorderableColumns={true}
			header={header}
			emptyMessage="אין נתונים"
		>
			{dynamicColumns}
		</DataTable>
	);

	return (
		<div>
			<NavBar shownav={props.shownav} />
			<div className="container">
				{spinner ? <ProgressSpinner className="spinner" /> : null}

				<div className="w-14rem text-right p-3 mr-6" style={{ float: 'right' }}>
					<DropDownRegs reglist={reglist} slctMngr={slctMngr} />
				</div>
				<hr style={{ width: '95%' }} />

				{showTable && (
					<div className="container card flex-wrap p-4 mt-4 surface-card border-round-lg md:w-8">
						{table}
					</div>
				)}
			</div>
		</div>
	);
};

export default Refs;

/*
   
  

*/