import male from '../../logos/male.svg';
import female from '../../logos/female.svg';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

const MstnOptions = [
	{ label: 'מסתן', value: 'מסתנן' },
	{ label: "נתב''ג", value: "נתב''ג" },
	{ label: 'ארעי', value: 'ארעי' },
	{ label: 'ערער', value: 'ערער' },
	{ label: 'אחר', value: 'אחר' },
];

const StatusOptions = [
	{ label: 'ממתין לביטוח', value: 'ממתין לביטוח' },
	{ label: 'ממתין לכרטיס', value: 'ממתין לכרטיס' },
	{ label: 'קיים ביטוח וכרטיס', value: 'קיים ביטוח וכרטיס' },
	{ label: 'ללא ביטוח', value: 'ללא ביטוח' },
];


export const bodyTemplateName = (item) => {
	return (
		 
		<div className="text-right pr-4">
			<Tooltip target='.aaa' />
			<img
				alt={item.Name}
				src={item.Gndr === undefined ? null : item.Gndr === 'זכר' ? male : female}
			//	width={item.Gndr === 'זכר' ? 50 : 30}
				style={{ verticalAlign: 'middle', marginLeft:'8px' }}
				width='30'
				className="aaa"
				data-pr-tooltip={item.Name + " " + item.LName}
			/> 	
			<span className="pr-1">{item.Name}</span>
		</div>
		
	);
};



export const representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.value}</span>
            </div>
        );
    }
	
export const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option.value}</span>;
    }
	 
export const mstnnFilterTemplate = (options) => {
		return (
			<MultiSelect
				value={options.value}
				options={MstnOptions}
				itemTemplate={representativesItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				optionLabel="value"
				placeholder="אפשרויות"
			/>
		);
	};
	
export const statusFilterTemplate = (options) => {
        return( 
			<Dropdown 
				value={options.value} 
				options={StatusOptions} 
				onChange={(e) => options.filterCallback(e.value, options.index)} 
				itemTemplate={statusItemTemplate} 
				placeholder="בחר סטטוס" 
				className="p-column-filter" 
				showClear />
		)
    }

export const t101FilterTemplate =(options) => <Dropdown style={{width: '100%'}} value={options.value} options={[
                {label: 'כן', value: 'כן'},
                {label: 'לא', value: 'לא'},
            ]} placeholder='מילוי 101' onChange={(e) => options.filterCallback(e.value, options.index)}  />;


export const filterSets={
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			SDate: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_AFTER }],
			},
			EDate: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
			},
			VValdty: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
			},
			InsrnceVldty: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
			},
			Mstnn: { value: null, matchMode: FilterMatchMode.IN },
			Status: {
				operator: FilterOperator.OR,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			Ttl: {
				operator: FilterOperator.OR,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			T101: {
				operator: FilterOperator.OR,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			CustName: {
				operator: FilterOperator.OR,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			AffName: {
				operator: FilterOperator.OR,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
		}

/***************************Date Converter*************************************/


/******************************************************************/