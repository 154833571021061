import React, {createContext} from 'react';
import useAuth from '../hooks/useAuth';

export const AuthContext = createContext();

const AuthProvider=(props)=>{
		const user = useAuth();
		const admn =user.user!==null ? user.user.photoURL.slice(-3,-2)==='y' ? true : false : null;
		const uid = user.user!==null ? user.user.uid : null;
		const write =user.user!==null ? user.user.photoURL.slice(-2,-1)==='y' ? true : false : null;	
		const regmng =user.user!==null ? user.user.photoURL.slice(-1)==='y' ? true : false : null;	

		return (
		<AuthContext.Provider value={{user, admn, uid, write, regmng}} >
				{props.children}
		</AuthContext.Provider>
		);
	};

export default AuthProvider;