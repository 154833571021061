import {useState} from 'react';
var dateFormat = require('dateformat');

	function parseDate(input) {
		var parts = input.match(/(\d+)/g); 			
		return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
	}

	const useRcState = () =>{
	
	const [emplData, setEmplData] = useState([]);
	const [custData, setCustData] = useState([]);
	
	const [hqData, setHqData] = useState([]);
	const [refsData, setRefsData] = useState([]);
	const [displayDialog,setDisplayDialog]=useState(false);	
	const [value, setValue] = useState(null);	

	const [valueRef, setValueRef] = useState(null);
	const [ttlList, setTtlList] = useState([]);
	const [kabsList, setKabsList] = useState([]);
	const [growl, setGrowl] = useState('');
	const [reglist, setReglist] = useState([]);
	const [AffList, setAffList] = useState( /*props.arrCust.map(k => k.AffName).sort()*/);
    const [arrIndex, setArrIndex] = useState();
	const [filteredData, setFilteredData] = useState([]);	
	const [mngChosen, setMngChosen] = useState(null);
		
	//Cust Data Dropdown
	const [custDataDrpDown, setCustDataDrpDown] = useState([]); //custName list //Filtered Custname List
	const [aftDataDrpDown, setAftDataDrpDown] = useState([]); //AffName list //Filtered Custname List
	const [countryDataDrpDown, setCountryDataDrpDown] = useState([]); //AffName list //Filtered Custname List
	const [isSelected, setIsSelected] = useState(false) // select Value in dropdown
	const [isEditing, setIsEditing] = useState({CustName: false, AffName: false, Bcountry: false}) //if edit dropdown inputText
	const [valueEditDrpDown, setvalueEditDrpDown] = useState({CustName: '', AffName: '', Bcountry: ''}); //edit dropdown inputText
	
	const [loading, setLoading] = useState(false)
return {
	emplData, setEmplData,
	custData, setCustData,
	custDataDrpDown, setCustDataDrpDown,
	aftDataDrpDown, setAftDataDrpDown,
	hqData, setHqData,
	refsData, setRefsData,
	displayDialog,
	setDisplayDialog,
	value,	setValue,
	growl, setGrowl,
	isSelected, setIsSelected,
	isEditing, setIsEditing,
	valueEditDrpDown, setvalueEditDrpDown,
	countryDataDrpDown, setCountryDataDrpDown,
	loading, setLoading,
	filteredData, setFilteredData,
	mngChosen, setMngChosen,
	selectValue: rowData => { 
		setValue(rowData);
		setDisplayDialog(true);
	},
	handleChange: e => {
		setValue({...value, [e.target.name]:e.target.value });  
	},
	handleChangeD:  name=> e => {
        setValue({...value, [e.target.name]: e===null ? '' :dateFormat(e.target.value, "dd-mm-yyyy")} );
    },
	handleChangeS: e => {
		setValue({...value, [e.target.name]:e.target.value }); 
	},
	setDateYMD : (x)=>{
		
        let xyz;
    	if(x===''){
    	   xyz=x;
    	}else{
        	let	d = x.substring(0, 2);
        	let	m = x.substring(3, 5); 
        	let	y = x.substring(x.length-4, x.length);
        	return xyz=parseDate(y.concat("-").concat(m).concat("-").concat(d));
   	}
		
     return xyz;
	},
	setDateBack : (x)=>{
        let xyz;
    	if(x===''){
    	   xyz=x;
    	}else{
        	let	d = x.substring(0, 2);
        	let	m = x.substring(3, 5); 
        	let	y = x.substring(x.length-4, x.length);
        	return xyz=y.concat("-").concat(m).concat("-").concat(d);
   	}
     return xyz;
	},
	createNewRef2Cust: (data, num)=>{
        
        let ref2Cust=[];  //עושה בכל מקרה
        let obj={};
        for (var key in data) {
            obj={
                    CustNum: data[key].CustNum,
                    SDateAff: data[key].SDateAff,
                    EDateAff: data[key].EDateAff,
    				//Notes: '',
    			    Rgnmgr: data[key].Rgnmgr
			    };	
            if(obj.EDateAff==='')ref2Cust.push(obj);
        }
  		return ref2Cust;
    },
	valueRef, setValueRef,
	handleChangeDRef: name => e => {
        if(e==null){
            setValueRef({...valueRef, [name]:'' });
        }else{
            setValueRef({...valueRef, [name]:dateFormat(e.target.value, "dd-mm-yyyy")} );
        }
    },
	handleChangeSRef:name=> e => {
  
        if(e==null){setAffList( custData.map(k => k.AffName.trim()).sort()); return;}
    
        let index=null;
        if (name==='CustName'){
           index = custData.findIndex(x => x.CustName.trim() === e.target.innerHTML);
           setArrIndex(index);
           setAffList(custData.filter(x => e.target.innerHTML === x.CustName.trim()).map(y => y.AffName.trim()) );
        }
        
        if (name==='AffName'){
            index = custData.findIndex(x => x.AffName.trim() === e.target.innerHTML);
            setArrIndex(index);
        }
  	
        setValueRef ({...valueRef,  [e.target.name]:e.target.innerHTML, 
                'SDateAff':'', 'EDateAff':'', 'CustNum': custData[index].CustNum,
                    'CustName': custData[index].CustName,
                    'AffName': custData[index].AffName
					 });
		
		setIsSelected(true)
		setvalueEditDrpDown({...valueEditDrpDown, [name]:e.target.innerHTML })
		
	},
	handleChangeCountry:name=>e => {

    	setValue({...value, [name]:e.target.innerHTML }); 
		
		setIsSelected(true)
		setvalueEditDrpDown({...valueEditDrpDown, [name]:e.target.innerHTML })
		
	},
	handleChangeRef:options=> e => { //edit DropDown inputText
	
		setIsEditing({...isEditing, [e.target.name]: true})
		if(e.target.name!=='Bcountry') {
			let result =custData.map(k => k[e.target.name].trim()).filter((val,id,array) => array.indexOf(val) === id) //remove duplicates
			result=result.filter(val => val.includes(e.target.value));
			result.sort();
			result = result.length===0 ? ['אין נתונים']: result 
			setvalueEditDrpDown({...valueEditDrpDown, [e.target.name]:e.target.value })
			
			if(e.target.name==='CustName')setCustDataDrpDown(result)
			if(e.target.name==='AffName')setAftDataDrpDown(result)
		}else{
			
			let result=options.filter(val => val.includes(e.target.value));
			result.sort();
			result = result.length===0 ? ['אין נתונים']: result 
			setvalueEditDrpDown({...valueEditDrpDown, [e.target.name]:e.target.value })
			setCountryDataDrpDown(result)
		}	
		
	},
	AffList, setAffList,
	arrIndex, setArrIndex,
	ttlList, setTtlList,
	kabsList, setKabsList,
	reglist, setReglist,
	handleChangeScust : (options, name) => e => { 
       
        let index=null;
        let tmp=null;
    
	
        if (name==='AffName'){
            index = custData.findIndex(x => x.AffName.trim() === e.target.innerHTML);
            tmp= custData[index];
          
            setValue ({...tmp,  [name]:e.target.innerHTML });
        }
		
		setIsSelected(true)
		setvalueEditDrpDown({...valueEditDrpDown, [name]:e.target.innerHTML })
		
        
        if (name==='RgMngName'){
            tmp= custData[index];
            let tmpNum = reglist[reglist.findIndex(w => w.name===e.value)].num;
            setValue ({...value,  [name]:e.value, 'RgMngNum': tmpNum });
        } 
    },
	clrDate: (name)=>e=>{
		
		if(name==='SDate' || name==='Bday' || name==='EDate' || name==='VValdty' || name==='InsrnceVldty' ){
			setValue({...value, [name]: ''  }); 
		}
		
		if(name==='EDateAff'){
			setValueRef({...valueRef, [name]: ''} );
		}
		
	
	},
	
}
};

export default useRcState;