import React, { useState, useEffect, useRef } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import Header from '../Subcomponents/TableHeader/Header';
import {readDataAll, delDoc, addDoc} from '../../functions/functions.js';
import EmplModalGarbage from './EmplModalGarbage';
import CustModalGarbage from './CustModalGarbage';
import HqModalGarbage from './HqModalGarbage';
import {ProgressSpinner} from 'primereact/progressspinner';
import NavBar from '../Subcomponents/NavBar/NavBar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

let propDefaults = [ 
                {field: 'Number',header: '#', showcol: true, initial:true},
                {field: 'Name', header: 'שם פרטי/סניף', showcol: true, initial:true},
                {field: 'LName', header: 'שם משפחה/לקוח', showcol: true, initial:true},
                {field: 'NName', header: 'כינוי/מנהל אזור', showcol: true, initial:true},
                {field: 'Desc', header: 'תיאור', showcol: true, initial:true},
                {field: 'deltime', header: 'תאריך מחיקה', showcol: true, initial:true}
            ];


const  Garbage =(props) =>{
    
        const [grdt, seGrdt] = useState([]);
		let myRef = useRef();
		const [slct, setSlct] = useState('');
	
        const [cols, setCols] = useState(propDefaults);
        
        const [showFilter, setShowFilter] = useState(false);
        const [showSearch, setShowSearch] = useState(false);
        const [spinner, setSpinner] = useState(false);
        const [displayDialogEmpl, setDisplayDialogEmpl] = useState(false);
		const [displayDialogCust, setDisplayDialogCust] = useState(false);
		const [displayDialogHq, setDisplayDialogHq] = useState(false);
        const [globalFilter,setGlobalFilter] = useState(null);
        const [searchValue, setSearchValue] = useState('');
        const toast = useRef(null);
	
    useEffect(() => {
        console.log('start Garbage');
       
		 const loadData = async() =>{
		 setSpinner(true);
		 
		const grdata = await readDataAll('Garbage');
		let tmpdata = grdata.map(x=>{
			let tt=null;
			if(x.type==='empl'){tt = 'עובד שטח'}
			else if(x.type==='cust'){tt = 'לקוח'}
			else if(x.type==='hq'){tt = 'עובד מטה'};
					
			return {Number: x.Number || x.CustNum|| x.HqNum,
					 Name: x.Name || x.AffName || x.HqName,
					 LName: x.LName || x.CustName || x.HqLName,
					 NName: x.NName || x.RgMngName || '',
					 Desc:tt , type: x.type, data: x,
					 deltime: x.DelTime};
		}); 
		
		seGrdt(tmpdata);
        setSpinner(false);
    };
        loadData();
    }, []);
    
    
 
   
    
    const  dsplSlctVal= e =>{
		let nm = e.data.Number;
		let slctd = grdt.filter(x => nm===x.Number || nm===x.CustNum || nm===x.HqNum)[0]['data'];
        setSlct(slctd);
		if(slctd.type==='empl')setDisplayDialogEmpl(true);
		if(slctd.type==='hq')setDisplayDialogHq(true);
		if(slctd.type==='cust')setDisplayDialogCust(true);
    };
    
	
	const Delete = async(id,num) =>{
	let updtgrdt = grdt.filter(x => x['data'][num]!==id);
	seGrdt(updtgrdt);
	//await apiCalls.delEnt('Garbage', id,'');	
	
		await delDoc('Garbage', id)
	setDisplayDialogEmpl(false);
	setDisplayDialogHq(false);
	setDisplayDialogCust(false);	
	
	toast.current.show({closable: false, severity: 'success', summary: 'מחיקת שורה הסתיימה'});
	};

     const onSearchChange = (event) => { 
        myRef.current.filter(null,'', 'equals');
        setGlobalFilter(event.target.value);
        setSearchValue(event.target.value);
    }; //search 
    
    const showSearchClick=()=>{
        setShowSearch(!showSearch);
        setGlobalFilter(null);
        setSearchValue('');
        setShowFilter(false);
    };
    
    const handleToggleCols= value => {
       	let newArr = [...cols];
        let newObj = {...value, 'showcol': !value.showcol};
        newArr[newArr.map(i => i.field).indexOf(value.field)] = newObj;
        setCols(newArr);
    };
    
    const resetFilter = async() =>{
        console.log('reset');
      	setGlobalFilter(null);
       	setSearchValue('');
       	setShowFilter(false);
       	myRef.current.reset()
	};
    
	const restore=(id, num, type)=>{
		let q=null;
		if(type==='empl' || type==='hq'){q='שים לב: העובד יוחזר לרשימת העובדים!'};
		if(type==='cust' ){q='.שים לב: הלקוח יוחזר לרשימת הלקוחות'};
		
		confirmDialog({
			message: 'האם להמשיך?',
			header: q,
			focusOnShow: false,
			className:'cnfDialaog w-3',
			acceptClassName: 'p-button-text buttonTextFont',
			rejectClassName:'p-button-text buttonTextFont text-red-500',
			acceptLabel: 'כן ',
			rejectLabel: 'לא',
			accept: async() =>{
		
                  	prepareToRestore(grdt.filter(x=> x['data'][num]===id)[0], id);
					let updtgrdt = grdt.filter(x => x['data'][num]!==id);
					seGrdt(updtgrdt);
					
					setDisplayDialogEmpl(false);
					setDisplayDialogHq(false);
					setDisplayDialogCust(false);	
             		toast.current.show({closable: false, severity: 'success', summary: 'אחזור הישות הסתיים'});
                  
               },
			reject: () => {}
        }); 
	};
	
	const prepareToRestore = async(val,id)=>{
		let tmpObj=val.data;
			if(tmpObj.type==='empl'){
				var ref2Cust=[];
				var tmpArr=tmpObj.ref2Cust;
				for (var key in tmpArr) {
					let obj ={
						CustNum: tmpArr[key].CustNum,
						SDateAff: tmpArr[key].SDateAff,
						EDateAff: tmpArr[key].EDateAff,
					//	Notes: tmpArr[key].Notes,
						Rgnmgr: tmpArr[key].Rgnmgr
					}; 
				ref2Cust.push(obj);
				}
				tmpObj.ref2Cust=ref2Cust; 
			}												
		let ent=null;
		if(tmpObj.type==='empl')ent='Empl';
		if(tmpObj.type==='cust')ent='Cust';
		if(tmpObj.type==='hq')ent='Hq';
		
		delete tmpObj.DelTime;
		delete tmpObj.type;
	
		await addDoc(ent, id, tmpObj);
		await delDoc('Garbage', id)
	};
	
	
	
	
	const header = <Header 		showSearch={showSearch}
						   		onChange={onSearchChange}
                                runFltr={()=> setShowFilter(!showFilter)}
                                showFilter={showFilter}
                                searchValue={searchValue}
								showActNotActIcon='false'
                                showSrchInpt={showSearchClick}
                                resetFltr={resetFilter}
                                handleToggleCols={handleToggleCols}
                                cols={cols}
                                data={grdt}
                                tableName='סל מחזור'
					   
					   			expTableCols={cols.filter((col) => col.showcol === true)}
								
                            />;

        let dynamicColumns = cols.filter(col => col.showcol === true).slice(0).reverse().map((col,i) =>{
             return <Column key={col.field} field={col.field} header={col.header} filter={showFilter} sortable={false} 
                  headerStyle={{overflow:'visible'}}  /> ;
        });

        return(
            <div>
                <NavBar shownav={props.shownav}/>
				<ConfirmDialog /> 
                 <div className='container card flex-wrap p-4 surface-card border-round-lg w-7' >
                    {spinner ? <ProgressSpinner className="spinner"/>:null}
                    <Toast  ref={toast} position="top-left" className='pgrowl'></Toast>
                    <DataTable value={grdt}
							ref={myRef}
							paginator={true}
							rows={10}
							rowsPerPageOptions={[10, 25, 50, 100]}
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
							header={header} 
                            globalFilter={globalFilter}
                              
                             //   autoLayout={true}
                            responsiveLayout="scroll"   
							selectionMode="single"
						   // onSelectionChange={e => setSlct(e.value)}
							onRowClick={dsplSlctVal} 
							emptyMessage="אין נתונים"
							>
                    {dynamicColumns}
                    </DataTable>
                </div>
             		 {displayDialogEmpl ? <EmplModalGarbage  
								 	slct={slct}
									//grdt={grdt}
									displayDialogEmpl={displayDialogEmpl}
									onClose={()=>setDisplayDialogEmpl(false)}
									delete={Delete}
									restore={restore}
									/> : null}
                {displayDialogCust ? <CustModalGarbage  
								 	slct={slct}   
									displayDialogCust={displayDialogCust}
									onClose={()=>setDisplayDialogCust(false)}
									delete={Delete}
								 	restore={restore}
									/> : null}
                {displayDialogHq ? <HqModalGarbage  
								 	slct={slct}   
									displayDialogHq={displayDialogHq}
									onClose={()=>setDisplayDialogHq(false)}
									delete={Delete}
								   	restore={restore}
									/> : null}
				
				
			
            </div>
            );
  
};


export default Garbage;

