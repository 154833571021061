import React, { useState } from 'react';
import { Button } from 'primereact/button';
import EmplDetailsGarbage from './EmplDetailsGarbage';
import EmplRefsGarbage from './EmplRefsGarbage';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';


const EmplModalGarbage = (props) => {
	const [selected, setSelected] = useState(
		props.slct.ref2Cust[0] === undefined ? '' : props.slct.ref2Cust[0]
	);
	const [tabind, setTabind] = useState(0);
	
	const CLickRow = (e) => {
		setSelected({
			...selected,
			CustNum: e.CustNum,
			CustName: e.CustName,
			AffName: e.AffName,
			SDateAff: e.SDateAff,
			EDateAff: e.EDateAff,
			RgMngName: e.RgMngName,
		});
	};

	const footer = (
		<div style={{direction: 'rtl'}}>
			<Button
				className="p-button-text buttonTextFont"
				onClick={() => props.restore(props.slct.Number, 'Number', 'empl')}
				color="primary"
				label="אחזר"
			/>
			<Button
				className="p-button-text buttonTextFont"
				onClick={() => props.delete(props.slct.Number, 'Number')}
				color="primary"
				label="מחיקה"
			/>
			<Button
				className="p-button-text buttonTextFont text-red-500"
				onClick={props.onClose}
				color="secondary"
				label="יציאה"
			/>
		</div>
	);

	return (
		<>
			<Dialog
				header="כרטיס עובד"
				visible={props.displayDialogEmpl}
				onHide={props.onClose}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
				<TabView
					activeIndex={tabind}
					onTabChange={(e) => setTabind(e.index)}
					panelContainerClassName="p-0 pt-2"
				>
					<TabPanel
						header="פרטי עובד"
						rightIcon="pi pi-user"
						key="1"
						className="tabPane "
					>
						<EmplDetailsGarbage value={props.slct} />
					</TabPanel>
					<TabPanel
						header="שיוכים"
						rightIcon="pi pi-share-alt"
						key="2"
						className="tabPane"
					>
						<EmplRefsGarbage
							valueRef={props.slct.ref2Cust}
							value={props.slct}
							CLickRow={CLickRow}
							vslct={selected}
						/>
					</TabPanel>
				</TabView>
			</Dialog>
		</>
	);
};

export default EmplModalGarbage;