import React, {useContext } from 'react';

import { DataContext } from '../../contexts/useDataContext';
import { Dropdown } from 'primereact/dropdown';

const DropDownRegs = (props) => {
	const {mngChosen} = useContext(DataContext);
	
	return (
		<div>
			<Dropdown
				value={mngChosen != null && mngChosen}
				onChange={(e) => props.slctMngr(e)}
				options={props.reglist.map((k) => k.name)}
				placeholder="בחר מנהל אזור"
				className={`text-xs w-full border-1 border-solid outline-none focus:border-primary focus:shadow-none inpHght 
								hover:border-solid plcholdr dropDown`}
				dropdownIcon="pi pi-angle-down"
				style={{ direction: 'rtl' }}
			/>
		</div>
	);
};

export default DropDownRegs;
