import React from 'react';
import {Link} from 'react-router-dom';


const SideBar= ({value,img,alt,ttl, url, click}) => (
            <li>
                <Link to={url} onClick={click} >
                    <img src={img} title={ttl} alt={alt}  />
                </Link>
            </li>
            );

export default SideBar;
