import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/useDataContext';
import { Dropdown } from 'primereact/dropdown';

const Slct = (props) => {
	const [divSize, setDivSize] = useState(null);

	const val =
		props.vl === '' || props.vl == null || props.vl.toString() === '-1' ? undefined : props.vl;

	const { handleChangeS, handleChangeSRef, handleChangeScust } = useContext(DataContext);

	const itemTemplate = (option) => {
		return (
			<div>
				<div className="text-sm itemDropDown text-right" style={{ direction: 'rtl' }}>
					{option}
				</div>
			</div>
		);
	};

	useEffect(() => {
		var clientWidth = document.getElementById(props.inpt);
		if (clientWidth !== null) {
			setDivSize({ ...divSize, [props.inpt]: clientWidth.clientWidth + 3 });
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="w-full">
			<div className="flex justify-content-around align-content-center">
				<div className="col-6 p-1">
					<label className="float-right text-sm">{props.lbl}</label>
				</div>
				<div className="col-6 p-1">
					<Dropdown
						value={val != null && val !== undefined ? val : undefined}
						options={props.option}
						onChange={
							props.rf
								? handleChangeSRef(props.option, props.inpt)
								: props.slct
								? handleChangeScust(props.option, props.inpt)
								: handleChangeS
						}
						placeholder={props.lbl}
						className={`text-xs w-full border-1 border-solid outline-none focus:border-primary focus:shadow-none inpHght hover:border-solid plcholdr
						${props.vl === '' && props.r ? 'redBorder' : 'inptBorder'} dropDown`}
						dropdownIcon="pi pi-angle-down"
						itemTemplate={itemTemplate}
						panelStyle={{
							width: divSize === null ? '100px' : divSize[props.inpt],
						}}
						name={props.inpt}
					/>
				</div>
			</div>
		</div>
	);
};

export default Slct;