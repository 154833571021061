import React, { useState, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import HqDetails from './HqDetails';
import { DataContext } from '../../../contexts/useDataContext';
import {
	updateDocument, delDoc,
	addDoc,
	getData,
	movetoGarbage, updtRegName, deleteRegFromRefandCust,
} from '../../../functions/functions.js';
//import './EmplModal.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { AuthContext } from '../../../contexts/useAuthContext';

const mustFillValue = ['HqNum', 'Ttl', 'HqName', 'HqLName', 'HqId', 'SDate', 'HqTel'];

const HqModal = (props) => {
	const {
		value,
		setValue,
		hqData,
		setHqData,
		custData,
		setCustData,
		displayDialog,
		setDisplayDialog,
		setDateBack,
	} = useContext(DataContext);

	const [cl, setCl] = useState(false);
	const [snifim, setSnifim] = useState(false);
	const { write } = useContext(AuthContext);
	const toast = useRef(null);

	

	/**********************************/

	const handleSubmit = async () => {
		
		setCl(true);
		for (let key in mustFillValue) {
			if (
				value[mustFillValue[key]] === '' ||
				value[mustFillValue[key]] === null ||
				value[mustFillValue[key]] === undefined
			)
				return;
		}

		let tmpALL = hqData; //active and not active

		if (!props.isnotActLoaded) {
			//טעינה של לא פעלים פעם ראשונה ובדיקה
			let dataOfNotActive = await getData('Hq', false);
			props.setIsnotActLoaded(true); //טעינה של לא פעילים
			tmpALL = [...hqData, ...dataOfNotActive].sort((a, b) =>
				a.HqNum > b.HqNum ? 1 : b.HqNum > a.HqNum ? -1 : 0
			);
		}

		if (tmpALL.map((x) => x.HqNum).includes(value.HqNum)) {
			alert('מספר עובד כבר קיים');
			return;
		}

		let valTmp = { ...value, ActStts: true };
		setValue(valTmp);
		let newArr = [...tmpALL, valTmp];
		setHqData(newArr);

		let Addd = await addDoc('Hq', valTmp.HqNum, valTmp);
		Addd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'הוספת העובד הסתיימה',
			});
			
	};
	
	
	const handleUpdate = async () => {
				
		setCl(false);
		if (value.HqNum == null || value.HqNum === '') {
			alert('אין נתונים מתאימים');
			return;
		}
		if (setDateBack(value.SDate) > setDateBack(value.EDate) && value.EDate !== '') {
			alert('שגיאה בתאריכי הזנה!');
			return;
		}
		if (value.EDate !== '' && !value.ActStts) {
			alert('העובד אינו פעיל!');
			return;
		}

		//מה קורה כשמשנים את השם ושם משפחה, משפיע על הלקוחות
		const tableValue = hqData.filter((x) => x.HqNum === value.HqNum)[0];
		let isChanged = tableValue.HqName !== value.HqName || tableValue.HqLName !== value.HqLName;
		if (isChanged) {
			await updtRegName(value.HqNum, `${value.HqName} ${value.HqLName}`);
			let tmpCustData = [...custData];
			tmpCustData = tmpCustData.map((x) =>
				x.RgMngNum === value.HqNum
					? { ...x, RgMngName: value.HqName.concat(' ').concat(value.HqLName) }
					: x
			);
			setCustData(tmpCustData);
		}

		let tmp = value.EDate !== '' ? false : true;
		let valTmp = { ...value, ActStts: tmp };
		setValue(valTmp);

		let newArr = hqData.map((x) => (x.HqNum === valTmp.HqNum ? valTmp : x));
		setHqData(newArr);

		let Upd = await updateDocument('Hq', valTmp.HqNum, valTmp);
		Upd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'עדכון העובד הסתיים',
			});
	};

	const handleDelete = (e) => {
		if (value.HqNum === '') {
			alert('אין נתונים למחיקה');
			return;
		}
		confirmDialog({
			message: 'שים לב: העובד ימחק מהרישומים!',
			header: 'האם למחוק?',
			focusOnShow: false,
			className:'cnfDialaog',
			acceptClassName: 'p-button-text buttonTextFont',
			rejectClassName:'p-button-text buttonTextFont text-red-500',
			acceptLabel: 'כן ',
			rejectLabel: 'לא',
			accept: async() =>{
						const del=async()=>{
						let newArr = hqData.filter(x=> x.HqNum!==value.HqNum)
		 				setHqData(newArr)
						let Dld = await delDoc('Hq', value.HqNum);
                   		Dld && toast.current.show({closable: false, severity: 'success', summary: 'מחיקת העובד הסתיימה'});
                   		toGarbage();
					}
						del();
						await deleteRegFromRefandCust(value.HqNum);
						reset();
						},
			reject: () => {}
    	});
	
	};

	/**********************************/

	const toGarbage = async () => {
		let tmpObj = value;
		tmpObj = { ...tmpObj, type: 'hq', DelTime: Date().substring(4, 21) };
		await movetoGarbage(tmpObj, tmpObj.HqNum);
	};

	const reset = (e) => {
		setValue(props.obj);
		setCl(false);
	};

	/**********************************/

	const showsnifim = () => {
		setSnifim(!snifim);
	};

	const footer = (
		<div style={{ direction: 'rtl' }}>
			{write && (
				<Button className="p-button-text buttonTextFont" type="submit" onClick={handleSubmit} label="הוספה" color="primary" />
				
			)}
			{write && (
				<Button className="p-button-text buttonTextFont" onClick={handleUpdate} color="primary" label="עדכן" />

			)}
			{write && (
				<Button className="p-button-text buttonTextFont" onClick={handleDelete} color="primary" label="מחיקה" />
					
			)}
			<Button className="p-button-text buttonTextFont" onClick={reset} color="primary" 	label="נקה" />
			
			{write && value.Ttl === 'מנהל אזור' && (
				<Button className="p-button-text buttonTextFont" onClick={showsnifim} color="primary" 	label="העברת סניפים" />
			)}
			<Button className="p-button-text buttonTextFont text-red-500" onClick={() => setDisplayDialog(false)} color="secondary" 	label="יציאה" />
		
		</div>
	);

	return (
		<>
		
			<Toast ref={toast} position="top-left" className="pgrowl"></Toast>
			<ConfirmDialog /> 
			<Dialog
				header="כרטיס עובד הנהלה"
				visible={displayDialog}
				onHide={() => setDisplayDialog(false)}
				footer={footer}
				className="Modal w-5"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
					<HqDetails 	cl={cl} snifim={snifim}	toast={toast} /> 
			</Dialog>
			
		</>
	);
};

export default HqModal;

/*


 

             
                
               */