import React, {createContext} from 'react';
import useLists from '../hooks/useLists';

export const ListsContext = createContext();

const ListsProvider = (props) => {
		const listsActions = useLists();
	return(
		<ListsContext.Provider value={listsActions}>
			{props.children}
		</ListsContext.Provider>
	);
}

export default ListsProvider;
