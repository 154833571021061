import { Button } from 'primereact/button';
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import * as XLSX from "xlsx";//from 'https://unpkg.com/xlsx/xlsx.mjs';


const ExportData = ({expTableCols, data, tableName}) => {

	const exportExcel = () => {
		
      handleExport().then((url) => {
		  const downloadAnchorNode = document.createElement("a");
		  downloadAnchorNode.setAttribute("href", url);
		  downloadAnchorNode.setAttribute("download", tableName + '.xlsx');
		  downloadAnchorNode.click();
		  downloadAnchorNode.remove();
    });

  };
	 
	
 const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
		 
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

	
  const s2ab = (s) => {
 
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

	
  const handleExport = () => {

    let wb = XLSX.utils.book_new();
	wb = { Workbook: { Views: [{ RTL: true }] }, Sheets: {}, SheetNames: [] }
		  
	let widthCols=expTableCols.map(x=> ({wch: 10}))
	
	const rows = data.map(row => {
		let newObj = {}

		for (let [k, v] of Object.entries(expTableCols)) {
		  newObj[v.header] = row[v.field]!==undefined ? row[v.field]: '';
			
		let w = newObj[v.header].length > widthCols[k].wch ? newObj[v.header].length: widthCols[k].wch
		widthCols[k].wch=w;
		}
		return newObj
	});
		
			
    const worksheet = XLSX.utils.json_to_sheet(rows, {header: expTableCols.map(x=> x.header)});
	worksheet["!cols"] = widthCols;
	  
    XLSX.utils.book_append_sheet(wb, worksheet, "נתונים");
    const workbookBlob = workbook2blob(wb);

    return addStyle(workbookBlob);
  };

	
	
  const addStyle = (workbookBlob) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
			sheet.usedRange().style({
				verticalAlignment: "center",
				 horizontalAlignment: "center",
			});

	sheet.range("A1:ZZ1").style({ bold: true});
		 
      });

      return workbook.outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
	  
	   
	   
	return (
		<div>
			<Button
				icon="pi pi-cloud-download"
				className="p-button-rounded p-button-text p-button-plain buttonCustom outline-none"
				onClick={()=>exportExcel()}
				style={{ color: 'dimgray' }}
				tooltip="אקסל"
				tooltipOptions={{ position: 'bottom' }}
			/>
		</div>
	);
};

export default ExportData;

