import React, { useState, useEffect, useContext, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import EmplDetails from '../Modal/EmplDetails';
import EmplRefs from '../Modal/EmplRefs';
import { DataContext } from '../../../contexts/useDataContext';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { } from '../EmplFuncHelpers';

import {
	updateDocument,
	delDoc,
	addDoc,
	getData,
	getDataFromHis,
	movetoHistory,
	movetoGarbage,
} from '../../../functions/functions.js';
import './EmplModal.css';

import { AuthContext } from '../../../contexts/useAuthContext';

const mustFillValue = [
	'Number',
	'NewNum',
	'Name',
	'LName',
	'NName',
	'Tel',
	'Status',
	'Mstnn',
	'Gndr',
	'T101',
	'Kablan',
	'Bcountry',
	'SDate',
	'Bday',
];
const mustFillValueRef = ['SDateAff', 'AffName'];

const slctEmplRef = {
	CustNum: '',
	CustName: '',
	AffName: '',
	SDateAff: '',
	EDateAff: '',
	Notes: '',
	Rgnmgr: '',
};

const EmplModal = (props) => {
	const {
		value,
		setValue,
		emplData,
		setEmplData,
		custData,
		displayDialog,
		setDisplayDialog,
		setArrIndex,
		valueRef,
		setValueRef,setDateBack
	} = useContext(DataContext);
	
	const [dataRef, setDataRef] = useState([]);

	const [tabind, setTabind] = useState(0);
	const [loadSpin, setLoadSpin] = useState(false);
	const [radSelect, setRadSelect] = useState(1);
	const [cl, setCl] = useState(false);
	const { write } = useContext(AuthContext);
	const toast = useRef(null);
	/*******************************************************************/

	useEffect(() => {
		const findVal = (cust, x) => {
			let moshe = custData.filter((k) => k.CustNum === cust)[0];
			return moshe === undefined ? '' : moshe[x];
		};

		let dataRef = [];
		if (value.ActStts === true) {
			for (let key in value.ref2Cust) {
				dataRef.push({
					key: key,
					CustNum: value.ref2Cust[key].CustNum,
					CustName: findVal(value.ref2Cust[key].CustNum, 'CustName'),
					AffName: findVal(value.ref2Cust[key].CustNum, 'AffName'),
					SDateAff: value.ref2Cust[key].SDateAff,
					EDateAff: value.ref2Cust[key].EDateAff,
					Rgnmgr: value.ref2Cust[key].Rgnmgr,
				});
			}
		}

		setValueRef(dataRef[0] || slctEmplRef);
		dataRef.length
			? setArrIndex(custData.findIndex((x) => x.CustNum === dataRef[0].CustNum))
			: setArrIndex(undefined);
		setDataRef(dataRef);
	}, [custData, setValueRef, value, setArrIndex]); // Pass empty array to only run once on mount.

	const findVal = (cust, x) => {
		let moshe = custData.filter((k) => k.CustNum === cust)[0];
		return moshe === undefined ? '' : moshe[x];
	};

	const refTablePrepape = (dt) => {
		let newDt = [];
		for (let key in dt) {
			newDt.push({
				key: key,
				CustNum: dt[key].CustNum,
				CustName: findVal(dt[key].CustNum, 'CustName'),
				AffName: findVal(dt[key].CustNum, 'AffName'),
				SDateAff: dt[key].SDateAff,
				EDateAff: dt[key].EDateAff,
				Rgnmgr: dt[key].Rgnmgr,
			});
		}
		return newDt;
	};

	const refTableShrtPrepare = (dt) => {
		let newRef2Cust = [];

		for (let x in dt) {
			newRef2Cust.push({
				CustNum: dt[x].CustNum,
				SDateAff: dt[x].SDateAff,
				EDateAff: dt[x].EDateAff,
				Rgnmgr: dt[x].Rgnmgr,
			});
		}
		return newRef2Cust;
	};

	const reset = (e) => {
		setValue(props.obj);
		setValueRef(slctEmplRef);
		setArrIndex(undefined);
		setDataRef([]);
		setCl(false);
		//  setAffList(props.arrCust.map(k => k.AffName).sort());
	};

	/**********************************/
	const handleSubmit = (e) => {
		tabind === 0 ? addNewEmpl() : addNewRef();
	};
	const handleUpdate = (e) => {
		tabind === 0 ? updateEmpl() : udpateRef();
	};
	const handleDelete = (e) => {
		if (tabind === 0) delEmpl();
	};

	/**********************************/

	const addNewEmpl = async () => {
		setCl(true);

		 for (let key in mustFillValue) {
		 	if (
		 		value[mustFillValue[key]] === '' ||
		 		value[mustFillValue[key]] === null ||
		 		value[mustFillValue[key]] === undefined
		 	)
		 		return;
		 }

		if (value.Visa === '' && value.Id === '') return;
		if (value.Visa !== '' && value.VValdty === null) return;

		let tmpALL = emplData; //active and not active

		if (!props.isnotActLoaded) {
			//טעינה של לא פעלים פעם ראשונה ובדיקה
			let dataOfNotActive =  await getData('Empl', false);
			props.setIsnotActLoaded(true); //טעינה של לא פעילים
			tmpALL = [...emplData, ...dataOfNotActive].sort((a, b) =>
				a.Number > b.Number ? 1 : b.Number > a.Number ? -1 : 0
			);
			setEmplData(tmpALL);
		}

		if (tmpALL.map((x) => x.Number).includes(value.Number)) {
			alert('מספר עובד כבר קיים');
			return;
		}

		let valTmp = { ...value, ActStts: false, ref2Cust: [] };

		setValue(valTmp)
		let newArr = [...tmpALL, valTmp];
		setEmplData(newArr);
		
		let Addd = await addDoc('Empl', valTmp.Number, valTmp);
	
		Addd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'הוספת העובד הסתיימה',
			});
	};

	const updateEmpl = async () => {
	
		setCl(false);
		if (value.Number == null || value.Number === '') {
			alert('אין נתונים מתאימים');
			return;
		}
		if (value.SDate > value.EDate && value.EDate !== '') {
			alert('שגיאה בתאריכי הזנה!');
			return;
		}
		if (value.EDate !== '' && !value.ActStts) {
			alert('העובד אינו פעיל!');
			return;
		}

		let valTmp = { ...value };

		if (value.EDate !== '') {
			//סיום פעילות
			valTmp = { ...value, ActStts: false, ref2Cust: [] };
			setDataRef(dataRef.map((o) => ({ ...o, EDateAff: value.EDate }))); //לעדכן טבלה עם תאריכי גמר
			toHis(dataRef.map((o) => ({ ...o, EDateAff: value.EDate }))); //הכנה להעברת להיסטוריה
		}
		let newArr = emplData.map((x) => (x.Number === valTmp.Number ? valTmp : x));
		setEmplData(newArr);
		setValueRef(slctEmplRef);
		setValue(valTmp);

		let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
		Upd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'עדכון העובד הסתיים',
			});
	};

	const delEmpl = () => {
		if (value.Number === '') {
			alert('אין נתונים למחיקה');
			return;
		}

	  confirmDialog({
        message: 'שים לב: העובד ימחק מהרישומים!',
        header: 'האם למחוק?',
		focusOnShow: false,
		className:'cnfDialaog',
		acceptClassName: 'p-button-text buttonTextFont',
		rejectClassName:'p-button-text buttonTextFont text-red-500',
		acceptLabel: 'כן ',
		rejectLabel: 'לא',
        accept: async() =>{
						let newArr = emplData.filter(x=> x.Number!==value.Number)
		 				setEmplData(newArr)
						let Dld = await delDoc('Empl', value.Number);
                   		Dld && toast.current.show({closable: false, severity: 'success', summary: 'מחיקת העובד הסתיימה'});
                   		toGarbage();
						setValue(props.obj);
					},
        reject: () => {}
    	});
	};

	const toHis = async (dt) => {
		for (let key in dt) {
			delete dt[key].key;
			let obj = Object.assign(
				{ ...dt[key] },
				{ Number: value.Number },
				{ Name: value.Name },
				{ LName: value.LName },
				{ NName: value.NName },
				{ RgMngName: findVal(dt[key].CustNum, 'RgMngName') },
				{ SDate: value.SDate },
				{ EDate: value.EDate }
			);
			await movetoHistory(obj);
		}
	};

	const toGarbage = async () => {
		let tmpObj = value;
		let ref2Cust = [];
		let tmpArr = dataRef.filter((k) => k.EDateAff === '');
		for (let key in tmpArr) {
			delete tmpArr[key].key;
			let obj = Object.assign(
				{ ...tmpArr[key] },
				{ Notes: /*tmpArr[key].Notes*/ '' },
				{ RgMngName: findVal(tmpArr[key].CustNum, 'RgMngName') },
				{ CustStreet: findVal(tmpArr[key].CustNum, 'Strt') },
				{ CustCity: findVal(tmpArr[key].CustNum, 'Cty') }
			);
			ref2Cust.push(obj);
		}
		tmpObj = { ...tmpObj, type: 'empl', DelTime: Date().substring(4, 21), ref2Cust: ref2Cust };
		await movetoGarbage(tmpObj, tmpObj.Number);
	};

	/**********************************/

	const addNewRef = async () => {
		setCl(true);
		for (let key in mustFillValueRef) {
			if (
				valueRef[mustFillValueRef[key]] === '' ||
				valueRef[mustFillValueRef[key]] === null ||
				valueRef[mustFillValueRef[key]] === undefined
			)
				return;
		}
		setCl(false);

		if (value.Number === '') {
			alert('אין נתונים');
			return;
		}

		if (value.EDate !== '' && dataRef.length < 1) { 
			alert('העובד אינו פעיל. נא להגדיר תקופת עבודה חדשה!');
			return;
		}
		if (radSelect === 2) {
			alert('לא ניתן להוסיף שיוכים, נא להעביר לשיוכים פעילים!');
			return;
		}
		if (setDateBack(value.SDate) > setDateBack(valueRef.SDateAff)) {
			alert('תאריך תחילת עבודה בסניף אינו יכול להיות מוקדם יותר מתחילת עבודה בחברה!');
			return;
		}

		if (dataRef != null)
			if (dataRef.map((x) => x.CustNum).includes(valueRef.CustNum)) {
				alert('שיוך כבר קיים');
				return;
			}

		const newData = {
			key: valueRef.CustNum,
			CustNum: valueRef.CustNum,
			CustName: valueRef.CustName,
			AffName: valueRef.AffName,
			SDateAff: valueRef.SDateAff,  
			EDateAff: valueRef.EDateAff,
			Rgnmgr: findVal(valueRef.CustNum, 'RgMngNum'),
		};

		let newArray = [...dataRef, newData];
		setDataRef(newArray); //update table

		newArray = refTableShrtPrepare(newArray); //set short ref data for server (wihtout key and etc..)

		let valTmp = !value.ActStts
			? { ...value, ActStts: true, ref2Cust: newArray }
			: { ...value, ref2Cust: newArray };
		setEmplData(emplData.map((x) => (x.Number === valTmp.Number ? valTmp : x)));
		setValue(valTmp);
		
		let Upd = await updateDocument('Empl', valTmp.Number, valTmp);

		Upd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'הוספת השיוך הסתיימה',
			});
	};

	const udpateRef = async () => {
		setCl(false);
		if (!value.ActStts) {
			alert('העובד אינו פעיל!');
			return;
		}
		if (dataRef == null || !dataRef.map((x) => x.CustNum).includes(valueRef.CustNum)) {
			alert('אין שיוך מתאים');
			return;
		}
		if (radSelect === 2) {
			alert('לא ניתן לעדכן שיוך היסטורי');
			return;
		}
		if (setDateBack(valueRef.SDateAff) > setDateBack(valueRef.EDateAff) &&
			valueRef.EDateAff !== ''
		) {
			alert('תאריך סיום עבודה בסניף לפני תחילת עבודה בסניף!');
			return;
		}
		
		if (setDateBack(value.SDate)> setDateBack(valueRef.SDateAff)) {
			alert('תאריך תחילת עבודה בסניף אינו יכול להיות מוקדם יותר מתחילת עבודה בחברה!');
			return;
		}

		//first update small table
		let newArray = [...dataRef];
		newArray[dataRef.findIndex((x) => x.CustNum === valueRef.CustNum)].SDateAff =
			valueRef.SDateAff;
		newArray[dataRef.findIndex((x) => x.CustNum === valueRef.CustNum)].EDateAff =
			valueRef.EDateAff;
		setDataRef(newArray); //update small table

		newArray = refTableShrtPrepare(newArray); //set short ref data for server (wihtout key and etc..)

		let valTmp = { ...value, ref2Cust: newArray.filter((x) => x.EDateAff === '') }; //update value

		//finisehd working (end date)  move to history
		if (valueRef.EDateAff !== '') {
			toHis([valueRef]); //הכנה להעברת להיסטוריה

			//finisehd working (end date) and there are no more refs
			if (valueRef.EDateAff !== '' && dataRef.filter((k) => k.EDateAff === '').length === 0) {
				valTmp = { ...value, ActStts: false, EDate: valueRef.EDateAff, ref2Cust: [] };
			}
		}

		setValue(valTmp);
		let newArr = emplData.map((x) => (x.Number === valTmp.Number ? valTmp : x));
		setEmplData(newArr);

		let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
		Upd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'עדכון השיוך הסתיים',
			});
	};


	const delRefDialog = (event) => {
		if (radSelect === 2) {
			alert('לא ניתן למחוק שיוך היסטורי!');
			return;
		}
		const custNumTmp = dataRef[event.index].CustNum;
		
		confirmDialog({
			message: 'האם להמשיך?',
			header: 'השיוך ימחק!',
			focusOnShow: false,
			className:'cnfDialaog',
			acceptClassName: 'p-button-text buttonTextFont',
			rejectClassName:'p-button-text buttonTextFont text-red-500',
			acceptLabel: 'כן ',
			rejectLabel: 'לא',
			accept: async() =>{
						let tmpArr = dataRef.filter(k => k.CustNum!==custNumTmp && k.EDateAff==='');
						setDataRef(tmpArr);
						
						tmpArr = refTableShrtPrepare(tmpArr); //set short ref data for server (wihtout key and etc..)
						let valTmp;
						
						if(tmpArr.length===0){
							valTmp={...value, 'ActStts':false, 'ref2Cust' : []};
							setValueRef(slctEmplRef);
						}else{
							valTmp={...value, 'ref2Cust' : tmpArr};
							setValue(valTmp);
						}
						
						
						let newArr = emplData.map(x=> x.Number===valTmp.Number? valTmp: x)
						setEmplData(newArr)

						let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
						Upd && toast.current.show({closable: false, severity: 'success', summary: 'עדכון השיוך הסתיים'}); 
					},
			reject: () => {}
    	});
		
	};

	/**********************************/

	const footer = (
		<div style={{ direction: 'rtl' }}>
			{write && (
				<Button className="p-button-text buttonTextFont" type="submit" onClick={handleSubmit} label="הוספה" color="primary"/>
			)}
			{write && (
				<Button className="p-button-text buttonTextFont" onClick={handleUpdate} color="primary" label='עדכן' />
			)}
			{write && tabind !== 1 && (
				<Button className="p-button-text buttonTextFont" onClick={handleDelete} color="primary" label='מחיקה' />
			)}
			<Button className="p-button-text buttonTextFont" onClick={reset} label="נקה" color="primary"/>

			<Button
				className="p-button-text buttonTextFont text-red-500"
				onClick={() => setDisplayDialog(false)}
				label="יציאה"
			/>
		</div>
	);

	const changeRad = async (e) => {
		setRadSelect(e.target.value);
		if (e.target.value === 1) {
			setDataRef(refTablePrepape(value.ref2Cust));
		} else {
			if (value.Number === '' || value.Number == null) {
				alert('אין מספר עובד');
				return;
			}
			setLoadSpin(true);
			let lastHisttoryData = await getDataFromHis(value.Number);
			setDataRef(lastHisttoryData);
			setLoadSpin(false);
		}
	};

	return (
		<>
			<Toast ref={toast} position="top-left" className="pgrowl"></Toast>
			
			<ConfirmDialog /> 
			<Dialog
				header="כרטיס עובד"
				visible={displayDialog}
				onHide={() => setDisplayDialog(false)}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
				<TabView activeIndex={tabind} onTabChange={(e) => setTabind(e.index)} panelContainerClassName="p-0 pt-2">
					<TabPanel
						header="פרטי עובד"
						rightIcon="pi pi-user"
						key="1"
						className="tabPane "
					>
						<EmplDetails cl={cl} />
					</TabPanel>
					<TabPanel
						header="שיוכים"
						rightIcon="pi pi-share-alt"
						key="2"
						className="tabPane"
					>
						<EmplRefs
							delRefDialog={delRefDialog}
							loadSpin={loadSpin}
							dataRef={dataRef}
							changeRad={changeRad}
							radSelect={radSelect}
							cl={cl}
						/>
					</TabPanel>
				</TabView>
			</Dialog>
		</>
	);
};

export default EmplModal;