import React, { useContext } from 'react';
import { ListsContext } from '../../contexts/listsContext';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';

import './Kablanim.css';

const Form = () => {
	const { addItem, value, handleChange, entity } = useContext(ListsContext);

	return (
		<Card style={{ margin: '1rem 0', padding: '0 1rem' }} className="cardForm">
			<form
				onSubmit={(e) => {
					e.preventDefault();
					if(value!==''){
						addItem(entity, value);
					}else{
						return;
					}
				}}
			>
				<span className="p-float-label">
					<InputText
						id="username"
						value={value}
						onChange={handleChange}
						className="p-inputtext-md divInputTextAdd"
					/>
					<label htmlFor="username" className="addItemLabel">
						{entity === 'Titles' ? 'הכנס תפקיד' : 'הכנס קבלן'}
					</label>
				</span>
			</form>
		</Card>
	);
};

export default Form;