import React from 'react';
import TableFilter from './TableFilter';
import ColFilter from './ColumnsFilter';
import ExportData from './exportToExcel';
import SearchData from './SearchData';
import ShowNotActive from './showNotActive';

import './headercss.css';

const Header = (props) => {
	const {
		cols,
		resetFltr,
		onChange,
		searchValue,
		showSrchInpt,
		showSearch,
		showActive,
		showYNAct,
		handleToggleCols,
		expTableCols,
		data
	} = props;
	const InptText = (
		<input
			type="search"
			placeholder="חיפוש"
			className="outline-none focus:border-primary text-base p-2 border-1 border-solid surface-border border-round"
			value={searchValue}
			onChange={onChange}
		/>
	);
	
	const resetFilter = (props.tableName!=='דוח תוקף ויזות ל-7 ימים הבאים' &&
						props.tableName!=='דוח תוקף ביטוח ל-7 ימים הבאים' &&
						props.tableName!=='דוח עדכון שבועי לסוכנים' &&
						props.tableName!=='דוח חוסר 101' &&
						props.tableName!=="דוח ביטוח" &&
						props.tableName!=="דוח הסכמי לקוח"
						) ? true: false
	
	
	
	const showCheckBox = (props.tableName!=='טבלת שיוכים' && props.tableName!=='סל מחזור' && props.tableName!=='תיעוד שיוכים' && resetFilter) ? true: false
	
	
	
	return (
		<div className="justify-content-between flex">
			<div className="flex align-items-center justify-content-center">
				{showCheckBox && <ShowNotActive  showActive={showActive} showYNAct={showYNAct} /> }
				{resetFilter && <TableFilter  resetFltr={resetFltr} /> }
				<ColFilter
					cols={cols.filter((x) => x.field !== 'ActStts')}
					handleToggleCols={handleToggleCols}
				/>
				<ExportData				
					expTableCols={expTableCols} data={data} tableName = {props.tableName}
				/>
				<SearchData showSrchInpt={showSrchInpt} />
			</div>
			<div className="flex align-items-center justify-content-center">
				{!showSearch ? <div className="text-2xl">{props.tableName}</div> : null}
				{showSearch ? InptText : null}
			</div>
		</div>
	);
};

export default Header;

/*
 
              
                

*/