import React from 'react';
import { Button } from 'primereact/button';
import CustDetailsGarbage from './CustDetailsGarbage';
import { Dialog } from 'primereact/dialog';

const CustModal = (props) => {
	const footer = (
		<div style={{direction: 'rtl'}} >
			<Button
				className="p-button-text buttonTextFont"
				onClick={() => props.restore(props.slct.CustNum, 'CustNum', 'cust')}
				color="primary"
				label="אחזר"
			/>
			<Button
				className="p-button-text buttonTextFont"
				onClick={() => props.delete(props.slct.CustNum, 'CustNum')}
				color="primary"
				label="מחיקה"
			/>
			<Button
				className="p-button-text buttonTextFont text-red-500"
				onClick={props.onClose}
				color="secondary"
				label="יציאה"
			/>
		</div>
	);

	return (
		
			<Dialog
				header="כרטיס לקוח סניף"
				visible={props.displayDialogCust}
				onHide={props.onClose}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
				<CustDetailsGarbage value={props.slct} />
		</Dialog>
		
	);
};

export default CustModal;