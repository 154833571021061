import React, {useContext} from 'react';
import { Menubar } from 'primereact/menubar';
import {LogoutFromSystem} from '../../../functions/functions.js';
import {DataContext} from '../../../contexts/useDataContext';
import { useNavigate } from 'react-router-dom';
import './NavBar.css';
import {AuthContext} from '../../../contexts/useAuthContext';

const NavBar = (props) => {
	
	const {setCustData, setEmplData, setHqData, setRefsData} = useContext(DataContext);
	let navigate = useNavigate();
	const {admn} = useContext(AuthContext);	
	
	const exitSystem=()=>{
	
		
		setEmplData([])
		setCustData([]);
		setHqData([]);
		setRefsData([]);
	
		LogoutFromSystem();
		navigate('/login');
	}
	
const itemsLogOut = [{
			label: 'יציאה',
		  	command: () => {
                exitSystem();
            }}
	]
	
const itemsMain = [
	admn && {
		label: 'הגדרות',
        items: [
                {
                    label: 'הרשאות',
					command: () => { navigate('/auth'); }
                },
                {
                    label: 'קבלנים',
					command: () => { navigate('/kablanim'); }
                },
                {
                    label: 'תפקידים',
					command: () => { navigate('/titles'); }
                },
                {
                    label: 'סל מחזור',
					command: () => { navigate('/garbage'); }

                },
				],
	},
	{
		label: 'דוחות',
        items: [
					{
						label: 'תוקף ויזות', 
						command: () => { navigate('/vvalidity'); }
					},
					{
						label: 'תוקף ביטוח',
						command: () => { navigate('/insvalidity'); }
					},
					{
						label: 'עדכון שבועי לסוכנים',
						command: () => { navigate('/regsreport'); }			
					},
					{
						label: 'דוח 101',
						command: () => { navigate('/missing101'); }	
					},
					{
						label: 'דוח ביטוח',
						command: () => { navigate('/increport'); }	
					},
					{
						label: 'דוח הסכמי ביטוח', 
						command: () => { navigate('/custagrmntreport'); }	
					},
				]
	},
	{
		label: 'תיעוד',
		command: () => { navigate('/history'); }	
	},
	{
		label: 'יציאה',
		  	command: () => {
                exitSystem();
			}
	}

]

const Rashi = [
		{
			label: 'ראשי',
		  	command: () => { navigate('/main'); }
		}
	]

const RashiTitles = [
		{
			label: 'ראשי',
		  	command: () => { navigate('/main'); }
		},
		{
			label: 'עובדי הנהלה',
			command: () => { navigate('/Hq'); }
		}
	]
	
    return (
        <div style={{direction: 'rtl'}} >
          
                <Menubar model={
					props.shownav==='logout' ? itemsLogOut :
					props.shownav==='main' ? itemsMain : 
					props.shownav==='kablanim' || props.shownav==='rashi'? Rashi : 
					props.shownav==='titles' ? RashiTitles :null
					} className='menuBar shadow-3 border-noround border-none'	/>
          
        </div>
    );
}


export default NavBar;


/*import React, {useContext} from 'react';
import './NavBar.css';
//import logo from '../../../logos/logo.png';

import {Link} from 'react-router-dom';
import {AuthContext} from '../../../contexts/useAuthContext';
import {LogoutFromSystem} from '../../../functions/functions.js';
import {DataContext} from '../../../contexts/useDataContext';

const NavBar = (props) => {
	const {admn} = useContext(AuthContext);	
	const {setCustData, setEmplData, setHqData, setRefsData} = useContext(DataContext);
	
	const exitSystem=()=>{
		setEmplData([])
		setCustData([]);
		setHqData([]);
		setRefsData([]);
	
		LogoutFromSystem()
	}
	
	let logOut = <li className="nav-item">
                        <Link  className="nav-link" to='/login' onClick={exitSystem}>יציאה </Link>
				</li>
	let MainPage = 	<ul className="navbar-nav">
						{admn &&
						<li className="nav-item dropdown_settings">
							<Link className="nav-link" to='/main' >הגדרות </Link>
								<div className="dropdown-content">
										<Link  to='/auth' className="nav-link" style={{width:'100%', padding: '7px'}}>הרשאות</Link>
										<Link to='/kablanim' className="nav-link" style={{width:'100%', padding: '7px'}}>קבלנים</Link>
										<Link to='/titles' className="nav-link" style={{width:'100%', padding: '7px'}}>תפקידים</Link>
										<Link to='/garbage' className="nav-link" style={{width:'100%', padding: '7px'}}>סל מחזור</Link>		  
								</div>
						</li> }
						<li className="nav-item dropdown_settings">
							<Link className="nav-link" to='/main' >דוחות </Link>
								<div className="dropdown-content">
										<Link  to='/vvalidity' className="nav-link" style={{width:'100%', padding: '7px'}}>תוקף ויזות</Link>
										<Link to='/insvalidity' className="nav-link" style={{width:'100%', padding: '7px'}}>תוקף ביטוח</Link>
										<Link to='/regsreport' className="nav-link" style={{width:'100%', padding: '7px'}}>עדכון שבועי לסוכנים</Link>
										<Link to='/missing101' className="nav-link" style={{width:'100%', padding: '7px'}}>דוח 101</Link>
										<Link to='/increport' className="nav-link" style={{width:'100%', padding: '7px'}}>דוח ביטוח</Link>
										<Link to='/custagrmntreport' className="nav-link" style={{width:'100%', padding: '7px'}}>דוח הסכמי לקוחות</Link>	
								</div>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to='/history'>תיעוד </Link>
						</li> 
						
						{logOut}
                	</ul>
	let Rashi = <li className="nav-item">
                        <Link  className="nav-link" to='/main'>ראשי </Link>
				</li>
	let Lists = <ul className="navbar-nav">
						{Rashi}
						{props.shownav==='titles' && 
							<li className="nav-item">
								<Link to='/Hq' className="nav-link">עובדי הנהלה </Link>
							</li>
						}
				</ul>
	
    return (
        <nav className='navbar navbar-expand-lg navbar-dark bg-secondary text-white' >  
           
            <button   className="navbar-toggler"
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarNav" 
                        aria-controls="navbarNav" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
		  	<div className="collapse navbar-collapse" id="navbarNav">
				{props.shownav==='main' && MainPage}
				{props.shownav==='rashi' && Rashi}
			   	{(props.shownav==='titles' || props.shownav==='kablanim') && Lists}
            	{props.shownav==='logout' && logOut}
			</div>
        </nav>

  );

};

export default NavBar;

/*

	<li className="nav-item">
		<Link className="nav-link" to='/'>גיבוי </Link>
	</li> 

 <Link className='navbar-brand text-white' to='' >
                <img src={logo} title={'לוגו חברה'} alt={'לוגו חברה'} style={{'width':'60px'}}/>
            </Link>



*/

