import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { Column } from 'primereact/column';
import Text from '../Subcomponents/TextInput';
import { DataTable } from 'primereact/datatable';

   
const renderHeader = () => {
		return (
			<div className="flex justify-content-center align-items-center">
				<h5 className="m-0">לקוחות משוייכים</h5>
			</div>
		);
	};

const  EmplRefsGarbage = ({valueRef, value, CLickRow , vslct}) => {
   	const [dt, setDt] = useState([]);
	
	  useEffect(() => {
      setDt(valueRef.map(v => {
	 	return {...v,'key': uuid()};
	 	}));
    }, [valueRef]);
	
	
   return (
        <div style={{ direction: 'rtl' }} className="grid w-full mx-auto gap-0">
			<div className="fr col-12 md:col-5">
               	<div className="border-round-lg border-1 border-300">
                    <Text inpt='Number' vl={value.Number} lbl="מספר עובד" dis={true} />
                </div>
               	<div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='CustName' vl={vslct.CustName===null? '':vslct.CustName}  lbl={'בחירת לקוח'} dis={true} /> 
                    <Text inpt='AffName' vl={vslct.AffName} lbl='בחירת סניף' dis={true} />  
                </div>
              	<div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='SDateAff'  vl={vslct.SDateAff} lbl ='תחילת עבודה בסניף' dis={true}/>
                    <Text inpt='EDateAff'  vl={vslct.EDateAff} lbl='סיום עבודה בסניף' dis={true} />
                </div>
                <div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='CustNum' vl={vslct.CustNum} lbl="מספר סניף" dis={true}/>
                    <Text inpt='CustStreet' vl={vslct.Strt} lbl="רחוב" dis={true}/>
                    <Text inpt='CustCity' vl={vslct.Cty} lbl="עיר" dis={true}/>
                    <Text inpt='RegManagerName' vl={vslct.RgMngName} lbl="מנהל אזור" dis={true}/>
                </div>
            </div>
            <div className="col-12 md:col-7">
                <div className="border-round-lg border-1 border-300">
                    <Text inpt='Number' vl={(value.Number!=null) ? `${value.Name} ${value.LName}`: null} lbl="שם מלא של העובד" dis={true}/>
                </div>
                <div className="border-round-lg border-1 border-300 mt-2">
						<DataTable
						value={dt}
						responsiveLayout="scroll"
						className="p-1 refTable"
						header={renderHeader}
						onRowClick={CLickRow} // click row
						paginator rows={5}
						paginatorClassName='refsTablePaginator p-0'
						rowClassName='cursor-pointer'
					>
						<Column field="CustNum" header="#" className="refTable"></Column>
						<Column field="CustName" header="שם לקוח" className="refTable"></Column>
						<Column field="AffName" header="שם סניף" className="refTable"></Column>
						<Column field="SDateAff" header="תחילת עבודה" className="refTable" ></Column>
						<Column field="EDateAff" header="סיום עבודה" className="refTable" ></Column>
					</DataTable> 
                </div>
            </div>             
        </div> 
        );
};

export default EmplRefsGarbage;