import React, {useContext, useEffect} from 'react';
import Form from './Form';
import {ProgressSpinner} from 'primereact/progressspinner';
import List from './List';
import NavBar from '../Subcomponents/NavBar/NavBar';
import {ListsContext} from '../../contexts/listsContext';
import {readDataAll} from '../../functions/functions.js';
import uuid from 'react-uuid';

import './Kablanim.css';

const Lists =({shownav}) => {
	
	const {spinner, setList, setSpinner,setEntity} = useContext(ListsContext);
	
	useEffect(() => {
		
		 const loadData = async() =>{
			setSpinner(true);
			let int = shownav.charAt(0).toUpperCase() + shownav.slice(1); 
			let listTmp =  await readDataAll(int); //First letter Uppercase
			 	
	    	listTmp = listTmp.map(v => {
	    		return {'id': uuid(), 'item': v.item};
		});
	
		setSpinner(false);
		setList(listTmp);
		setEntity(int)
	 }
		
		
	loadData();
	 }, [setSpinner, setList, shownav, setEntity]);
    

	return(
			<div className="bg" >
				<NavBar shownav={shownav}/>
			 	{spinner ? <ProgressSpinner className="spinner"/>:null}
				
					<div className='container card flex-wrap lg:w-6 md:w-7 xl:w-3'>
						<Form />
						<List />
					</div>
			
			</div>	
			
	)
};
export default Lists;
