import React, { useContext, useRef, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/useDataContext';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';

const Slct = (props) => {
	const [divSize, setDivSize] = useState(null);
	const op = useRef(null);
	//const val =
	//	props.vl === '' || props.vl == null || props.vl.toString() === '-1' ? undefined : props.vl;


	const {
		handleChangeSRef,
		handleChangeScust,
		handleChangeRef,
		isSelected,
		setIsSelected,
		isEditing,
		setIsEditing,
		setvalueEditDrpDown,
		custData,
		setCustDataDrpDown,
		valueEditDrpDown,
		setAftDataDrpDown,
	} = useContext(DataContext);

	let filtRows = props.option.map((value, indx) => {

		return (
			<div
				className="text-right directionRTL hover:bg-blue-100 cursor-pointer"
				key={indx}
				onClick={
					(props.option.length !== 1 || props.option[0] !== 'אין נתונים') && //not אין נתונים
					props.emplRefs
						? handleChangeSRef(props.inpt) //emplrefs
						:  handleChangeScust(props.option, props.inpt) //custDetails
				}
				style={value.trim()===props.plchldr.trim() ? {color: '#4338CA',background: '#EEF2FF'}: {}} //to highlight the selected item in the list
			>
				<div className="mr-2 text-xs py-2 itemDropDown">{value}</div>
			</div>
		);
	});

	useEffect(() => {
		isSelected && op.current.hide();
	}, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		var clientWidth = document.getElementById('inptContainer');
		if (clientWidth !== null) setDivSize(clientWidth.clientWidth + 3);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const openDrownDown = (e) => {
		setIsEditing({ ...isEditing, [props.inpt]: true });

		setIsSelected(false);
		setvalueEditDrpDown({ ...valueEditDrpDown, [props.inpt]: '' });
	};

	const closeDropDown = () => {
		setIsEditing({ CustName: false, AffName: false });

		let result = custData
			.map((k) => k.CustName.trim())
			.filter((val, id, array) => array.indexOf(val) === id); //remove duplicates
		result.sort();
		setCustDataDrpDown(result);
		result = custData
			.map((k) => k.AffName.trim())
			.filter((val, id, array) => array.indexOf(val) === id); //remove duplicates
		result.sort();
		setAftDataDrpDown(result);
	};

	return (
		<div className="w-full">
			<div className="flex justify-content-around align-content-center ">
				<div className="col-6 p-1">
					<label className="float-right text-sm">{props.lbl}</label>
				</div>
				<div className="col-6 p-1">
					<div className="p-input-icon-left w-full">
						<i
							className={`pi pi-angle-down border-right-1 pr-1 border-300 ${
								isEditing[props.inpt] ? 'text-800' : 'text-400'
							} `}
							style={{ left: '8px' }}
							onClick={(e) => op.current.toggle(e)}
						/>

						<InputText
							id="inptContainer"
							value={props.vl}
							name={props.inpt}
							onChange={handleChangeRef()}
							onClick={(e) => op.current.toggle(e)}
							placeholder={isEditing[props.inpt] ? props.plchldr : undefined}
							className={`text-xs w-full border-1 border-solid outline-none focus:border-primary focus:shadow-none inpHght hover:border-solid
							${props.vl === '' && props.r ? 'redBorder' : 'inptBorder'} pr-2 plcholdr itemDropDown cursor-pointer`}
						/>
					</div>
					<OverlayPanel
						ref={op}
						className="pannelDropDown"
						onShow={openDrownDown}
						onHide={closeDropDown}
					>
						<div className="max-h-20rem overflow-auto p-0" style={{ width: divSize }}>
							{filtRows}
						</div>
					</OverlayPanel>
				</div>
			</div>
		</div>
	);
};

export default Slct;

/*
	<Dropdown
						value={val != null && val !== undefined ? val : undefined }
						options={props.option}
						onChange={
							props.rf
								? handleChangeSRef(props.option, props.inpt)
								: props.slct
								? handleChangeScust(props.option, props.inpt)
								: handleChangeS
						}
					//	name={props.inpt}
						placeholder={props.lbl}
						className={`text-xs w-full border-1 border-solid outline-none focus:border-primary focus:shadow-none inpHght hover:border-solid
						${props.vl === '' && props.r ? 'redBorder' : 'inptBorder'}`}
						itemTemplate={itemTemplate}
						panelStyle={{width: '175px'}}
						filter={shsrch}
						filterBy='CustName'
						optionLabel='CustName'
						
					/>
					*/