import React from 'react';
import Text from '../Subcomponents/TextInput';
import { Chip } from 'primereact/chip';
import { InputTextarea } from 'primereact/inputtextarea';

const  HqDetailsGarbage = ({value}) => {

	
    return (
       <div style={{ direction: 'rtl' }} className="grid w-full mx-auto mt-1">
			<div className="col-12 md:col-6">
				<div className="border-round-lg border-1 border-300">
                    <Text inpt='HqNum' vl={value.HqNum} lbl="מספר עובד" dis={true}/>
                    <Text inpt='Ttl' vl={value.Ttl} lbl='תפקיד' dis={true} />
                    <Text inpt='HqId' vl={value.HqId} lbl="תעודת זהות"  dis={true} />
                    <Text inpt='HqName'  vl={value.HqName} lbl="שם פרטי" dis={true} />
                    <Text inpt='HqLName' vl={value.HqLName} lbl="שם משפחה" dis={true} />
                </div>
                <div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='SDate'  vl={value.SDate} lbl ='תחילת עבודה' dis={true} />
                    <Text inpt='EDate'  vl={value.EDate} lbl={'סיום עבודה'} dis={true}/>
                </div>
               <div className="border-round-lg border-1 border-300 mt-2">
                    <Text  inpt='Strt'    vl={value.Strt} lbl="רחוב" dis={true}/>
                    <Text  inpt='Cty' vl={value.Cty} lbl="עיר" dis={true} />
                    <Text  inpt='HqTel' vl={value.HqTel} lbl="מס' טלפון"  dis={true}  />
                </div>
            </div>
            <div className="col-12 md:col-6">
                <div  >
                    <label className='fr'>הערות</label>
                    <InputTextarea rows={5}  name ='Notes' value={value.Notes} className='w-full'/>   
                </div>
                <div className="mt-2 text-center">
                    <Chip
							label={value.ActStts ? 'עובד פעיל' : 'עובד לא פעיל'}
							className={`w-8rem justify-content-center h-2rem ${
								value.ActStts ? 'bg-indigo-400 text-0' : 'bg-black-alpha-30 text-0'
							}`}
						/>
				</div>
			</div>                     
        </div>  
        );
};

export default HqDetailsGarbage;
