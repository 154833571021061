import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataContext } from '../../../contexts/useDataContext';
import CustDetails from './CustDetails';
import { AuthContext } from '../../../contexts/useAuthContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm , FormProvider} from "react-hook-form";
import {
	getDataCustRefs,
	updateRegNumInRefs,
	updateDocument,
	movetoGarbage,
	delDoc,
	addDoc,
	getData,
} from '../../../functions/functions.js';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const mustFillValue = ['CustNum', 'CustName', 'SDate', 'AffName', 'RgMngName'];

const CustModal = (props) => {
	const {
		value,
		setValue,
		emplData,
		setEmplData,
		custData,
		setCustData,
		displayDialog,
		setDisplayDialog,
		setDateBack,
	} = useContext(DataContext);

	const [cl, setCl] = useState(false);
	const [refsData, setRefsData] = useState();
	const { write } = useContext(AuthContext);
	const toast = useRef(null);
	const [spinnerTableEmpl, setSpinnerTableEmpl] = useState(false);
	const  methods = useForm();
	
	useEffect(() => {
		if (value.ActStts) {
			//load refs

			const loadrefData = async () => {
				
				setSpinnerTableEmpl(true);
				let refData = await getDataCustRefs(value.CustNum);
				console.log('spinning')
				setRefsData(refData);
				setSpinnerTableEmpl(false);
			};

			loadrefData();
		} else {
			console.log('Ref is Empty');
		}
	}, [value.ActStts, value.CustNum]); // Pass empty array to only run once on mount.

	/**********************************/
	const handleSubmit = async () => {
		setCl(true);
		for (let key in mustFillValue) {
			if (
				value[mustFillValue[key]] === '' ||
				value[mustFillValue[key]] === null ||
				value[mustFillValue[key]] === undefined
			)
				return;
		}

		let tmpALL = custData; //active and not active

		if (!props.isnotActLoaded) {
			//טעינה של לא פעלים פעם ראשונה ובדיקה
			let dataOfNotActive = await getData('Cust', false);
			props.setIsnotActLoaded(true); //טעינה של לא פעילים
			tmpALL = [...custData, ...dataOfNotActive].sort((a, b) =>
				a.CustNum > b.CustNum ? 1 : b.CustNum > a.CustNum ? -1 : 0
			);
		}

		if (tmpALL.map((x) => x.CustNum).includes(value.CustNum)) {
			alert('מספר סניף כבר קיים');
			return;
		}

		let tmp = value.EDate !== '' ? false : true;
		let valTmp = { ...value, ActStts: tmp };
		setValue(valTmp);
		let newArr = [...tmpALL, valTmp];
		setCustData(newArr);

		let Addd = await addDoc('Cust', valTmp.CustNum, valTmp);
		Addd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'הסניף התווסף בהצלחה',
			});
	};

	const handleUpdate = async () => {
		setCl(false);
		if (value.CustNum == null || value.CustNum === '') {
			alert('אין נתונים מתאימים');
			return;
		}
		if (setDateBack(value.SDate) > setDateBack(value.EDate) && value.EDate !== '') {
			alert('שגיעה בתאריכי הזנה!');
			return;
		}
		if (value.EDate !== '' && !value.ActStts) {
			alert('הלקוח אינו פעיל!');
			return;
		}

		if (value.EDate !== '' && refsData.length > 0) {
			alert('ישנם עובדים פעילים בסניף ולכן לא ניתן להזין תאריך סיום פעילות לסניף זה');
			return;
		}

		const tableValue = custData.filter((x) => x.CustNum === value.CustNum)[0];
		let isChanged = tableValue.RgMngNum !== value.RgMngNum;

		if (isChanged) {
			await updateRegNumInRefs(value.RgMngNum, value.CustNum, refsData);

			let tmpEmplData = [];
			if (!emplData.length) {
				let emplDt = await getData('Empl', true);
				tmpEmplData = [...emplDt];
			}

			for (let i in refsData) {
				let emplIndex = tmpEmplData.findIndex((x) => x.Number === refsData[i].Number);
				let tmpArr = tmpEmplData[emplIndex]['ref2Cust'];
				let ref2Cust = [];

				for (let key in tmpArr) {
					if (
						tmpArr[key].Rgnmgr !== value.RgMngNum &&
						tmpArr[key].CustNum === value.CustNum
					) {
						let newObj = { ...tmpArr[key], Rgnmgr: value.RgMngNum };
						ref2Cust.push(newObj);
					} else {
						ref2Cust.push(tmpArr[key]);
					}
				}
				tmpEmplData[emplIndex].ref2Cust = ref2Cust;
			}

			setEmplData(tmpEmplData);
		}

		let tmp = value.EDate !== '' ? false : true;
		let valTmp = { ...value, ActStts: tmp };
		setValue(valTmp);

		let newArr = custData.map((x) => (x.CustNum === valTmp.CustNum ? valTmp : x));
		setCustData(newArr);

		let Upd = await updateDocument('Cust', valTmp.CustNum, valTmp);
		Upd &&
			toast.current.show({
				closable: false,
				severity: 'success',
				summary: 'עדכון  הלקוח הסתיים',
			});
	};

	const handleDelete = (e) => {
		if (value.CustNum === '') {
			alert('אין נתונים למחיקה');
			return;
		}
		if (refsData != null) {
			if (refsData.length > 0) {
				alert('ישנם עובדים פעילים בסניף ולכן לא ניתן למחוק!');
				return;
			}
		}

	  confirmDialog({
        message: 'שים לב: הלקוח ימחק מהרישומים!',
        header: 'האם למחוק?',
		focusOnShow: false,
		className:'cnfDialaog',
		acceptClassName: 'p-button-text buttonTextFont',
		rejectClassName:'p-button-text buttonTextFont text-red-500',
		acceptLabel: 'כן ',
		rejectLabel: 'לא',
        accept: async() =>{
					const del=async()=>{
						let newArr = custData.filter(x=> x.CustNum!==value.CustNum)
		 				setCustData(newArr)
						let Dld = await delDoc('Cust', value.CustNum);
                   		Dld && toast.current.show({closable: false, severity: 'success', summary: 'מחיקת הסניף הסתיימה'});
					}
					del();
                	toGarbage();
                	reset();
					},
        reject: () => {}
    	});
		
	};

	/**********************************/

	const toGarbage = async () => {
		let tmpObj = value;
		tmpObj = { ...tmpObj, type: 'cust', DelTime: Date().substring(4, 21) };
		await movetoGarbage(tmpObj, tmpObj.CustNum);
	};

	const reset = (e) => {
		setValue(props.obj);
		setRefsData([]);
	};

	/**********************************/

	const footer = (
		<div style={{ direction: 'rtl' }}>
			{write && (
				<Button
					className="p-button-text buttonTextFont"
					type="submit"
					onClick={handleSubmit}
					color="primary"
					label="הוספה"
				/>
			)}
			{write && (
				<Button
					className="p-button-text buttonTextFont"
					onClick={handleUpdate}
					color="primary"
					label="עדכן"
				/>
			)}
			{write && (
				<Button
					className="p-button-text buttonTextFont"
					onClick={handleDelete}
					color="primary"
					label="מחיקה"
				/>
			)}
			<Button
				className="p-button-text buttonTextFont"
				onClick={reset}
				color="primary"
				label="נקה"
			/>
			<Button
				className="p-button-text buttonTextFont text-red-500"
				onClick={() => setDisplayDialog(false)}
				label="יציאה"
			/>
		</div>
	);

	return (
		<>
			<Toast ref={toast} position="top-left" className="pgrowl"></Toast>
			<ConfirmDialog /> 
			<Dialog
				header="כרטיס לקוח סניף"
				visible={displayDialog}
				onHide={() => setDisplayDialog(false)}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
				<FormProvider {...methods}>
					<CustDetails refsData={refsData} cl={cl} spinnerTableEmpl={spinnerTableEmpl}/>
				</FormProvider>
			</Dialog>
		</>
	);
};

export default CustModal;

/*

                
               */