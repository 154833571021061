import React, {useRef } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';

const ColFilter = ({ cols, handleToggleCols }) => {
	const op = useRef(null);

	let filtRows = cols.map((value,indx) => {
		return (
			<div className='popupMenuColumns text-right py-2 pr-1' key={indx}>
				<span className='mr-2'>{value.header}</span>
				<Checkbox onChange={() => handleToggleCols(value)} checked={value.showcol} />
			</div>
		);
	});
	
	return (
		<div>
			<Button
				icon="pi pi-bars"
				className="p-button-rounded p-button-text p-button-plain buttonCustom outline-none rotate-90"
				onClick={(event) => op.current.toggle(event)}
				style={{ color: 'dimgray' }}
				tooltip="עמודות"
				tooltipOptions={{ position: 'bottom' }}
			/>

			<OverlayPanel ref={op} className='w-13rem max-h-25rem overflow-auto text-right shadow-3'>
				<div className='pb-1'>עמודות להצגה</div>
				<div>{filtRows}</div>
			</OverlayPanel>
		</div>
	);
};

export default ColFilter;