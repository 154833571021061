import React from 'react';
import HqDetailsGarbage from './HqDetailsGarbage';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

	const HqModalGarbage = (props) =>{
     
    const footer = <div style={{direction: 'rtl'}}>
		   	<Button className='p-button-text buttonTextFont' onClick={()=>props.restore(props.slct.HqNum,'HqNum','hq')} color="primary" 	label="אחזר" />
			<Button className='p-button-text buttonTextFont' onClick={()=>props.delete(props.slct.HqNum, 'HqNum')} color="primary" label="מחיקה" />
			<Button className='p-button-text buttonTextFont text-red-500' onClick={props.onClose} color="secondary" label="יציאה"/>
                        </div>;
 
	return (
		
		<Dialog
				header="כרטיס לקוח סניף"
				visible={props.displayDialogHq}
				onHide={props.onClose}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
			  <HqDetailsGarbage 
					value={props.slct}
        />
		</Dialog>
		
   
    );
    };

export default HqModalGarbage;





/*
 	
                
               */
      
      