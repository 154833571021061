import React, { useContext } from 'react';
import { DataContext } from '../../contexts/useDataContext';
import { Calendar } from 'primereact/calendar';

const DPicker = (props) => {
	const { handleChangeD, handleChangeDRef, clrDate, setDateYMD } = useContext(DataContext);

	let val =
		props.vl !== '' && props.vl !== null && props.vl !== undefined
			? setDateYMD(props.vl)
			: undefined;

	return (
		<div className="w-full">
			<div className="flex justify-content-around align-content-center">
				<div className="col-6 p-1">
					<label className="float-right text-sm">{props.lbl}</label>
				</div>
				<div className="col-6 p-1">
					<Calendar
						id="basic"
						value={val != null && val !== undefined ? val : null}
						onChange={
							!props.rf ? handleChangeD(props.inpt) : handleChangeDRef(props.inpt)
						}
						dateFormat="dd-mm-yy"
						placeholder="בחר תאריך"
						inputClassName={`text-xs p-2 border-solid  border-round outline-none w-full focus:border-primary plcholdr
									focus:shadow-none inpHght ${props.vl === '' && props.r ? 'redBorder' : 'inptBorder'}`}
						name={props.inpt}
						className="w-full"
						showButtonBar
						onClearButtonClick={clrDate(props.inpt)}
					/>
				</div>
			</div>
		</div>
	);
};

export default DPicker;

/*

{/*<DatePicker
						style={{ width: '100%' }}
						name={props.inpt}
						onChange={
							!props.rf ? handleChangeD(props.inpt) : handleChangeDRef(props.inpt)
						}
						value={val != null && val !== undefined ? val : null}
						placeholder="בחר תאריך"
						format={dateFormat}
						showToday={false}  locale={locale}
						className={props.vl === '' && props.r ? 'redBorderD' : 'inptBorderD'}
				//	/>

*/