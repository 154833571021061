import React, {createContext} from 'react';
import useDataState from '../hooks/useDataState';


export const DataContext = createContext();

const DataProvider=(props)=>{
		const dataStuff = useDataState();
		
		return (
			<DataContext.Provider value={dataStuff}>
				{props.children}
			</DataContext.Provider>
		);
	};

export default DataProvider;