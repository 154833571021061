import { useState, useEffect, useContext, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../Subcomponents/TableHeader/Header';
import EmplModal from './Modal/EmplModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import SideBar from '../Subcomponents/SideBar';
import NavBar from '../Subcomponents/NavBar/NavBar';
//import PageFooter from '../Subcomponents/PageFooter';
import { getData, readDataAll } from '../../functions/functions.js';
import { DataContext } from '../../contexts/useDataContext';
import { CntrOption } from '../Subcomponents/dropDownOptions';
import Main from '../../logos/Main.png';
import Cust from '../../logos/Cust.png';
import Mng from '../../logos/Mng.png';
import Hq from '../../logos/Hq.png';
import AddEmpl from '../../logos/Add_empl.png';
import { Calendar } from 'primereact/calendar';

import {
	bodyTemplateName,
	mstnnFilterTemplate,
	statusFilterTemplate,
	filterSets,
} from './EmplFuncHelpers';

import '../Subcomponents/tableCSS.css';
const dateFormat = require('dateformat');	
const sideBar = [
	{ id: 1, url: '/main', label: 'בית', img: Main, ttl: 'בית' },
	{ id: 2, url: '/refs', label: 'מנהלי אזור', img: Mng, ttl: 'מנהלי אזור' },
	{ id: 3, url: '/cust', label: 'לקוחות', img: Cust, ttl: 'לקוחות' },
	{ id: 4, url: '/hq', label: 'עובדי מטה', img: Hq, ttl: 'עובדי מטה' },
	{ id: 5, url: '#', label: 'טופס חדש', img: AddEmpl, ttl: 'טופס חדש' },
];

let propDefaults = [
	{ field: 'Number', header: '#', showcol: true, w: 70 },
	{ field: 'NewNum', header: 'חדש #', showcol: true, w: 70 },
	{ field: 'Name', header: 'שם פרטי', showcol: true, w: 150 },
	{ field: 'LName', header: 'שם משפחה', showcol: true, w: 150 },
	{ field: 'NName', header: 'כינוי', showcol: true, w: 150 },
	{ field: 'Tel', header: 'טלפון', showcol: true, w: 150 },
	{ field: 'Visa', header: 'מספר ויזה', showcol: true, w: 130 },
	{ field: 'VValdty', header: 'תוקף ויזה', showcol: true, w: 140, showFilter: true },
	{ field: 'Id', header: 'תעודת זהות', showcol: true, w: 130 },
	{ field: 'Insrnce', header: 'מספר ביטוח', showcol: true, w: 150 },
	{ field: 'SDate', header: 'תחילת עבודה', showcol: true, w: 140, showFilter: true },
	{ field: 'EDate', header: 'סיום עבודה', showcol: false, w: 140, showFilter: true },
	{ field: 'KHol', header: 'מספר קופ"ח', showcol: false, w: 150 },
	{ field: 'Status', header: 'סטטוס', showcol: false, w: 200, showFilter: true },
	{ field: 'InsrnceVldty', header: 'תוקף ביטוח', showcol: false, w: 140, showFilter: true },
	{ field: 'Strt', header: 'רחוב', showcol: false, w: 150 },
	{ field: 'Cty', header: 'עיר', showcol: false, w: 120 },
	{ field: 'Notes', header: 'הערות', showcol: false, w: 150 },
	{ field: 'Mstnn', header: 'מסתנן', showcol: false, w: 140, showFilter: true },
	{ field: 'Bday', header: 'ת. לידה', showcol: false, w: 150 },
	{ field: 'Gndr', header: 'מין', showcol: false, w: 100 },
	{ field: 'Bcountry', header: 'ארץ לידה', showcol: false, w: 150 },
	{ field: 'T101', header: '101', showcol: false, w: 100 },
	{ field: 'ActStts', header: 'פעיל', showcol: false, w: 150 },
	{ field: 'Kablan', header: 'קבלן', showcol: false, w: 150 },
];

const Empl = (props) => {
	const {
		emplData,
		setEmplData,
		setCustData,
		selectValue,
		displayDialog,
		setDateBack,
		setKabsList,
		setAftDataDrpDown,
		setCustDataDrpDown,
		setCountryDataDrpDown
	} = useContext(DataContext);

	const [isnotActLoaded, setIsnotActLoaded] = useState(false);
	const [showOnlyActive, setShowOnlyActive] = useState(false);
	const [sinunVal, setSinunVal] = useState({InsrnceVldty:null, VValdty:null, SDate:null, EDate:null})
	let myRef = useRef();

	const [cols, setCols] = useState(propDefaults);
	const [showSearch, setShowSearch] = useState(false);
	const [spinner, setSpinner] = useState(false);

	const [filters1, setFilters1] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		const loadData = async () => {
			setSpinner(true);

			let empldata = await getData('Empl', true); 

			setEmplData(empldata);
	

			let arrCust = await getData('Cust', true); //await readDataAll('Cust').filter(x=> ActStts.true);
				
			let arrCustListNameTemp = arrCust.map((k) => k.CustName.trim());
			arrCustListNameTemp.sort();
			let removedDuplicates = arrCustListNameTemp.filter(
				(val, id, array) => array.indexOf(val) === id
			);

			setCustDataDrpDown(removedDuplicates);
			setCustData(arrCust);

			arrCustListNameTemp = arrCust.map((k) => k.AffName.trim());
			arrCustListNameTemp.sort();
			removedDuplicates = arrCustListNameTemp.filter(
				(val, id, array) => array.indexOf(val) === id
			);

			setAftDataDrpDown(removedDuplicates);

			setCountryDataDrpDown(CntrOption);

			setFilters1(filterSets);

			setSpinner(false);
		};

		loadData();

		const loadkabsList = async () => {
			let kabsListTmp = await readDataAll('Kablanim');

			setKabsList(kabsListTmp.map((itm) => itm.item));
		};

		loadkabsList();
	}, [setEmplData, setCustData, setKabsList, setCustDataDrpDown]); // eslint-disable-line react-hooks/exhaustive-deps

	const dsplSlctVal = (e) => {
		selectValue(e.data);
	};

	const addEnt = (x) => (e) => {
		if (x === 5) {
			selectValue(createEmptyObj());
		}
	};

	const createEmptyObj = () => {
		let tmpObj = {};
		propDefaults
			.map((k) => k.field)
			.map((q) => {
				return (tmpObj[q] = '');
			});

		return tmpObj;
	};

	const onSearchChange = (event) => {
		myRef.current.filter(null, '', 'equals');
		setGlobalFilter(event.target.value);
		setSearchValue(event.target.value);
	}; //search

	const showSearchClick = () => {
		setShowSearch(!showSearch);
		setGlobalFilter(null);
		setSearchValue('');
	};

	const handleToggleCols = (value) => {
		let newArr = [...cols];
		let newObj = { ...value, showcol: !value.showcol };
		newArr[newArr.map((i) => i.field).indexOf(value.field)] = newObj;
		setCols(newArr);
	};

	const resetFilter = () => {
		console.log('reset');
		setGlobalFilter(null);
		setSearchValue('');
		setShowOnlyActive(false);

		myRef.current.reset();
	};

	const showAct = async () => {
		let tmpALL = emplData; //active and not active

		if (!isnotActLoaded) {
			setSpinner(true);
			let dataOfNotActive = await getData('Empl', false);
			
			setIsnotActLoaded(true); //טעינה של לא פעילים
			tmpALL = [...emplData, ...dataOfNotActive].sort((a, b) =>
				a.Number > b.Number ? 1 : b.Number > a.Number ? -1 : 0
			);

			let seen = new Set(); //removre duplicates when deleting refs
			const filteredArr = tmpALL.filter((el) => {
				const duplicate = seen.has(el.Number);
				seen.add(el.Number);
				return !duplicate;
			});

			setEmplData(filteredArr);
			setSpinner(false);
		}

		setShowOnlyActive(!showOnlyActive);
	};

	
//************Dates Filter*****************************?//
 const dateFilterTemplate = (col) => {

	return <Calendar  dateFormat="dd-mm-yy" readOnlyInput={true} value={sinunVal[col.field]}
                                    onChange={(e)=>onfltDates(e.value, col.field)}
                    				placeholder="dd-mm-yyyy"
			   />
    }
		
  const onfltDates = (date, state) =>{ 
	
		 setSinunVal({...sinunVal, [state]: date })
		 let newArr = fltTable(date, state);
      
		if(newArr.length){
			myRef.current.filter(newArr.map(x=>x[state]), state,  "in")
		}else{
			myRef.current.filter('jfgjhfgjhgvjhfj', state,  "equals")
		}
    };


    const fltTable = (date, state) => { 
    
        let tempD=emplData;
		
		//initial filter per request
		
        if(date!=='' && state==='VValdty'){
			tempD = tempD.filter(v => setDateBack(v.VValdty) <= dateFormat(date, "yyyy-mm-dd") && v.VValdty!=='');
		}else if(date!=='' && state==='InsrnceVldty'){
			tempD = tempD.filter(v => setDateBack(v.InsrnceVldty) <= dateFormat(date, "yyyy-mm-dd") && v.InsrnceVldty!=='');
		}else if(date!=='' && state==='SDate'){
			tempD =tempD.filter(v => setDateBack(v.SDate) >= dateFormat(date, "yyyy-mm-dd") && v.SDate!=='' );
		}else if(date!=='' && state==='EDate'){
			tempD = tempD.filter(v => setDateBack(v.EDate) <= dateFormat(date, "yyyy-mm-dd") && v.EDate!=='' );
		}else if(date===''){
			return tempD;
		}  
		
		//Check if other filters are assigned
		
	  	tempD = (sinunVal.VValdty!==null && state!=='VValdty')?
                tempD.filter(v => setDateBack(v.VValdty) <=
                dateFormat(sinunVal.VValdty, "yyyy-mm-dd") && v.VValdty!=='') : tempD;
       
        tempD = (sinunVal.InsrnceVldty!==null && state!=='InsrnceVldty') ?
                tempD.filter(v => setDateBack(v.InsrnceVldty) <=
                dateFormat(sinunVal.InsrnceVldty, "yyyy-mm-dd") && v.InsrnceVldty!=='') : tempD;
        
	
        tempD = (sinunVal.SDate!==null && state!=='SDate')?
                tempD.filter(v => setDateBack(v.SDate) >=
                dateFormat(sinunVal.SDate, "yyyy-mm-dd") && v.SDate!=='' ) : tempD;
       
        tempD = (sinunVal.EDate!==null && state!=='EDate') ?
                tempD.filter(v => setDateBack(v.EDate) <=
                dateFormat(sinunVal.EDate, "yyyy-mm-dd") && v.EDate!=='' ) : tempD;
		
	
		return tempD;
    };

	const clrFlt = (state) => e =>{
		setSinunVal({...sinunVal, [state]: null })
 		myRef.current.filter(null, [state], 'equals');
    };
	
//*******************************************//
	
	
	
	console.log('qw');

	const header = (
		<Header
			showSearch={showSearch}
			onChange={onSearchChange}
			showActive={showAct}
			showSrchInpt={showSearchClick}
			searchValue={searchValue}
			showYNAct={showOnlyActive}
			resetFltr={resetFilter}
			handleToggleCols={handleToggleCols}
			cols={cols}
			tableName="עובדי שטח"
			
			expTableCols={cols.filter((col) => col.showcol === true)}
			data={!showOnlyActive ? emplData.filter((x) => x.ActStts === true) : emplData}
		/>
	);

	let dynamicColumns = cols
		.filter((col) => col.showcol === true)
		.slice(0)
		.reverse()
		.map((col, i) => {
			return (
				<Column
					key={col.field}
					field={col.field}
					header={col.header}
					filter={col.showFilter}
					filterElement={
						col.field === 'SDate' ||
						col.field === 'EDate' ||
						col.field === 'VValdty' ||
						col.field === 'InsrnceVldty'
							? dateFilterTemplate
							: col.field === 'Mstnn'
							? mstnnFilterTemplate
							: col.field === 'Status'
							? statusFilterTemplate
							: null
					}
					showFilterOperator={false}		//filter
					showFilterMatchModes={false}  	//filter
					showAddButton={false}		//filter
					showApplyButton={col.field === 'SDate' ||
						col.field === 'EDate' ||
						col.field === 'VValdty' ||
						col.field === 'InsrnceVldty' ? false:true}  //filter
					
					onFilterClear={clrFlt(col.field)}
					sortable={false}
					headerStyle={{ overflow: 'visible', width: col.w + 'px' }}
					style={{ width: col.w + 'px' }}
					body={
						col.field === 'Name'
							? bodyTemplateName: null
					}
				/>
			);
		});

	let sidebar = sideBar.map((value) => (
		<SideBar
			click={addEnt(value.id)}
			key={value.id}
			img={value.img}
			ttl={value.ttl}
			alt={value.alt}
			url={value.url}
		/>
	));

	return (
		<div className="bg">
			<NavBar shownav={props.shownav} /> 
			
			<div className="container card flex-wrap p-4 surface-card border-round-lg">
				{spinner ? <ProgressSpinner className="spinner" /> : null}

				<DataTable
					value={!showOnlyActive ? emplData.filter((x) => x.ActStts === true) : emplData}  
					ref={myRef}
					paginator={true}
					rows={10}
					rowsPerPageOptions={[10, 25, 50, 100]}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
					globalFilter={globalFilter}
					filters={filters1}
					selectionMode="single"
					header={header}
					//onSelectionChsange={e => setSlctEmpl(e.value)}
					onRowClick={dsplSlctVal}
					//   autoLayout={true}
					responsiveLayout="scroll"
					emptyMessage="אין נתונים"
				>
					{dynamicColumns}
				</DataTable>
			</div>
			{displayDialog ? (
				<EmplModal
					isnotActLoaded={isnotActLoaded}
					setIsnotActLoaded={setIsnotActLoaded}
					obj={createEmptyObj()}
				/>
			) : null}

			<div className="icon-bar"> {sidebar}</div>
			{/*<PageFooter /> */}
		</div>
	);
};

export default Empl;