import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../Subcomponents/TableHeader/Header';
import RefsModal from './Modal/RefsModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import SideBar from '../Subcomponents/SideBar';
import NavBar from '../Subcomponents/NavBar/NavBar';
import { getData, getRegsMngs, getDataPerMng, readDataAll } from '../../functions/functions.js';
import { DataContext } from '../../contexts/useDataContext';
import { AuthContext } from '../../contexts/useAuthContext';

import {
	mstnnFilterTemplate,
	statusFilterTemplate,
	filterSets, t101FilterTemplate,
} from '../Empl/EmplFuncHelpers';

import Main from '../../logos/Main.png';
import Empl from '../../logos/Empl.png';
import Cust from '../../logos/Cust.png';
import Hq from '../../logos/Hq.png';

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const dateFormat = require('dateformat');

const sideBar = [
	{ id: 1, url: '/main', alt: 'בית', img: Main, ttl: 'בית' },
	{ id: 2, url: '/empl', alt: 'עובדי שטח', img: Empl, ttl: 'עובדי שטח' },
	{ id: 3, url: '/cust', alt: 'לקוחות', img: Cust, ttl: 'לקוחות' },
	{ id: 4, url: '/hq', alt: 'עובדי מטה', img: Hq, ttl: 'עובדי מטה' },
];

let propDefaults = [
	{ field: 'Number', header: '#', showcol: true, initial: true },
	{ field: 'NewNum', header: 'חדש #', showcol: true, initial: true },
	{ field: 'Name', header: 'שם פרטי', showcol: true, initial: true },
	{ field: 'LName', header: 'שם משפחה', showcol: true, initial: true },
	{ field: 'NName', header: 'כינוי', showcol: true, initial: true },
	{ field: 'Tel', header: 'טלפון', showcol: true, initial: true },
	{ field: 'Visa', header: 'מספר ויזה', showcol: true, initial: true },
	{ field: 'VValdty', header: 'תוקף ויזה', showcol: true, initial: true, showFilter: true },
	{ field: 'Id', header: 'תעודת זהות', showcol: true, initial: true },
	{ field: 'Kablan', header: 'קבלן', showcol: true, initial: true },
	{ field: 'SDateAff', header: 'ת. עב. בסניף', showcol: true, initial: true },
	{ field: 'EDateAff', header: 'ס. עב. בסניף', showcol: false, initial: true },
	{ field: 'AffName', header: 'סניף', showcol: true, initial: true, showFilter: true },
	{ field: 'SDate', header: 'תחילת עבודה', showcol: true, initial: true, showFilter: true },
	{ field: 'EDate', header: 'סיום עבודה', showcol: false, initial: true, showFilter: true },
	{ field: 'KHol', header: 'מספר קופ"ח', showcol: false, initial: false },
	{ field: 'CustName', header: 'לקוח', showcol: false, initial: false, showFilter: true },
	{ field: 'InsrnceVldty', header: 'תוקף ביטוח', showcol: false, initial: false, showFilter: true },
	{ field: 'Status', header: 'סטטוס', showcol: false, initial: false, showFilter: true },
	{ field: 'Insrnce', header: 'מספר ביטוח', showcol: false, initial: false },
	{ field: 'Mstnn', header: 'מסתנן', showcol: false, initial: false, showFilter: true },
	{ field: 'RegMngr', header: 'מנהל אזור', showcol: true, initial: false },
	{ field: 'T101', header: '101', showcol: false, initial: false, showFilter: true },
];

const Refs = (props) => {
	const { regmng, user } = useContext(AuthContext);
	const {
		emplData,
		setEmplData,
		custData,
		setCustData,
		selectValue,
		displayDialog,
		setDateBack,
		refsData,
		setRefsData,
		filteredData,
		setFilteredData,
		setKabsList,
		kabsList,
	} = useContext(DataContext);
	const [sinunVal, setSinunVal] = useState({
		CustName: null,
		AffName: null,
		T101: null,
		Status: null,
		Mstnn: null,
		InsrnceVldty: null,
		VValdty: null,
		SDate: null,
		EDate: null,
	});
	const [runFirstTime, setRunFirstTime] = useState(false);
	const [reglist, setReglist] = useState([]);
	const [mngChosen, setMngChosen] = useState(null);
	let myRef = useRef();
	const [custFlt, setCustFlt] = useState(null);
	const [affFlt, setAffFlt] = useState(null);
	const [showTable, setShowTable] = useState(false);

	const [cols, setCols] = useState(propDefaults);
	const [runLoadRefs, setRunLoadRefs] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [spinner, setSpinner] = useState(false);
	
	const [filters1, setFilters1] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	const [sidebarLinks, setSidebarLinks] = useState(sideBar);

	const setAddData = (doc, rf) => {
		let indx = custData.findIndex((x) => x.CustNum === rf.CustNum);
		return {
			...doc,
			SDateAff: rf.SDateAff,
			EDateAff: rf.EDateAff,
			CustNum: rf.CustNum,
			CustName: indx !== -1 ? custData[indx].CustName : null,
			AffName: indx !== -1 ? custData[indx].AffName : null,
			RegMngr: indx !== -1 ? custData[indx].RgMngName : null,
		};
	};

	useEffect(() => {
		console.log('start Refs');

		if (regmng) {
			let newSideBar = sideBar.filter((x) => x.id === 1 /*|| x.id===3*/);
			setSidebarLinks(newSideBar);
		}

		const loadData = async () => {
			setSpinner(true);
			setRunFirstTime(true);

			if (!emplData.length) {
				let emplD = await getData('Empl', true);
				setEmplData(emplD);
			}
			if (!custData.length) {
				let cstdata = [];
				if (!regmng) {
					//filtering the cust list if the user is not manager
					cstdata = await getData('Cust', true);
				} else {
					cstdata = await getDataPerMng('Cust', true, user.user.displayName);
				}

				setCustData(cstdata);
			}

			let regListTmp = await getRegsMngs();

			if (regmng) {
				//filtering the regs list if the user is not manager
				regListTmp = regListTmp.filter((x) => x.num === user.user.displayName);
				setReglist(regListTmp);
			} else {
				regListTmp.push({ name: 'כל מנהלי אזור', num: '0000' });
				setReglist(regListTmp);
			}

			if (!kabsList.length) {
				const kabsListTmp = await readDataAll('Kablanim');
				setKabsList(kabsListTmp.map((itm) => itm.item));
			}
			
			setFilters1(filterSets);
			setSpinner(false);
		};

		!runFirstTime && loadData();

		const setAddData = (doc, rf) => {
			let indx = custData.findIndex((x) => x.CustNum === rf.CustNum);
			return {
				...doc,
				SDateAff: rf.SDateAff,
				EDateAff: rf.EDateAff,
				CustNum: rf.CustNum,
				CustName: indx !== -1 ? custData[indx].CustName : null,
				AffName: indx !== -1 ? custData[indx].AffName : null,
				RegMngr: indx !== -1 ? custData[indx].RgMngName : null,
			};
		};

		const loadRefs = () => {
			let Dt = [];
			for (let x in emplData) {
				let tmpArr = emplData[x].ref2Cust;
				for (var y in tmpArr) {
					if (tmpArr[y].Rgnmgr === mngChosen || mngChosen === '0000') {
						//מנהל ספציפי
						Dt.push(setAddData(emplData[x], tmpArr[y]));
					}
				}
			}

			setRefsData(Dt);
			setRunLoadRefs(false);
		};

		runLoadRefs && loadRefs();
	}, [
		regmng,
		user,
		runFirstTime,
		custData,
		emplData,
		setEmplData,
		setCustData,
		mngChosen,
		runLoadRefs,
		setRefsData,
		kabsList,
		setKabsList,
	]);

	const slctMngr = async (e) => {
		const regNum = reglist.filter((x) => x.name === e.value)[0].num;
		setSpinner(true);
		setMngChosen(regNum);
		setShowTable(true);
		let Dt = [];
		for (let x in emplData) {
			let tmpArr = emplData[x].ref2Cust;
			for (var y in tmpArr) {
				if (tmpArr[y].Rgnmgr === regNum) {
					//מנהל ספציפי
					Dt.push(setAddData(emplData[x], tmpArr[y]));
				}
				if (regNum === '0000') {
					//כולם
					Dt.push(setAddData(emplData[x], tmpArr[y]));
				} //
			}
		}

		setRefsData(Dt);
		setFilteredData(Dt);
		setSpinner(false);
		setCustFlt(
			Dt.map((v) => v.CustName) //sort for filter
				.filter((val, id, array) => array.indexOf(val) === id)
				.map((vv) => {
					return { label: vv, value: vv };
				})
				.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
		);

		setAffFlt(
			Dt.map((v) => v.AffName) //sort for filter
				.filter((val, id, array) => array.indexOf(val) === id)
				.map((vv) => {
					return { label: vv, value: vv };
				})
			.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
		);
	};

	const dsplSlctVal = (e) => {
		selectValue({ ...e.data });
	};

	const createEmptyObj = () => {
		let tmpObj = {};
		propDefaults
			.map((k) => k.field)
			.map((q) => {
				return (tmpObj[q] = '');
			});
		return tmpObj;
	};

	const onSearchChange = (event) => {
		myRef.current.filter(null, '', 'equals');
		setGlobalFilter(event.target.value);
		setSearchValue(event.target.value);
	}; //search

	const showSearchClick = () => {
		setShowSearch(!showSearch);
		setGlobalFilter(null);
		setSearchValue('');
		setShowFilter(false);
	};

	const handleToggleCols = (value) => {
		let newArr = [...cols];
		let newObj = { ...value, showcol: !value.showcol };
		newArr[newArr.map((i) => i.field).indexOf(value.field)] = newObj;
		setCols(newArr);
	};

	const resetFilter = async () => {
		console.log('reset');
		setGlobalFilter(null);
		setSearchValue('');
		setSinunVal({
			CustName: null,
			AffName: null,
			T101: null,
			Status: null,
			Mstnn: null,
			InsrnceVldty: null,
			VValdty: null,
			SDate: null,
			EDate: null,
		});
		setShowFilter(false);

		myRef.current.reset();
	};

	
	//************Dates Filter*****************************?//
	const dateFilterTemplate = (col) => {
		return (
			<Calendar
				dateFormat="dd-mm-yy"
				readOnlyInput={true}
				value={sinunVal[col.field]}
				onChange={(e) => onfltDates(e.value, col.field)}
				placeholder="dd-mm-yyyy"
			/>
		);
	};

	const clrFlt = (state) => (e) => {
		setSinunVal({ ...sinunVal, [state]: null });
		fltTable('', state);
	};

	const onfltDates = (date, state) => {
		setSinunVal({ ...sinunVal, [state]: date });
		let newArr = fltTable(date, state);

		if (newArr.length) {
			myRef.current.filter(newArr.map((x) => x[state]),state,	'in');
		} else {
			myRef.current.filter('jfgjhfgjhgvjhfj', state, 'equals');
		}
	};

	const fltTable = (date, state) => {
		console.log('נכנס לסינון');
		let tempD = refsData; // = isnotActLoaded ? emplActAndnAct : emplinitial;
		//initial filter per request
		if (date !== '' && state === 'VValdty') {
			tempD = tempD.filter(
				(v) => setDateBack(v.VValdty) <= dateFormat(date, 'yyyy-mm-dd') && v.VValdty !== ''
			);
		} else if (date !== '' && state === 'InsrnceVldty') {
			tempD = tempD.filter(
				(v) =>
					setDateBack(v.InsrnceVldty) <= dateFormat(date, 'yyyy-mm-dd') &&
					v.InsrnceVldty !== ''
			);
		} else if (date !== '' && state === 'SDate') {
			tempD = tempD.filter(
				(v) => setDateBack(v.SDate) >= dateFormat(date, 'yyyy-mm-dd') && v.SDate !== ''
			);
		} else if (date !== '' && state === 'EDate') {
			tempD = tempD.filter(
				(v) => setDateBack(v.EDate) <= dateFormat(date, 'yyyy-mm-dd') && v.EDate !== ''
			);
		} else if (date === '') {
			return tempD;
		}

		//Check if other filters are assigned
	
		tempD =
			sinunVal.VValdty !== null && state !== 'VValdty'
				? tempD.filter(
						(v) =>
							setDateBack(v.VValdty) <= dateFormat(sinunVal.VValdty, 'yyyy-mm-dd') &&
							v.VValdty !== ''
				  )
				: tempD;

		tempD =
			sinunVal.InsrnceVldty !== null && state !== 'InsrnceVldty'
				? tempD.filter(
						(v) =>
							setDateBack(v.InsrnceVldty) <=
								dateFormat(sinunVal.InsrnceVldty, 'yyyy-mm-dd') &&
							v.InsrnceVldty !== ''
				  )
				: tempD;

		tempD =
			sinunVal.SDate !== null && state !== 'SDate'
				? tempD.filter(
						(v) =>
							setDateBack(v.SDate) >= dateFormat(sinunVal.SDate, 'yyyy-mm-dd') &&
							v.SDate !== ''
				  )
				: tempD;

		tempD =
			sinunVal.EDate !== null && state !== 'EDate'
				? tempD.filter(
						(v) =>
							setDateBack(v.EDate) <= dateFormat(sinunVal.EDate, 'yyyy-mm-dd') &&
							v.EDate !== ''
				  )
				: tempD;

	
		return tempD;
	};

	const custFilterTemplate =(options)=> <Dropdown style={{width: '100%'}} value={options.value} options={custFlt} 
						placeholder='בחר לקוח'
	onChange={(e) => options.filterCallback(e.value, options.index)} />;  

	const affFilterTemplate = (options)=><Dropdown style={{width: '100%'}} value={options.value} options={affFlt} placeholder='בחר סניף'
	onChange={(e) => options.filterCallback(e.value, options.index)}  />;

/************************************************************/
	
	console.log('qw');

	const header = (
		<Header
			showSearch={showSearch}
			onChange={onSearchChange}
			showSrchInpt={showSearchClick}
			showFilter={showFilter}
			searchValue={searchValue}
			resetFltr={resetFilter}
			handleToggleCols={handleToggleCols}
			cols={cols}
			tableName="טבלת שיוכים"
			
			expTableCols={cols.filter((col) => col.showcol === true)}
			data={refsData}
		/>
	);

	let dynamicColumns = cols
		.filter((col) => col.showcol === true)
		.slice(0)
		.reverse()
		.map((col, i) => {
			return (
				<Column
					key={col.field}
					field={col.field}
					header={col.header}
					filter={col.showFilter}
					filterElement={
						col.field === 'SDate' ||
						col.field === 'EDate' ||
						col.field === 'VValdty' ||
						col.field === 'InsrnceVldty'
							? dateFilterTemplate
							: col.field === 'Mstnn'
							? mstnnFilterTemplate
							: col.field === 'Status'
							? statusFilterTemplate
							:col.field === 'T101'
							? t101FilterTemplate
						:col.field === 'CustName'
							? custFilterTemplate
						:col.field === 'AffName'
							? affFilterTemplate
							: null  
					}
					showFilterOperator={false}		//filter
					showFilterMatchModes={false}  	//filter
					showAddButton={false}		//filter
					showApplyButton={col.field === 'SDate' ||
						col.field === 'EDate' ||
						col.field === 'VValdty' ||
						col.field === 'InsrnceVldty' ? false:true}  //filter
					onFilterClear={clrFlt(col.field)}
					sortable={false}
					headerStyle={{ overflow: 'visible' }}
				/>
			);
		});

	let sidebar = sidebarLinks.map((value) => (
		<SideBar key={value.id} img={value.img} ttl={value.ttl} alt={value.alt} url={value.url} />
	));

	let slctManagers = (
		<Dropdown
			value={mngChosen != null && reglist.filter((x) => x.num === mngChosen)[0].name}
			onChange={(e) => slctMngr(e)}
			options={reglist.map((k) => k.name)}
			placeholder="בחר מנהל אזור"
			className={`text-xs w-full border-1 border-solid outline-none focus:border-primary focus:shadow-none inpHght 
								hover:border-solid plcholdr dropDown`}
			dropdownIcon="pi pi-angle-down"
			style={{ direction: 'rtl' }}
		/>
	);

	const setFilteredDataforTable = (filteredData) => {
		if (filteredData) setFilteredData(filteredData);
	};

	let table = (
		<div className="container card flex-wrap p-4 mt-4 surface-card border-round-lg">
			<DataTable
				value={refsData}
				ref={myRef}
				paginator={true}
				rows={10}
				rowsPerPageOptions={[10, 25, 50, 100]}
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
				globalFilter={globalFilter}
				selectionMode="single"
				filters={filters1}
				header={header}
				onRowClick={dsplSlctVal}
				emptyMessage="אין נתונים"
				sortField={'AffName'}
				sortOrder={1}
				onValueChange={filteredData => setFilteredDataforTable(filteredData)}
				responsiveLayout="scroll"
			>
				{dynamicColumns}
			</DataTable>
		</div>
	);

	return (
		<div>
			<NavBar shownav={props.shownav}/>  
			<div className="container">
				{spinner ? <ProgressSpinner className="spinner" /> : null}

				<div className="w-14rem text-right p-3 mr-6" style={{ float: 'right' }}>
					{slctManagers}
				</div>
				<hr style={{ width: '95%' }} />
				{showTable && table}
			</div>
			{displayDialog ? <RefsModal   obj={createEmptyObj()}
										 			mngChosen={mngChosen}
										 		setRunLoadRefs={setRunLoadRefs}
									 /> : null}

			<div className="icon-bar"> {sidebar}</div>
		</div>
	);
};

export default Refs;

/*
   


*/