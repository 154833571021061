import React, {useState, useEffect, useContext, useRef} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {DataContext} from '../../../contexts/useDataContext';
import EmplDetails from '../../Empl/Modal/EmplDetails';
import EmplRefs from '../../Empl/Modal/EmplRefs';
import {AuthContext} from '../../../contexts/useAuthContext';
import {updateDocument, getDataFromHis, movetoHistory} from '../../../functions/functions.js';
import { TabView, TabPanel } from 'primereact/tabview';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import {Toast} from 'primereact/toast';


    const mustFillValueRef = ['SDateAff', 'AffName'];

    
    const RefsModal = (props) =>{
		
	 const {emplData, setEmplData, custData,value, setValue, valueRef, setValueRef, displayDialog, setDisplayDialog,setDateBack,
			setArrIndex} = useContext(DataContext);	
	 const [dataRef, setDataRef] = useState([])
     const {write} = useContext(AuthContext);	
		
     const [tabind,setTabind] = useState(0);
     const [loadSpin, setLoadSpin] = useState(false);
     const [radSelect, setRadSelect] = useState(1);
     const [cl,setCl] = useState(false);
   	 const toast = useRef(null);
		
   const  slctEmplRef = {CustNum: '',CustName:'', AffName:'',
                SDateAff: '', EDateAff:'',Notes:'',Rgnmgr:''};

    /*******************************************************************/
 
    useEffect(() => {
		
		const findVal = (cust,x) =>{ 
		let moshe =   custData.filter(k => k.CustNum===cust)[0];
		return moshe === undefined ? '' : moshe[x];
		};
		
		const load = async() =>{
		let	rf = value.ref2Cust;
			
			let dataRef=[];
			for (var key in rf) {
				dataRef.push({
					key: key,
					CustNum: rf[key].CustNum,
					CustName: findVal(rf[key].CustNum, 'CustName'),
					AffName: findVal(rf[key].CustNum, 'AffName'),
					SDateAff: rf[key].SDateAff,
					EDateAff: rf[key].EDateAff,
					Rgnmgr: rf[key].Rgnmgr
				});
			}
			
			 dataRef[0]!==undefined ? setValueRef(dataRef[0]): setValueRef({CustNum: '',CustName:'', AffName:'',
                SDateAff: '', EDateAff:'',Notes:'',Rgnmgr:''});
			 dataRef[0]!==undefined ? setArrIndex(custData.findIndex(x => x.CustNum === dataRef[0].CustNum)): setArrIndex(undefined);
			 setDataRef(dataRef);
		
		};
		load();
		
    }, [custData, setValueRef, value, setArrIndex]); // Pass empty array to only run once on mount.
  
		const findVal = (cust,x) =>{ 
            let moshe =   custData.filter(k => k.CustNum===cust)[0];
            return moshe === undefined ? '' : moshe[x];
    };
		
	const refTablePrepape=(dt)=>{
		let newDt=[];
			for (let key in dt) {
					newDt.push({
						key: key,
						CustNum: dt[key].CustNum,
						CustName: findVal(dt[key].CustNum, 'CustName'),
						AffName: findVal(dt[key].CustNum, 'AffName'),
						SDateAff: dt[key].SDateAff,
						EDateAff: dt[key].EDateAff,
						Rgnmgr: dt[key].Rgnmgr
				 	});
			}
		return newDt;
	};
		
	const reset = e => {
		setValue(props.obj);  //לבדוק את האובייקט
		setValueRef(slctEmplRef);
		setArrIndex(undefined);
		setDataRef(null);
		setCl(false);
	};
    
	// /**********************************/
	const handleSubmit = e => {addNewRef();};
	const handleUpdate = e => {(tabind===0) ? updateEmpl(): udpateRef();};
    
	// /**********************************/
    
	const updateEmpl = async() => {
		
		if (value.Number==null || value.Number===''){  alert("אין נתונים מתאימים"); return;}
		if((setDateBack(value.SDate) > setDateBack(value.EDate)) &&
		(value.EDate!=='')){ alert("שגיעה בתאריכי הזנה!"); return;}
		if(value.EDate!=='' && !value.ActStts){alert('העובד אינו פעיל!'); return;}

		let valTmp = {...value};

		if(value.EDate!==''){  //סיום פעילות
			valTmp = {...value, 'ActStts':false, 'ref2Cust':[]};
			setValue(valTmp);
			setDataRef(dataRef.map(o => ({ ...o, EDateAff: value.EDate }))); //לעדכן טבלה עם תאריכי גמר
			toHis(dataRef.map(o => ({ ...o, EDateAff: value.EDate })));  //הכנה להעברת להיסטוריה
		}
		
		delete valTmp.CustNum;
		delete valTmp.CustName;
		delete valTmp.AffName;
		delete valTmp.SDateAff;
		delete valTmp.EDateAff;
		
		let newArr = emplData.map(x=> x.Number===valTmp.Number? valTmp: x)
	 	setEmplData(newArr)
		props.setRunLoadRefs(true)	

		let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
		Upd && toast.current.show({closable: false, severity: 'success', summary: 'עדכון העובד הסתיים'}); 
	};
	
	const toHis=async(dt) => {
		for (let key in dt){
				delete dt[key].key;     
				let obj= Object.assign({...dt[key]},
					{Number: value.Number},
					{Name: value.Name},
					{LName: value.LName},
					{NName: value.NName},
					{RgMngName:findVal(dt[key].CustNum, 'RgMngName')},
					{SDate: value.SDate},
					{EDate: value.EDate}
				);
	   await movetoHistory(obj);	
		}
	};

	// /**********************************/
    
	 const addNewRef = async() =>{
	 	setCl(true);
		for (let key in mustFillValueRef){
			if (valueRef[mustFillValueRef[key]] === '' || valueRef[mustFillValueRef[key]]
			=== null || valueRef[mustFillValueRef[key]] === undefined) return;
		}
		setCl(false); 

		if(value.Number===''){alert('אין נתונים'); return;}
		if( value['EDate']!=='' && dataRef.length<1){alert('העובד אינו פעיל. נא להגדיר תקופת עבודה חדשה!'); return;}
		if(radSelect===2){alert('לא ניתן להוסיף שיוכים, נא להעביר לשיוכים פעילים!'); return;}
		if(setDateBack(value.SDate) > setDateBack(valueRef.SDateAff)){ alert("תאריך תחילת עבודה בסניף אינו יכול להיות מוקדם יותר מתחילת עבודה בחברה!"); return;}
        
	 	if(dataRef!=null) if(dataRef.map(x => x.CustNum).includes(valueRef.CustNum)) {  alert("שיוך כבר קיים"); return;}
        
	const newData = {
		key: valueRef.CustNum,
		CustNum: valueRef.CustNum,
		CustName: valueRef.CustName,
		AffName: valueRef.AffName,
		SDateAff: valueRef.SDateAff,
		EDateAff: valueRef.EDateAff,
		Rgnmgr: findVal(valueRef.CustNum, 'RgMngNum')
	};  
         
		let newArray = [...dataRef, newData];
		
		newArray = setShortRef2Cust(newArray)
		let valTmp = {...value, 'ref2Cust':newArray};
		 
	 	valTmp = !valTmp.ActStts ? {...valTmp, 'ActStts':true}: valTmp;
		setValue(valTmp);
		
	
		delete valTmp.CustNum;
		delete valTmp.CustName;
		delete valTmp.AffName;
		delete valTmp.SDateAff;
		delete valTmp.EDateAff;
		 
		let newArr = emplData.map(x=> x.Number===valTmp.Number? valTmp : x);
	 	setEmplData(newArr)
		props.setRunLoadRefs(true)	
		 
	 	let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
		Upd && toast.current.show({closable: false, severity: 'success', summary: 'הוספת העובד הסתיימה'}); 

	 };
		
        
	 const udpateRef = async()=>{
		setCl(false);
		if(!value.ActStts){alert('העובד אינו פעיל!'); return;}
		if(dataRef==null || !dataRef.map(x => x.CustNum).includes(valueRef.CustNum)){ alert("אין שיוך מתאים");return;}
		if(radSelect===2){alert('לא ניתן לעדכן שיוך היסטורי'); return;}
		if((setDateBack(valueRef.SDateAff) > setDateBack(valueRef.EDateAff)) && 
		(valueRef.EDateAff!=='')){ alert("תאריך סיום עבודה בסניף לפני תחילת עבודה בסניף!"); return;}
		if(setDateBack(value.SDate) > setDateBack(valueRef.SDateAff)){ alert("תאריך תחילת עבודה בסניף אינו יכול להיות מוקדם יותר מתחילת עבודה בחברה!"); return;}

	 //first update everything
	let newArray = [...dataRef];
	newArray[dataRef.findIndex(x => x.CustNum === valueRef.CustNum)].SDateAff = valueRef.SDateAff;
	newArray[dataRef.findIndex(x => x.CustNum === valueRef.CustNum)].EDateAff = valueRef.EDateAff;
	//setDataRef(newArray); //update table
	
	let valTmp = {...value, 'ref2Cust':setShortRef2Cust(newArray)};
		 
	//finisehd working (end date)  move to history
	if(valueRef.EDateAff!==''){
		toHis([valueRef]);        //הכנה להעברת להיסטוריה
        
		//finisehd working (end date) and there are no more refs
		if(valueRef.EDateAff!=='' && dataRef.filter(k => k.EDateAff==='').length===0){ 
			valTmp = {...valTmp, 'ActStts':false, 'EDate':valueRef.EDateAff, 'ref2Cust':[]};
		}
	}
		 
		delete valTmp.CustNum;
		delete valTmp.CustName;
		delete valTmp.AffName;
		delete valTmp.SDateAff;
		delete valTmp.EDateAff; 
		 
		setValue(valTmp);
		valTmp = valTmp.ref2Cust ? {...valTmp, 'ref2Cust':valTmp.ref2Cust.filter(k => k.EDateAff==='')}: valTmp; 
		let newArr = emplData.map(x=> x.Number===valTmp.Number? valTmp : x);
	 	setEmplData(newArr)
		props.setRunLoadRefs(true)	
		 
	 	let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
		Upd && toast.current.show({closable: false, severity: 'success', summary: 'הוספת העובד הסתיימה'});  
	
	 };
    
	 const delRefDialog = event => {
		if(radSelect===2){alert('לא ניתן למחוק שיוך היסטורי!'); return;}
		const CustNum = dataRef[event.index].CustNum;
		 
		confirmDialog({
			message: 'האם להמשיך?',
			header: 'השיוך ימחק!',
			focusOnShow: false,
			className:'cnfDialaog',
			acceptClassName: 'p-button-text buttonTextFont',
			rejectClassName:'p-button-text buttonTextFont text-red-500',
			acceptLabel: 'כן ',
			rejectLabel: 'לא',
			accept: async() =>{
				const deleteRef=async()=>{
					let tmpArr = dataRef.filter(k => k.CustNum!==CustNum && k.EDateAff==='');
					let valTmp = {...value, 'ref2Cust':setShortRef2Cust(tmpArr)};

					if(tmpArr.length===0){ 
						valTmp = {...valTmp, 'ActStts':false, 'ref2Cust':[]};
					}
					
					setValue(valTmp);
					
						delete valTmp.CustNum;
						delete valTmp.CustName;
						delete valTmp.AffName;
						delete valTmp.SDateAff;
						delete valTmp.EDateAff;
					
					let newArr = emplData.map(x=> x.Number===valTmp.Number? valTmp : x);
					setEmplData(newArr)
					props.setRunLoadRefs(true)	

					let Upd = await updateDocument('Empl', valTmp.Number, valTmp);
					Upd && toast.current.show({closable: false, severity: 'success', summary: 'הוספת העובד הסתיימה'});  
				}
				deleteRef();
			},
			reject: () => {}
			}); 
	 };
    
		
	const setShortRef2Cust=(newArray)	=>{
		let dt=[]
		for(let x in newArray){
			let obj ={
				'CustNum': newArray[x].CustNum,
				'SDateAff': newArray[x].SDateAff,
				'EDateAff': newArray[x].EDateAff,
				'Rgnmgr': newArray[x].Rgnmgr,
			}
			dt.push(obj)
		}
		return dt;
	}
		
	// /**********************************/
    
		const footer = <div style={{ direction: 'rtl' }}>
			{(tabind!==0 && 'write') && <Button className="p-button-text buttonTextFont" type="submit" onClick={handleSubmit} label="הוספה" color="primary"/>}
				 {write && <Button className="p-button-text buttonTextFont" onClick={handleUpdate} color="primary" label='עדכן' />}
				<Button className="p-button-text buttonTextFont" onClick={reset} label="נקה" color="primary"/>
				<Button
				className="p-button-text buttonTextFont text-red-500"
				onClick={() => setDisplayDialog(false)}
				label="יציאה"
			/>	  
		</div>;
 
	const changeRad = async(e) => {
		setRadSelect(e.target.value);
		if(e.target.value===1){ setDataRef(refTablePrepape(value.ref2Cust));
		}else{
		if(value.Number==='' || value.Number==null){alert('אין מספר עובד'); return;}
			setLoadSpin(true);
		let lastHisttoryData = await getDataFromHis(value.Number);
		setDataRef(lastHisttoryData);
		setLoadSpin(false);
		}
	};
    
	return (
		<>
			<Toast ref={toast} position="top-left" className='pgrowl'></Toast>
			<ConfirmDialog /> 
		<Dialog
				header="כרטיס עובד"
				visible={displayDialog}
				onHide={() => setDisplayDialog(false)}
				footer={footer}
				className="Modal"
				draggable={false}
				contentStyle={{ padding: 0 }}
				closeOnEscape
				position="top"
				focusOnShow={false}
				headerStyle={{
					textAlign: 'center',
					padding: '0.8rem',
					borderBottom: '1px solid #eee',
				}}
			>
					<TabView activeIndex={tabind} onTabChange={(e) => setTabind(e.index)} panelContainerClassName="p-0 pt-2">
						<TabPanel
							header="פרטי עובד"
							rightIcon="pi pi-user"
							key="1"
							className="tabPane "
						>
							<EmplDetails />
						</TabPanel>
						<TabPanel
							header="שיוכים"
							rightIcon="pi pi-share-alt"
							key="2"
							className="tabPane"
						>
							<EmplRefs
								delRefDialog={delRefDialog}
								loadSpin={loadSpin}
								dataRef={dataRef}
								changeRad={changeRad}
								radSelect={radSelect}
								cl={cl}
							/>
						</TabPanel>
				</TabView>
			
		</Dialog>
		
		
		{/*
			<Modal title="כרטיס עובד"   visible={displayDialog}  onCancel={()=>setDisplayDialog(false)}  footer={footer} className='Modal' >
				<Tabs defaultActiveKey="1" size={'small'} onTabClick={e => setTabind(e)} >
					<TabPane tab={<span><Icon type="share-alt" />שיוכים</span>} key="2">
						<EmplRefs       delRef={delRef}
										loadSpin={loadSpin}
										dataRef={dataRef}
										changeRad={changeRad}
										cl={cl}
							/>
					</TabPane>
					<TabPane tab={<span><Icon type="user" />פרטי עובד</span>} key="1">
							<EmplDetails   />	 
					</TabPane>
				</Tabs>
			</Modal>
			*/}
			</>
    );
    };

export default RefsModal;





/*

 
 
 	
             	
		  
			 
			 
			 
			 
			 
			 
                
               */
      
      