import { useState } from 'react';
import uuid from 'react-uuid';

import {addDoc, delDoc} from '../functions/functions.js';

	const useItems=() =>{
		const [list,setList] = useState([]);
		const [entity, setEntity] = useState([]);
		const [spinner, setSpinner] = useState(false);
		const [value,setValue] = useState('');

		return {
			list,setList,
			entity, setEntity,
			value,
			spinner,
			setSpinner,
			handleChange: e=>{
				setValue(e.target.value);
			},
			addItem: async(entity,newItem) =>{
				setList([...list, ({id:uuid(), item: newItem})]);
				setValue("");
				await addDoc(entity, newItem, {'item': newItem})
			},
			removeItem : async(entity, ItemId) =>{
				let item = list.filter(x=> x.id===ItemId)[0].item;
				let newArrItems = list.filter(x=> x.id!==ItemId);
				setList(newArrItems);
				await delDoc(entity, item)
			},
			editItem : async(entity, itemId, updItem) =>{
				let oldItem = list.filter(x=> x.id===itemId)[0].item;
				const updateItems = list.map(itm =>
					   itm.id===itemId? {...itm, 'item': updItem } : itm
					   );
				setList(updateItems);
				await delDoc(entity, oldItem)
				await addDoc(entity, updItem, {'item': updItem})
			}
		};
	};

export default useItems;