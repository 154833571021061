import React, { useState, useEffect, useContext, useRef } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import uuid from 'react-uuid';
import { Button } from 'primereact/button';
import NavBar from '../NavBar/NavBar';
import {getData} from '../../../functions/functions.js';
import {DataContext} from '../../../contexts/useDataContext';
import DrpDown from '../../Subcomponents/DropDown.js';
import { getFunctions, httpsCallable } from "firebase/functions";
import Text from '../../Subcomponents/TextInput';

import './Auth.css';


const dateFormat = require('dateformat');

let propDefaults = [ 
				{field: 'Name', header: 'שם מלא', showcol: true, initial:true},
				{field: 'UName', header: 'שם משתמש', showcol: true, initial:true},
                {field: 'Desc', header: 'תיאור', showcol: true, initial:true},
	 			{field: 'Write', header: 'כתיבה', showcol: true, initial:true},
				{field: 'start', header: 'התווסף', showcol: true, initial:true},
				{field: 'login', header: 'התחבר', showcol: true, initial:true},
				{field: 'Ttl', header: 'תפקיד', showcol: true, initial:true},
            	];

	const DescOption = ['משתמש רגיל','מנהל'];
	const writeOption = ['לא','כן'];
	

const Auth =(props) => {
		
		const {value, setValue} = useContext(DataContext);
		const [runFirstTime, setRunFirstTime] = useState(false);
	
		const [regsdt, setRegsdt] = useState([]);
        const [hq, setHq] = useState([]);
        const [spinner, setSpinner] = useState(false);
		const toast = useRef(null);
	
	
		 useEffect(() => {
			console.log('start Auth');
		
		let regdata=[]	
		 const loadData = async() =>{
			 	setRunFirstTime(true)
				setSpinner(true);
				
			 	regdata = await getData('Hq', true);
				regdata = regdata.filter(q=> q.ActStts).map(a =>{
					 return  {'Name': `${a.HqName} ${a.HqLName}`, 'Num': a.HqNum, 'Ttl': a.Ttl};
				 });
				setHq(regdata);
			 
			 	const getVal=(y,z)=>{
				if(	regdata.map(k =>k.Num).indexOf(y)!== -1 ){
					return regdata[regdata.map(k =>k.Num).indexOf(y)][z]
				}
					return '';
				}
			 
			 	
					const listAllUsers = httpsCallable(getFunctions(), 'listAllUsers');
					listAllUsers().then(result =>{
					let tmpData = result.data.tmp;
					let tmp =  tmpData.map(x=>{
							var tmpAdmin = x.photoURL.slice(-3,-2)==='y' ? true : false;
							var tmpWrite = x.photoURL.slice(-2,-1)==='y' ? true : false;
							let lstlgnTemp = x.metadata.lastSignInTime===null ?
											 '':dateFormat(x.metadata.lastSignInTime,'dd-mmm-yyyy');
						
							let tmpUser =  ({...x,'start':dateFormat(x.metadata.creationTime,'dd-mmm-yyyy'),
								 'login':lstlgnTemp,'UName': x.UName, 'Name': getVal(x.displayName, 'Name'),
									'Desc': tmpAdmin? 'מנהל': 'משתמש רגיל', 'Ttl': getVal(x.displayName, 'Ttl'),
									'Write': tmpWrite ? 'כן': 'לא',  'admin': tmpAdmin })
							delete tmpUser.metadata;
							delete tmpUser.photoURL;
							return tmpUser;}
						);
					
					setRegsdt(tmp);
					});	
			 
				setSpinner(false);
			 	
   		};
			 
			 	 
		if(!runFirstTime)loadData();	 
			
		}, [runFirstTime]);
    
    
	 const  dsplSlctVal= e =>{
		setValue({...value, 'Desc': e.data.Desc, 'Name': e.data.Name, 'UName': e.data.UName, 
				  'Write': e.data.Write, 'uid': e.data.uid})
	 }; 
	
	 const reset = e => {
		setValue(null);
    };
	
	const handleSubmit = async() => {
		if(value===null){alert("נא השלם את כל השדות");	return;}
		if(value.Desc==null || value.Name==null || value.UName==null ||
		   value.Pass==null || value.Write==null ){
		alert("נא השלם את כל השדות");	return;	}
		
		if(regsdt.map(x => x.Name).includes(value.Name)) {  alert("הרשאה קיימת"); return;}
		
		for(var i = 0; i < value.UName.length; i++) {
			if( (value.UName.charCodeAt(i)>=65 &&  value.UName.charCodeAt(i)<=90) || 
				(value.UName.charCodeAt(i)>=97 && value.UName.charCodeAt(i) <=122) ||
				(value.UName.charCodeAt(i)>=48 && value.UName.charCodeAt(i)<=57)){}
				else{ alert("שם משתמש יכול לקבל רק אותיות לועזיות או מספרים"); return;}
		}
		if(value.Pass.length<6){alert("אורך הסיסמה חייב להיות לפחות 6 תווים");	return;	}
		
		
		 const tmpStirng = 'http://a.a.a/';
		 const tmpAdmin = value.Desc==='מנהל' ? 'y':'n';
		 const tmpWrite = value.Write==='כן' ? 'y':'n';
		 const tmpTtl = hq.filter(x=>x.Name===value.Name)[0]['Ttl']==='מנהל אזור'? 'y':'n';
		
		
		 const srvrRow = {...value, 'displayName': hq[hq.map(k =>k.Name).indexOf(value.Name)].Num ,'UName': value.UName.concat('@is.is'),
						'photoURL': tmpStirng.concat(tmpAdmin).concat(tmpWrite).concat(tmpTtl),  'uid':uuid() }
		
		 
		 delete srvrRow.Desc;
		 delete srvrRow.Name;
		 delete srvrRow.Write;
		
		
			const addUser = httpsCallable(getFunctions(), 'addUser');	
			addUser(srvrRow).then(result=> {
			//props.setSnackbar( {open:true, msg: 'New user has been added!', variant: 'success'});
			toast.current.show({closable: false, severity: 'success', summary: 'נוספה הרשאה חדשה'}); 

			const tableRow = {'Name': value.Name, 'UName':value.UName, 'Desc': value.Desc, 'Write': value.Write,
							  'Ttl': hq[hq.map(k =>k.Name).indexOf(value.Name)].Ttl, 'start': dateFormat(new Date(),'dd-mmm-yyyy')}
			const newArr = [...regsdt, tableRow];
			setRegsdt(newArr);
		}); 
	
	}
	
	
	 const handleUpdate = async() =>{
		
		if(value===null){alert("נא השלם את כל השדות");	return;}
		if(value.Desc==null || value.Name==null || value.UName==null ||
		   value.Pass==null || value.Write==null ){
		alert("נא השלם את כל השדות");	return;	}
		
		
		for(var i = 0; i < value.UName.length; i++) {
			if( (value.UName.charCodeAt(i)>=65 &&  value.UName.charCodeAt(i)<=90) || 
				(value.UName.charCodeAt(i)>=97 && value.UName.charCodeAt(i) <=122) ||
				(value.UName.charCodeAt(i)>=48 && value.UName.charCodeAt(i)<=57)){}
				else{ alert("שם משתמש יכול לקבל רק אותיות לועזיות או מספרים"); return;}
		}
		if(value.Pass.length<6){alert("אורך הסיסמה חייב להיות לפחות 6 תווים");	return;	}
		 
		 const newArr = regsdt.map(x=>x.uid!==value.uid? x: {...value, 'Ttl': regsdt.filter(x=> x.uid===value.uid)[0]['Ttl'],
								'start': regsdt.filter(x=> x.uid===value.uid)[0]['start'],
								'login': regsdt.filter(x=> x.uid===value.uid)[0]['login']});
		 setRegsdt(newArr);
		 
		 const tmpStirng = 'http://a.a.a/';
		 const tmpAdmin = value.Desc==='מנהל' ? 'y':'n';
		 const tmpWrite = value.Write==='כן' ? 'y':'n';
		 const tmpTtl = hq.filter(x=>x.Name===value.Name)[0]['Ttl']==='מנהל אזור'? 'y':'n';
		 
		 const srvrRow = {...value, 'displayName': hq[hq.map(k =>k.Name).indexOf(value.Name)].Num ,'UName': value.UName.concat('@is.is'),
						 	'photoURL': tmpStirng.concat(tmpAdmin).concat(tmpWrite).concat(tmpTtl)}
		
		 
		 delete srvrRow.Desc;
		 delete srvrRow.Name;
		 delete srvrRow.Write;
			
			const updateUser = httpsCallable(getFunctions(), 'updateUser');
			updateUser(srvrRow).then(result=> {
				toast.current.show({closable: false, severity: 'success', summary: 'העדכון הסתיים'});
	 		})
	}
										 
	const handleDelete = async()=>{

		if(value==null){
		alert("חסר נתונים");	return;	}
		
		let	newArr = regsdt.filter(q=>q.uid!==value.uid);
		
	
		const delUser = httpsCallable(getFunctions(), 'delUser');
			delUser(value.uid).then(result=> {
			toast.current.show({closable: false, severity: 'success', summary: 'מחיקת הרשאה הסתיימה'});	
			setRegsdt(newArr)
		}); 
	} 
	
	
	
	const header = <div style={{textAlign:'right', paddingTop: '10px'}}>טבלת הרשאות</div> 
	 let dynamicColumns = propDefaults.filter(col => col.showcol === true).slice(0).reverse().map((col,i) =>{
             return <Column key={col.field} field={col.field} header={col.header} sortable={false} 
                headerStyle={{overflow:'visible' }}  /> ;
        });
	
	 
	const footer = <div className='Authfooter'>
			  	<Button className="p-button-text buttonTextFont" type="submit" onClick={handleSubmit} label="הוספה" color="primary"/>
				<Button className="p-button-text buttonTextFont" onClick={handleUpdate} color="primary" label='עדכן' />
				<Button className="p-button-text buttonTextFont" onClick={handleDelete} color="primary" label='מחיקה' />
				<Button className="p-button-text buttonTextFont" onClick={reset} label="נקה" color="primary"/>
            </div>;
	
	return(
		<div className="bg">
			<NavBar shownav={props.shownav}/>
			<div className='container card flex-wrap p-4 border-round-lg md:w-9'>
				<div className='grid w-full mx-auto' style={{direction: 'rtl'}}>
					<div className='col-12 md:col-5'>
						<div>
							<DrpDown inpt='Desc' vl={value!=null?value.Desc:null} lbl='תיאור משתמש' option={DescOption} /*r={props.cl} */ />
							<DrpDown inpt='Name' vl={value!=null?value.Name:null} 
									lbl='בחר עובד מהרשימה' option={hq.map(z=>z.Name)} /*r={props.cl} */ />	
							<Text inpt='UName' vl={value!=null?value.UName:''} lbl="שם משתמש" />
							<Text inpt='Pass' vl={value!=null?value.Pass: ''} lbl="סיסמה"  />
							<DrpDown inpt='Write' vl={value!=null?value.Write:null} 
									lbl='הרשאת כתיבה' option={writeOption} /*r={props.cl} */ />
						</div>
						<div>
							{footer}
						</div>
					</div>
					<div  className='col-12 md:col-7'>
							<div className="container mt-0 card flex-wrap p-4 surface-card border-round-lg">
									{spinner ? <ProgressSpinner className="spinner"/>:null}
									<Toast ref={toast}  position="top-left" className='pgrowl'></Toast>
									<DataTable value={regsdt}
												paginator={true}
												rows={10}
												rowsPerPageOptions={[10, 25, 50, 100]}
												paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
												currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
												header={header} 
												selectionMode="single"
												onRowClick={dsplSlctVal} 
												responsiveLayout="scroll"
												emptyMessage="אין נתונים"
												paginatorClassName='footerStyle'
												>
										{dynamicColumns}
									</DataTable>
							</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Auth;
