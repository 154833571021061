import React, { useContext} from 'react';
import '../Empl/Modal/EmplModal.css';
import { DataContext } from '../../contexts/useDataContext';


const Text = ({lbl,inpt, vl, r, dis }) => {

	const { handleChange } = useContext(DataContext);
	
	return (
		<div className="w-full">
			<div className='flex justify-content-around align-content-center'>
				<div className='col-6 p-1'>
					<label className="float-right text-sm">{lbl}</label>
				</div>
				<div className='col-6 p-1'>
					<input
						placeholder={lbl}
						className={`text-xs p-2 border-1 border-solid border-round outline-none focus:border-primary w-full plcholdr text-700
						focus:shadow-none inpHght ${vl === '' && r ? 'redBorder' : 'inptBorder'}
						${dis ? 'disabledInput' : 'aaa'} `}
						onChange={handleChange}
						value={vl}
						label={lbl}
						readOnly = {dis}
						name={inpt}
					/>
				</div>
			</div>
		</div>
	);
};

export default Text;