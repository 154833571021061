import { Checkbox } from 'primereact/checkbox';

const ShowNotActive = (props) => {
	

	return (
		<div className="pr-3 pt-1">
			<Checkbox
				onChange={props.showActive} 
				checked={props.showYNAct}
				tooltip="הצג לא פעילים"
				tooltipOptions={{ position: 'bottom' }}
			/>
		</div>
	);
};

export default ShowNotActive;