import React, { useContext } from 'react';
import { DataContext } from '../../../contexts/useDataContext';
import DPicker from '../../Subcomponents/DatePicker';
import Slct from '../../Subcomponents/SelectCompEdit-Cust-Aff';
import Text from '../../Subcomponents/TextInput';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressSpinner } from 'primereact/progressspinner';

const EmplRefs = (props) => {
	const { value, custData, valueRef, setValueRef, arrIndex, setArrIndex, custDataDrpDown, isEditing, valueEditDrpDown, aftDataDrpDown } = useContext(
		DataContext
	);
	
	const ab = (pr) => (arrIndex !== -1 && arrIndex !== undefined ? custData[arrIndex][pr] : '');

	const CLickRow = (e) => {
		setValueRef({
			...valueRef,
			CustNum: e.data.CustNum,
			CustName: e.data.CustName,
			AffName: e.data.AffName,
			SDateAff: e.data.SDateAff,
			EDateAff: e.data.EDateAff,
			Rgnmgr: e.data.Rgnmgr,
		});
		setArrIndex(custData.findIndex((x) => x.CustNum === e.data.CustNum));
	};

	const renderHeader = () => {
		return (
			<div className="flex justify-content-center align-items-center">
				<h5 className="m-0">לקוחות משוייכים</h5>
			</div>
		);
	};

	const header = renderHeader();
	
	return (
		<div style={{ direction: 'rtl' }} className="grid w-full mx-auto gap-0">
			<div className="fr col-12 md:col-5">
				<div className="border-round-lg border-1 border-300">
					<Text inpt="Number" vl={value.Number} lbl="מספר עובד" dis={true} /> 
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<Slct
						inpt={'CustName'}
						vl={isEditing.CustName ? valueEditDrpDown.CustName : valueRef.CustName}
						plchldr={valueRef.CustName}
						emplRefs={true}
						lbl={'בחירת לקוח'}
						r={props.cl}
						option={custDataDrpDown}
					/>
					<Slct
						inpt={'AffName'}
						vl={isEditing.AffName ? valueEditDrpDown.AffName : valueRef.AffName}
						plchldr={valueRef.AffName}
						emplRefs={true}
						lbl={'בחירת סניף'}
						r={props.cl}
						option={aftDataDrpDown}
					/> 
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<DPicker
						inpt="SDateAff"
						r={props.cl}
						vl={valueRef.SDateAff}
						lbl="תחילת עבודה בסניף"
						rf={true}
					/>
					<DPicker
						inpt="EDateAff"
						vl={valueRef.EDateAff}
						lbl="סיום עבודה בסניף"
						rf={true}
					/> 
				</div> 
				<div className="border-round-lg border-1 border-300 mt-2">
					<Text inpt="CustNum" vl={valueRef.CustNum} lbl="מספר סניף" dis={true} />
					<Text inpt="CustStreet" vl={ab('Strt')} lbl="רחוב" dis={true} />
					<Text inpt="CustCity" vl={ab('Cty')} lbl="עיר" dis={true} />
					<Text inpt="RegManagerName" vl={ab('RgMngName')} lbl="מנהל אזור" dis={true} />  
				</div>
			</div>
			<div className="fr col-12 md:col-7">
				<div className="border-round-lg border-1 border-300">
					<Text
						inpt="Number"
						vl={value.Number != null ? `${value.Name} ${value.LName}` : null}
						lbl="שם מלא של העובד"
						dis={true}
					/> 
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<DataTable
						value={props.dataRef}
						responsiveLayout="scroll"
						className="p-1 refTable"
						header={header}
						onRowClick={CLickRow} // click row
						onRowDoubleClick={props.delRefDialog}
						paginator rows={10}
						paginatorClassName='refsTablePaginator p-0'
						rowClassName='cursor-pointer'
					>
						<Column field="CustNum" header="#" className="refTable"></Column>
						<Column field="CustName" header="שם לקוח" className="refTable"></Column>
						<Column field="AffName" header="שם סניף" className="refTable"></Column>
						<Column field="SDateAff" header="תחילת עבודה" className="refTable" ></Column>
						<Column field="EDateAff" header="סיום עבודה" className="refTable" ></Column>
					</DataTable> 

				
				</div>
				
				{props.loadSpin && <ProgressSpinner style={{width: '50px', height: '50px'}}
									   strokeWidth="2" animationDuration=".5s"/>}
				<div className="border-round-lg border-1 border-300 mt-2 flex p-2 justify-content-center">
					<div className="m-2 flex align-items-center">
						<RadioButton
							name="radioButton"
							value={1}
							onChange={(e) => props.changeRad(e)}
							checked={props.radSelect === 1}
						/>
						<label className='mr-2 text-sm'>שיוכים פעילים</label>
					</div>
					<div className="m-2 flex align-items-center">
						<RadioButton
							name="radioButton"
							value={2}
							onChange={(e) => props.changeRad(e)}
							checked={props.radSelect === 2}
						/>
						<label className='mr-2 text-sm'>שיוכים היסטוריים</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmplRefs;
