import React, { useState, useContext } from 'react';
import { ListsContext } from '../../contexts/listsContext';

import './Kablanim.css';

const EditItem = (props) => {
	const [value, setValue] = useState(props.title);
	const { editItem, entity } = useContext(ListsContext);

	return (
		<form
			style={{ width: '100%', padding: '10px 0 0 0' }}
			onSubmit={(e) => {
				e.preventDefault();
				if (value !== '') {
					editItem(entity, props.id, value);
					setValue('');
					props.setIsediting(!props.isediting);
				} else {
					return;
				}
			}}
		>
			<input
				value={value}
				onChange={(e) => setValue(e.target.value)}
				className="divInputTextEdit"
				autoFocus
			/>
		</form>
	);
};

export default EditItem;