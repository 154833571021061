import { useState, useEffect, useContext } from 'react';

import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { getData } from '../../../functions/functions.js';

import Eempl from '../../../logos/Eempl.bmp';
import Hhq from '../../../logos/Hhq.bmp';
import Ccust from '../../../logos/Ccust.bmp';
import { DataContext} from '../../../contexts/useDataContext';

import './maindata.css';


const defaultProps = [
	{ id: 3, ttl: 'עובדי מטה', img: Hhq, num: '-', ent: 'Hq' },
	{ id: 2, ttl: 'לקוחות פעילים', img: Ccust, num: '-', ent: 'Cust' },
	{ id: 1, ttl: 'עובדי שטח פעילים', img: Eempl, num: '-', ent: 'Empl' },
];

const MainData = () => {

	const [dt, setDt] = useState(defaultProps);
	const { setEmplData, setCustData, setHqData } = useContext(DataContext);

	useEffect(() => {
		const loadCounts = async () => {
			
			let moshe = await defaultProps.map(async (x) => {
			let entityData =await getData(x.ent, true)
			
				return await { ...x, num: entityData.length, id: x.id, arr: entityData };
			});
				
			
 			const newArr = await Promise.all(moshe);
			
			
			for (let q in newArr) {
				if (newArr[q].ent === 'Empl') {
					setEmplData(newArr[q].arr);
				} else if (newArr[q].ent === 'Cust') {
					setCustData(newArr[q].arr);
				} else if (newArr[q].ent === 'Hq') {
					setHqData(newArr[q].arr);
				}
			}

			setDt(newArr);
		};
		loadCounts();
		
	}, [setEmplData, setCustData, setHqData]);

	
	let dd = Date().substring(4, 21);
	
	return (
		<div className="justify-content-center card-container grid mr-0 ml-0 mt-4 pb-5">
			{dt.map((x) => (
				<div className="col-12 lg:col-3 pl-5 pr-5 pt-6" key={x.id}>
					<Card className="pCardContent" style={{ background: 'whitesmoke' }}>
						<Image src={x.img} alt="Image Text" imageClassName="w-6rem -mt-6 border-round-sm shadow-5" imageStyle={{float: 'left'}}/>
						<p className="text-sm text-600 text-right m-0">{x.ttl}</p>
						<p className="text-xl text-600 text-right mt-1 mb-1 font-semibold">
							{x.num}
						</p>
						<Divider />
							<p className="text-sm text-600 text-right m-0">{dd} :לתאריך</p>
					</Card>
				</div>
			))}
		</div>
	);
};
export default MainData;