import React, { useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { DataContext } from '../../../contexts/useDataContext';
import DPicker from '../../Subcomponents/DatePicker';
import DrpDown from '../../Subcomponents/DropDown.js';
import Text from '../../Subcomponents/TextInput';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import Slct from '../../Subcomponents/SelectEdit-Aff';
import { ProgressSpinner } from 'primereact/progressspinner';

const renderHeader = () => {
		return (
			<div className="flex justify-content-center align-items-center">
				<h5 className="m-0">רשימת עובדים</h5>
			</div>
		);
	};

const header = renderHeader();


const YesNo = ['לא', 'כן'];
const GlobalHour = ['שעתי', 'גלובלי'];
const HitkashrutOptions = ['האלוף בניקיון', 'כוח אדם'];

const CustDetails = (props) => {
	const { value, handleChange, reglist, valueEditDrpDown, isEditing , aftDataDrpDown} = useContext(DataContext);
	const regList = reglist.map((w) => w.name);

	return (
		<div style={{ direction: 'rtl' }} className="grid w-full mx-auto mt-0">
			<div className="col-12 md:col-4">
				<div className="border-round-lg border-1 border-300">
					<Slct inpt='AffName' vl={isEditing.AffName ? valueEditDrpDown.AffName : value.AffName} 
                            lbl='בחירת שם סניף' option={aftDataDrpDown}  plchldr={value.AffName}/>
					<Text
						inpt="CustNum"
						vl={value.CustNum}
						r={props.cl}
						lbl="מספר סניף"
						dis={value.ActStts ? true : false}
					/>
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<DPicker inpt="SDate" vl={value.SDate} r={props.cl} lbl="תחילת פעולת" />
					<DPicker inpt="EDate" vl={value.EDate} lbl={'סיום פעילות'} />
				</div>
				<div className="border-round-lg border-1 border-300 mt-2">
					<Text inpt="CustName" vl={value.CustName} r={props.cl} lbl="שם לקוח" />
					<Text inpt="AffName" vl={value.AffName} r={props.cl} lbl="שם הסניף" />
					<Text inpt="ContactName" vl={value.ContactName} r={props.cl} lbl="שם איש קשר" />
					<Text
						inpt="ContactTel"
						vl={value.ContactTel}
						r={props.cl}
						lbl="טלפון איש קשר"
					/>
					<Text inpt="Strt" vl={value.Strt} lbl="רחוב" />
					<Text inpt="Cty" vl={value.Cty} lbl="עיר" />
				</div>

				<div className="border-round-lg border-1 border-300 mt-2">
					<DrpDown
						inpt="RgMngName"
						vl={value.RgMngName}
						slct={true}
						lbl="מנהל אזור"
						option={regList}
						r={props.cl}
					/>
					<Text inpt="RgMngNum" vl={value.RgMngNum} dis={true} lbl="מס' מנהל אזור" />
				</div>
			</div>

			<div className="col-12 md:col-8">
				{props.spinnerTableEmpl ? <ProgressSpinner className="spinner" strokeWidth="4" animationDuration=".5s" /> : null }
				<div className="border-round-lg border-1 border-300">
					<DataTable
						value={props.refsData}
						responsiveLayout="scroll"
						className="p-1 refTable"
						header={header}
						paginator rows={10}
						paginatorClassName='refsTablePaginator p-0'
						rowClassName='cursor-pointer'
					>
						<Column field="Number" header="#" className="refTable"></Column>
						<Column field="Name" header="שם פרטי" className="refTable"></Column>
						<Column field="LName" header="שם משפחה" className="refTable"></Column>
						<Column field="NName" header="כינוי" className="refTable" ></Column>
						<Column field="SDateAff" header="תחילת עבודה" className="refTable" ></Column>
						<Column field="EDateAff" header="סיום עבודה" className="refTable" ></Column>
					</DataTable> 
					
				</div>
				<div className="grid w-full mx-auto">
					<div className="col-12 md:col-5 px-0">
						<div className="border-round-lg border-1 border-300 mt-2">
							<DrpDown
								inpt="Agreement"
								vl={value.Agreement}
								lbl="הסכם"
								option={YesNo}
							/>
							<DrpDown
								inpt="Hitkashrut"
								vl={value.Hitkashrut}
								lbl="התקשרות"
								option={HitkashrutOptions}
							/>
						</div>
					</div>
					<div className="col-12 md:col-7 p-1">
						<div className="pt-2">
							<label>הערות</label>
							<InputTextarea
								rows={2}
								name="Notes"
								value={value.Notes}
								onChange={handleChange}
								className="focus:border-primary w-full inputArea p-2"
							/>
						</div>
					</div>
				</div>

				<div className="grid w-full mx-auto">
					<div className="col-12 md:col-4 px-0">
						<div className="border-round-lg border-1 border-300 mt-1">
							<Text
								inpt="WorkingDays"
								vl={value.WorkingDays}
								r={props.cl}
								lbl="ימי עבודה"
							/>
							<Text
								inpt="WorkingHours"
								vl={value.WorkingHours}
								r={props.cl}
								lbl="שעות עבודה"
							/>
							<Text
								inpt="TtlHours"
								vl={value.TtlHours}
								r={props.cl}
								lbl="סה'כ שעות"
							/>
						</div>
					</div>
					<div className="col-12 md:col-4 px-0 md:px-1">
						<div className="border-round-lg border-1 border-300 mt-1">
							<DrpDown
								inpt="InclCleanMaterial"
								vl={value.InclCleanMaterial}
								lbl="כולל חומרי ניקיון"
								option={YesNo}
							/>
							<DrpDown
								inpt="InclToaletika"
								vl={value.InclToaletika}
								lbl="כולל חומרי טואלטיקה"
								option={YesNo}
							/>
						</div>
					</div>
					<div className="col-12 md:col-4 px-0">
						<div className="border-round-lg border-1 border-300 mt-1">
							<Text
								inpt="ChargeAmount"
								vl={value.ChargeAmount}
								r={props.cl}
								lbl="סכום לחיוב"
							/>
							<DrpDown
								inpt="OfenHiuv"
								vl={value.OfenHiuv}
								lbl="אופן חיוב"
								option={GlobalHour}
							/>
							<Text
								inpt="TransferCharge"
								vl={value.TransferCharge}
								r={props.cl}
								lbl="חיוב בגין נסיעות"
							/>
						</div>
					</div>
				</div>
				<div className="grid w-full mx-auto">
					<div className="col-12 md:col-8 p-0">
						<div className="border-round-lg border-1 border-300 mt-2">
							<Text
								inpt="TekenHodshi"
								vl={value.TekenHodshi}
								r={props.cl}
								lbl="תקן חודשי בגין חומרי ניקיון"
							/>
						</div>
					</div>
					<div className="col-12 md:col-4">
						<div className="mt-1 text-center">
							<Chip
								label={value.ActStts ? 'לקוח פעיל' : 'לקוח לא פעיל' }
								className={`w-8rem justify-content-center h-2rem ${
									value.ActStts ? 'bg-indigo-400 text-0' : 'bg-black-alpha-30 text-0'
								}`}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustDetails;
/*
 <Slct inpt='AffName' vl={value.AffName} slct={true}
                            lbl='בחירת שם סניף' option={createOptions(affList)}  />
*/