import { Button } from 'primereact/button';

const SearchData = (props) => {
	return (
		<div>
			<Button
				icon="pi pi-search"
				className="p-button-rounded p-button-text p-button-plain buttonCustom outline-none"
				onClick={props.showSrchInpt}
				style={{ color: 'dimgray' }}
				tooltip="חיפוש"
				tooltipOptions={{ position: 'bottom'}}
			/>
		</div>
	);
};

export default SearchData;