import React, {useContext} from 'react';
import {DataContext} from '../../../contexts/useDataContext';
import DPicker from '../../Subcomponents/DatePicker';
import Slct from '../../Subcomponents/SelectCompEdit-Country.js';
import DrpDown from '../../Subcomponents/DropDown.js';
import Text from '../../Subcomponents/TextInput';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chip } from 'primereact/chip';
import {StatusOption, MstnnOption, GndrOption, T101Option} from '../../Subcomponents/dropDownOptions'

const  EmplDetails = (props) => {
	
	const {value, handleChange, kabsList, isEditing, valueEditDrpDown, countryDataDrpDown} = useContext(DataContext);

    return (
        <div style={{direction: 'rtl'}} className='grid w-full mx-auto'>
            <div className='fr col-12 md:col-4'>
                <div className='border-round-lg border-1 border-300'>
                    <Text inpt='Number' vl={value.Number} r={props.cl} lbl="מספר עובד" dis={value.hasOwnProperty('ref2Cust')? true: false}/>
                    <Text inpt='NewNum' vl={value.NewNum} r={props.cl} lbl="מספר עובד חדש"   />
                    <Text inpt='Name'  vl={value.Name}  r={props.cl} lbl="שם פרטי"  />
                    <Text inpt='LName'  vl={value.LName} r={props.cl} lbl="שם משפחה"  />
                    <Text inpt='NName' vl={value.NName} r={props.cl} lbl="כינוי"   />
                </div>
                <div className='border-round-lg border-1 border-300 mt-2' >
                    <DPicker inpt='SDate' vl={value.SDate}  r={props.cl} lbl ='תחילת עבודה' />
                    <DPicker inpt='EDate'  vl={value.EDate} lbl={'סיום עבודה'}/>
                </div>
                <div  className='border-round-lg border-1 border-300 mt-2'>
					<DPicker inpt='Bday' vl={value.Bday} r={props.cl} lbl='ת.לידה'/> 
                    <Text  inpt='Tel'  vl={value.Tel} r={props.cl} lbl="טלפון" /> 
                </div>
            </div>
            <div className='fr col-12 md:col-5'>
                <div className='border-round-lg border-1 border-300'>
                    <Slct inpt={'Bcountry'} lbl={'ארץ לידה'} option={countryDataDrpDown} r={props.cl} 
						vl={isEditing.Bcountry ? valueEditDrpDown.Bcountry : value.Bcountry} plchldr={value.Bcountry}
						val={value}/>  
                    <DrpDown inpt={'Kablan'} vl={value.Kablan} lbl={'קבלן'} option={kabsList} r={props.cl}  />
                    <DrpDown inpt={'Status'} vl={value.Status} r={props.cl} lbl={'סטטוס'} option={StatusOption}  /> 
                    <Text  inpt='Id' vl={value.Id} r={value.Visa==='' ? props.cl : null} lbl="ת.ז."   />
                    <Text  inpt='Visa' vl={value.Visa} r={value.Id==='' ? props.cl : null} lbl="ויזה"    />
                    <DPicker inpt='VValdty' vl={value.VValdty} r={value.Visa!=='' ? props.cl: null} lbl={'תוקף ויזה'}/>
                    <Text  inpt='Insrnce' vl={value.Insrnce}  lbl="מס' ביטוח" />
                    <DPicker inpt='InsrnceVldty' vl={value.InsrnceVldty} lbl={'תוקף ביטוח'}/>   
                </div>   
                <div className='border-round-lg border-1 border-300 mt-2'>
                    <Text  inpt='Strt' vl={value.Strt}  lbl="רחוב" />
                    <Text  inpt='Cty'  vl={value.Cty}  lbl="עיר" />
                </div>
            </div>             
            <div className='fr col-12 md:col-3'>
                 <div className='border-round-lg border-1 border-300'>
                    <DrpDown inpt={'Gndr'} vl={value.Gndr} lbl={'מין'} option={GndrOption} r={props.cl} /> 
                    <DrpDown inpt={'T101'} vl={value.T101} r={props.cl} lbl={'טופס 101'} option={T101Option} /> 
                    <DrpDown inpt={'Mstnn'} vl={value.Mstnn} r={props.cl} lbl={'מסתנן'} option={MstnnOption} /> 
                    <Text  inpt='KHol'  vl={value.KHol}  lbl="מספר קופ''ח"/>
                </div>
              	<div className='mt-2'>
					 <label className='fr'>הערות</label>
                    <InputTextarea rows={5}  name ='Notes' value={value.Notes} onChange={handleChange} autoResize 
						className='focus:border-primary w-full inputArea'/> 
				</div>
				<div className='mt-2 text-center '>
					<Chip label={value.ActStts ? 'עובד פעיל' : 'עובד לא פעיל'}
						className={`w-8rem justify-content-center ${value.ActStts ? 'bg-indigo-400 text-0' : 'bg-black-alpha-30 text-0'}`} /> 
				</div>
               
            </div>          
        </div>  
        );
};

export default EmplDetails;