import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../Subcomponents/TableHeader/Header';
import CustModal from './Modal/CustModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import SideBar from '../Subcomponents/SideBar';
import NavBar from '../Subcomponents/NavBar/NavBar';
import { getData, getRegsMngs, getDataPerMng } from '../../functions/functions.js';
import { DataContext } from '../../contexts/useDataContext';
import { AuthContext } from '../../contexts/useAuthContext';

import Main from '../../logos/Main.png';
import Empl from '../../logos/Empl.png';
import Hq from '../../logos/Hq.png';
import Mng from '../../logos/Mng.bmp';
import AddCust from '../../logos/Add_cust.png';

import { Calendar } from 'primereact/calendar';
import '../Subcomponents/tableCSS.css';

import {filterSets} from '../Empl/EmplFuncHelpers';

const dateFormat = require('dateformat');

const sideBar = [
	{ id: 1, url: '/main', alt: 'בית', img: Main, ttl: 'בית' },
	{ id: 2, url: '/empl', alt: 'עובדי שטח', img: Empl, ttl: 'עובדי שטח' },
	{ id: 3, url: '/refs', alt: 'מנהלי אזור', img: Mng, ttl: 'מנהלי אזור' },
	{ id: 4, url: '/hq', alt: 'עובדי הנהלה', img: Hq, ttl: 'עובדי הנהלה' },
	{ id: 5, url: '#', alt: 'טופס חדש', img: AddCust, ttl: 'טופס חדש' },
];

let propDefaults = [
	{ field: 'CustNum', header: '#', showcol: true, w: 80 },
	{ field: 'CustName', header: 'שם לקוח', showcol: true, w: 150 },
	{ field: 'AffName', header: 'שם הסניף', showcol: true, w: 150 },
	{ field: 'Cty', header: 'עיר', showcol: false, w: 150 },
	{ field: 'Agreement', header: 'הסכם', showcol: true, w: 60 },
	{ field: 'Hitkashrut', header: 'התקשרות', showcol: true, w: 150 },
	{ field: 'SDate', header: 'תחילת עבודה', showcol: true, w: 120, showFilter: true },
	{ field: 'EDate', header: 'סיום עבודה', showcol: true, w: 120, showFilter: true },
	{ field: 'RgMngName', header: 'שם מנהל אזור', showcol: true, w: 150 },
	{ field: 'Strt', header: 'רחוב', showcol: false, w: 150 },
	{ field: 'RgMngNum', header: 'מס מנהל אזור', showcol: false, w: 110 },
	{ field: 'Notes', header: 'הערות', showcol: false, w: 150 },
	{ field: 'ActStts', header: 'פעיל', showcol: false, w: 150 },
];

const CustT = (props) => {
	const {
		custData,
		setCustData,
		selectValue,
		displayDialog,
		setDateBack,
		setReglist,
		setFilteredData,setAftDataDrpDown
	} = useContext(DataContext);
	const [sinunVal, setSinunVal] = useState({ SDate: null, EDate: null });
	const [isnotActLoaded, setIsnotActLoaded] = useState(false);
	const [showOnlyActive, setShowOnlyActive] = useState(false);

	let myRef = useRef();
	
	const { user, regmng } = useContext(AuthContext);

	const [cols, setCols] = useState(propDefaults);

	const [showSearch, setShowSearch] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [filters1, setFilters1] = useState(null);
	
	const [globalFilter, setGlobalFilter] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	const [sidebarLinks, setSidebarLinks] = useState(sideBar);

	useEffect(() => {
		console.log('start Cust');

		const loadData = async () => {
			setSpinner(true);
		
			let cstdata = [];

			if (!regmng) {
				//filtering the cust list if the user is not manager
				cstdata = await getData('Cust', true);
			} else {
				cstdata = await getDataPerMng('Cust', true, user.user.displayName);
			}

			setCustData(cstdata);
			
			setAftDataDrpDown(cstdata.map((k) => k.AffName.trim()).sort());
			
			
			setFilteredData(cstdata);
			setFilters1(filterSets);
			setSpinner(false);
		};

		loadData();
	}, [regmng, setCustData, user, setFilteredData, setAftDataDrpDown]); // Pass em

	useEffect(() => {
		
		if (regmng) {
			let newSideBar = sideBar.filter((x) => x.id === 1 || x.id === 3);
			setSidebarLinks(newSideBar);
		}

		const loadRegs = async () => {
			let regListTmp = await getRegsMngs();
			if (regmng) {
				regListTmp = regListTmp.filter((x) => x.num === user.user.displayName);
			}
			setReglist(regListTmp);
		};

		loadRegs();
	}, [regmng, setReglist, user]); // Pass em

	const dsplSlctVal = (e) => {
		selectValue(e.data);
	};

	const addEnt = (x) => (e) => {
		if (x === 5) {
			selectValue(createEmptyObj());
		}
	};

	const createEmptyObj = () => {
		let tmpObj = {};
		propDefaults
			.map((k) => k.field)
			.map((q) => {
				return (tmpObj[q] = '');
			});
		return tmpObj;
	};

	const onSearchChange = (event) => {
		myRef.current.filter(null, '', 'equals');
		setGlobalFilter(event.target.value);
		setSearchValue(event.target.value);
	}; //search

	const showSearchClick = () => {
		setShowSearch(!showSearch);
		setGlobalFilter(null);
		setSearchValue('');

	};

	const handleToggleCols = (value) => {
		let newArr = [...cols];
		let newObj = { ...value, showcol: !value.showcol };
		newArr[newArr.map((i) => i.field).indexOf(value.field)] = newObj;
		setCols(newArr);
	};

	const resetFilter = async () => {
		setGlobalFilter(null);
		setSearchValue('');
		setSinunVal({ SDate: null, EDate: null });

		setShowOnlyActive(false);

		myRef.current.reset();
	};

	const showAct = async () => {
		let tmpALL = custData; //active and not active

		if (!isnotActLoaded) {
			setSpinner(true);
			let dataOfNotActive = [];

			if (!regmng) {
				//filtering the cust list if the user is not manager
				dataOfNotActive = await getData('Cust', false);
			} else {
				dataOfNotActive = await getDataPerMng('Cust', false, user.user.displayName);
			}

			setIsnotActLoaded(true); //טעינה של לא פעילים
			tmpALL = [...custData, ...dataOfNotActive].sort((a, b) =>
				a.CustNum > b.CustNum ? 1 : b.CustNum > a.CustNum ? -1 : 0
			);

			let seen = new Set(); //removre duplicates when deleting refs
			const filteredArr = tmpALL.filter((el) => {
				const duplicate = seen.has(el.CustNum);
				seen.add(el.CustNum);
				return !duplicate;
			});

			setCustData(filteredArr);
			setSpinner(false);
		}

		setShowOnlyActive(!showOnlyActive);
	};

//************Dates Filter*****************************	
	
	const dateFilterTemplate = (col) => {

	return <Calendar  dateFormat="dd-mm-yy" readOnlyInput={true} value={sinunVal[col.field]}
                                    onChange={(e)=>onfltDates(e.value, col.field)}
                    				placeholder="dd-mm-yyyy"
			   />
    }

	const clrFlt = (state) => (e) => {
		setSinunVal({ ...sinunVal, [state]: null });
		fltTable('', state);
	};

	const onfltDates = (date, state) => {
		setSinunVal({ ...sinunVal, [state]: date });
		let newArr = fltTable(date, state);

		if (newArr.length) {
			myRef.current.filter(
				newArr.map((x) => x[state]),
				state,
				'in'
			);
		} else {
			myRef.current.filter('jfgjhfgjhgvjhfj', state, 'equals');
		}
	};

	const fltTable = (date, state) => {
		let tempD = custData; // = isnotActLoaded ? emplActAndnAct : emplinitial;

		//initial filter per request
		if (date !== '' && state === 'SDate') {
			tempD = tempD.filter(
				(v) => setDateBack(v.SDate) >= dateFormat(date, 'yyyy-mm-dd') && v.SDate !== ''
			);
		} else if (date !== '' && state === 'EDate') {
			tempD = tempD.filter(
				(v) => setDateBack(v.EDate) <= dateFormat(date, 'yyyy-mm-dd') && v.EDate !== ''
			);
		} else if (date === '') {
			return tempD;
		}

		//Check if other filters are assigned

		tempD =
			sinunVal.SDate !== null && state !== 'SDate'
				? tempD.filter(
						(v) =>
							setDateBack(v.SDate) >= dateFormat(sinunVal.SDate, 'yyyy-mm-dd') &&
							v.SDate !== ''
				  )
				: tempD;

		tempD =
			sinunVal.EDate !== null && state !== 'EDate'
				? tempD.filter(
						(v) =>
							setDateBack(v.EDate) <= dateFormat(sinunVal.EDate, 'yyyy-mm-dd') &&
							v.EDate !== ''
				  )
				: tempD;

		return tempD;
	};

	const AgreementTemplateName = (item) => {
		return (
			<div>
			{
				item.Agreement==='כן' ? <i className="pi pi-check text-xl font-bold text-green-500" ></i> :
				item.Agreement==='לא' ?  <i className="pi pi-times text-xl font-bold text-red-500"></i> : null
				
			}
				</div>
	);
};
	
	
	const header = (
		<Header
			showSearch={showSearch}
			onChange={onSearchChange}
	
			searchValue={searchValue}
			showActive={showAct}
			showYNAct={showOnlyActive}
			showSrchInpt={showSearchClick}
			resetFltr={resetFilter}
			handleToggleCols={handleToggleCols}
			cols={cols}
			tableName="טבלת לקוחות"
			expTableCols={cols.filter((col) => col.showcol === true)}
			data={!showOnlyActive ? custData.filter((x) => x.ActStts === true) : custData}	
		/>
	);

	let dynamicColumns = cols
		.filter((col) => col.showcol === true)
		.slice(0)
		.reverse()
		.map((col, i) => {
			return (
				<Column
					key={col.field}
					field={col.field}
					header={col.header}
					filter={col.showFilter}
					sortable={false}
					filterElement={
						col.field === 'SDate' ||
						col.field === 'EDate'? dateFilterTemplate : null
					}
					showFilterOperator={false}		//filter
					showFilterMatchModes={false}  	//filter
					showAddButton={false}		//filter
					showApplyButton={col.field === 'SDate' ||
						col.field === 'EDate' ? false:true}  //filter
					
					onFilterClear={clrFlt(col.field)}
					
					headerStyle={{
						overflow: 'visible',
						width: col.w + 'px',
					}}
					style={{ width: col.w + 'px' }}
					body={
						col.field === 'Agreement'
							? AgreementTemplateName: null
					}
				/>
			);
		});

	let sidebar = sidebarLinks.map((value) => (
		<SideBar
			click={addEnt(value.id)}
			key={value.id}
			img={value.img}
			ttl={value.ttl}
			alt={value.alt}
			url={value.url}
		/>
	));

	const setFilteredDataforTable = (filteredData) => {
		if (filteredData) setFilteredData(filteredData);
	};

	return (
		<div className="bg">
			   <NavBar shownav={props.shownav}/> 
			<div className="container card flex-wrap p-4 surface-card border-round-lg">
				{spinner ? <ProgressSpinner className="spinner" /> : null}
				<DataTable
					value={!showOnlyActive ? custData.filter((x) => x.ActStts === true) : custData}
					ref={myRef}
					paginator={true}
					rows={10}
					rowsPerPageOptions={[10, 25, 50, 100]}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
					header={header}
					globalFilter={globalFilter}
					selectionMode="single"
					filters={filters1}
					//  resizableColumns={true}
					//autoLayout={true}
					//	scrollable={true}
					responsiveLayout="scroll"
					onRowClick={dsplSlctVal}
					emptyMessage="אין נתונים"
					onValueChange={(filteredData) => setFilteredDataforTable(filteredData)}
				>
					{dynamicColumns}
				</DataTable>
			</div>
			{displayDialog ? <CustModal 	isnotActLoaded={isnotActLoaded}
									 			setIsnotActLoaded={setIsnotActLoaded} 
									  			obj={createEmptyObj()}
                                /> : null }

			<div className="icon-bar"> {sidebar}</div>
		
		</div>
	);
};

export default CustT;

/* 

  

 
                
       

*/