import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import Text from '../Subcomponents/TextInput';
import { Chip } from 'primereact/chip';

const  CustDetailsGarbage = ({value}) => {
 
    return (
        <div style={{ direction: 'rtl' }} className="grid w-full mx-auto mt-0">
			<div className="col-12 md:col-4">
				<div className="border-round-lg border-1 border-300">
                    <Text inpt='AffName' vl={value.AffName} lbl='בחירת שם סניף' dis={true} />
                    <Text inpt='CustNum'  vl={value.CustNum}lbl="מספר סניף"  dis={true}/>
                </div>
               <div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='SDate' vl={value.SDate} lbl ='תחילת פעולת' dis={true} />
                    <Text inpt='EDate' vl={value.EDate} lbl={'סיום פעילות'} dis={true} />
                </div>
                <div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='CustName' vl={value.CustName} lbl="שם לקוח" dis={true} />
                    <Text inpt='AffName' vl={value.AffName} lbl="שם הסניף" dis={true}  />
                    <Text inpt='Strt'  vl={value.Strt} lbl="רחוב" dis={true} />
                    <Text inpt='Cty' vl={value.Cty}  lbl="עיר" dis={true} />
                </div>
                
               <div className="border-round-lg border-1 border-300 mt-2">
                    <Text inpt='RgMngName' vl={value.RgMngName} lbl='מנהל אזור'dis={true} />
                    <Text  inpt='RgMngNum' vl={value.RgMngNum}  dis={true} lbl="מס' מנהל אזור" />
                </div>
            </div>
           <div className="col-12 md:col-8">
               <div className="border-round-lg border-1 border-300">
                  			<Text inpt='Agreement' vl={value.Agreement} lbl='הסכם'   /> 
							<Text inpt='Hitkashrut' vl={value.Hitkashrut} lbl='התקשרות' />
                </div>
               <div className="mt-2">
                    <label className='fr'>הערות</label>
                    <InputTextarea rows={4}  name ='Notes' value={value.Notes} className='w-full'/>   
                </div>
                <div className="col-12">
						<div className="mt-1 text-center">
							<Chip
								label={value.ActStts ? 'לקוח פעיל' : 'לקוח לא פעיל' }
								className={`w-8rem justify-content-center h-2rem ${
									value.ActStts ? 'bg-indigo-400 text-0' : 'bg-black-alpha-30 text-0'
								}`}
							/>
						</div>
					</div>
            </div>             
                    
        </div>  
        );
};

export default CustDetailsGarbage;


